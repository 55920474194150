import React, { Fragment, useEffect, useRef, useState } from "react";
import Footer from "../../layouts/Footer/Footer";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import "../HireResources/HireResources.scss";
import { Carousel } from "react-bootstrap";
import leftArrow from "../../assets/leftresource.svg";
import rightArrow from "../../assets/rightresource.svg";
import Designer1 from "../../assets/Designer1.png";
import Designer2 from "../../assets/Designer2.png";
import Designer3 from "../../assets/Designer3.png";
import Designer4 from "../../assets/Designer4.png";
import Designer5 from "../../assets/Designer5.png";
import Designer6 from "../../assets/Designer6.png";
import { MdKeyboardArrowRight } from "react-icons/md";
// import Footer from "../../layouts/Footer/Footer";
import { useNavigate } from "react-router-dom";
import data from "./data";
import backUrl from "../../services/APIurl";
import { useDispatch, useSelector } from "react-redux";
import { MicroServicePostRequestAPI } from "../../services/ApiService";
import { CompanyDetailsAPI } from "../../services/CompanyAPI";
import {
  Company_Details,
  get_resource_id,
} from "../../redux/actions/CompanyAction";

const HireResources = () => {
  const dispatch = useDispatch();
  const Resource_Profile_State = useSelector(
    (state) => state?.Company?.Company_Details
  );

  const [companyData, setCompanyData] = useState();
  const [number, setNumber] = useState(1);
  const postPerPage = 6;

  const lastPost = number * postPerPage;
  const firstPost = lastPost - postPerPage;
  const currentPost = companyData?.slice(firstPost, lastPost);

  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(companyData?.length / postPerPage); i++) {
    pageNumber.push(i);
  }

  useEffect(() => {
    setCompanyData(Resource_Profile_State);
  }, []);

  const navigate = useNavigate("");
  const HiringResources = [
    "Testing and QA",
    "Analytics",
    "Creative Service",
    "Process Outsourcing",
    "Cloud Info Management",
    "Business Analyst",
    "UI Designer",
    "UX Designer",
    "PHP Developer",
    "Cloud Engineer",
  ];

  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };
  // const profileHandler = () => {
  //   navigate("/profile");
  // };

  useEffect(() => {
    CompanyDetailsAPI()
      .then((res) => {
        dispatch(Company_Details(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const profileHandler = (e, item) => {
    dispatch(get_resource_id(item));
    navigate("/profile");
  };

  return (
    <Fragment>
      <NavigationBar />
      <div className="container1">
        <div className="row">
          <div className="profile-container">
            <div className="col-md-12">
              <div className="header-text p-5">
                <div className="header-title">
                  <h3>
                    <span className="highlight-primary">Hire</span>Resources -
                    Hire the Best UX Designers
                  </h3>
                  <h6>
                    Check out UX designers with the skills you need for your
                    next job.
                  </h6>
                </div>
              </div>

              <div className="col-md-12 mt-3 p-4">
                <Carousel ref={ref} indicators={false} controls={false}>
                  <Carousel.Item interval={5000}>
                    <div className="row">
                      {currentPost?.length>=1 && currentPost?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="col-md-4 col-12 mb-3">
                              <div className="card resource-card-front">
                                <div className="image-wrapper">
                                  <img
                                    className="card-img-top"
                                    src={
                                      item.uploadPhoto
                                        ? backUrl.backendUrl +
                                          "/" +
                                          item.uploadPhoto
                                        : Designer1
                                    }
                                    alt="talented resource"
                                  />
                                </div>
                                <span className="top-content">
                                  {item?.hourlyRate == undefined
                                    ? "600 / Hr"
                                    : item?.hourlyRate + "/ Hr"}
                                </span>
                                <div className="card-content">
                                  <h4 className="profile-name">
                                    {item.fullName}
                                  </h4>
                                  <span className="designation">
                                    {/* {item?.work?.jobPosition} */}
                                    {item?.work?.length>0 && item?.work[item?.work?.length-1]?.jobPosition}
                                  </span>
                                  {/* <div className="profile-badges">
                                    <div className="badge">
                                      <span>Adobe XD</span>
                                    </div>
                                    <div className="badge">
                                      <span>UX Design</span>
                                    </div>
                                    <div className="badge">
                                      <span>Web Design</span>
                                    </div>
                                    <div className="badge">
                                      <span>Figma</span>
                                    </div>
                                    <div className="badge">
                                      <span>Photoshop</span>
                                    </div>
                                  </div>  */}
                                  <div className="skill-box">
                                  {item.skills.map((data, index) => {
                                    return (
                                      <>                                      
                                      <div className="profile-badges">
                                        <div className="badge">
                                          <span>{data}</span>
                                        </div>
                                        </div>                                                                       
                                      </>
                                    );
                                  })}
                                  </div>
                                </div>
                                <div
                                  className="cta-btn"
                                  onClick={(e) => profileHandler(e, item?._id)}
                                >
                                  <button className="btn-cta2">
                                    View Profile <MdKeyboardArrowRight />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </Carousel.Item>
                </Carousel>
                <div className="col-md-6 text-end">
                  <button
                    className=" btn-slider mb-3"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                    disabled={firstPost == 0 ? true : false}
                    onClick={() => setNumber(number - 1)}
                  >
                    <img src={leftArrow} alt="left-icon" className="me-3" />
                  </button>
                  <button
                    className=" btn-slider mb-3"
                    type="button"
                    disabled={currentPost?.length < 6 ? true : false}
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                    onClick={() => setNumber(number + 1)}
                  >
                    <img src={rightArrow} alt="right-icon" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-3 p-5">
              <div className="header-subtext">
                <h6 className="header-subtitle1">
                  <span>Hiring Porcess</span>
                </h6>

                <div className="header-title1">
                  <h3>UX Designer Hiring Resource</h3>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="overflow-auto">
                  {HiringResources.map((resources, idx) => (
                    <div className=" resources" key={idx}>
                      {resources}
                    </div>
                  ))}
                  <div class="scrollbar" id="style-3">
                    <div class="force-overflow"></div>
                  </div>
                </div>
              </div>

              <div className="col-md-7 col-12  mt-1">
                <div className="about-skipple">
                  <h3>How to Hire UX Designer through Skiple </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mollis metus, pulvinar ac tempus et quisque consectetur. Sed
                    vitae, enim, in tellus sed. Elementum non cum eget massa.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mollis metus, pulvinar ac tempus et quisque consectetur. Sed
                    vitae, enim, in tellus sed. Elementum non cum eget massa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container-fluid footer" id="footer-section">
        <Footer />
      </section>
    </Fragment>
  );
};

export default HireResources;
