import React, { Fragment, useRef, useState } from "react";
import { Blogs } from "../../data/BlogContent";
import "./Blog.scss";
import { Carousel } from "react-bootstrap";
import leftArrow from "../../assets/leftArrow.svg";
import rightArrow from "../../assets/rightArrow.svg";
import SingleBlog from "../Blog/SingleBlog";
import FeaturedBlog from "../Blog/FeaturedBlog";

const BlogListing = () => {
  const ref = useRef(null);
  const [count, setCount] = useState(0);
  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    setCount(count + 1);
    ref.current.next();
  };
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12 mb-0">
            <div className="header-text">
              <h6 className="header-subtitle">
                <span>Blogs</span>
              </h6>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="header-text">
              <div className="header-title">
                <h3>News, Events and Insights</h3>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 text-end controls-column">
            <button
              className="btn-slider "
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
              onClick={onPrevClick}
            >
              <img src={leftArrow} alt="left-icon" className="me-3" />
            </button>
            <button
              className=" btn-slider "
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
              onClick={onNextClick}
            >
              <img src={rightArrow} alt="right-icon" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <Carousel ref={ref} controls={false}>
              <Carousel.Item interval={5000}>
                <div className="row">
                  <FeaturedBlog SendBlogData={Blogs[0].featured} />
                  <SingleBlog SendBlogData={Blogs[0].nonFeatured} />
                </div>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <div className="row">
                  <FeaturedBlog SendBlogData={Blogs[0].featured} />
                  <SingleBlog SendBlogData={Blogs[0].nonFeatured} />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlogListing;
