import { Fragment } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Button,
} from "react-bootstrap";
import "./EmployeeProfileHeading.scss"
import CreatProfileIcon from "../../../assets/CreatProfileIcon.svg"


const EmployeeProfileHeading = () => {
    return (
        <Fragment>
            <div className="container-fluid">
                <Row>
                    <Col sm={4} md={4} className="d-flex justify-content-md-center justify-content-sm-center">
                        <img src={CreatProfileIcon}/>
                    </Col>
                    <Col>
                        <Row className="heading-text">
                        Create Your 
                        </Row>
                        <Row className="second-text">
                        Resume With Us
                        </Row>
                    </Col>
                </Row>
            </div>
        </Fragment >
    )
}

export default EmployeeProfileHeading;