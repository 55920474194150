import React, { useState } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import "./NavigationBar.scss";
import { useNavigate, Link } from "react-router-dom";
import SignUp from "../../pages/SignUp/SignUp";

const NavigationBar = ({ showModel }) => {
  const navigate = useNavigate();
const UserType=localStorage.getItem("UserType")
  const signupHandler = (e) => {
    e.preventDefault();
    navigate("/new-signup");
  };

  const hireresourceHandler = (e) => {
    e.preventDefault();
    navigate("/hireresources");
  };

  const aboutusHandler = (e) => {
    e.preventDefault();
    navigate("/aboutus");
  };

  const contactusHandler = (e) => {
    e.preventDefault();
    navigate("/contactus");
  };
  return (
    // <div classNameName='header-container'>
    // <div className='header-container'>

    <Navbar expand="lg" className="site-navbar">
      <Container>
        {/* <Navbar.Brand href="/">Skiple</Navbar.Brand> */}
        <Link to="/" className="navbar-brand">
          Skiple
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto navbar-links">
           <Nav.Link to="/hireresources" onClick={hireresourceHandler}>
              Hire Resources
            </Nav.Link>
            <Nav.Link to="/aboutus" onClick={aboutusHandler}>
              About Us
            </Nav.Link>
            <Nav.Link to="/contactus" onClick={contactusHandler}>
              Contact Us
            </Nav.Link>
            <Nav.Link href="#">News and Articles</Nav.Link>
          </Nav>
          <Nav className="ms-auto btn-navbar">
            <li className="nav-item">
              <Link className="nav-link" to="/new-login">
                Login
              </Link>
            </li>
            <li className="nav-item">
              <Button className="nav-link signUp-btn" onClick={signupHandler}>
                Sign Up
              </Button>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
