const initialState = {
  loginData: [],
  userType: "",
};
export const loginUserReducer = (state = initialState, action) => {
  switch (action.type) {
    // case "login_data":
    //   return {
    //     ...state,
    //     user: [...state.user, action.payload],
    //   };

    case "get_user_type":
      return {
        ...state,
        userType: action.payload,
      };

    default:
      return state;
  }
};
// export const forgotUserReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "forgot_password":
//       return {
//         ...state,
//         forgotPassword: action.payload,
//       };

//     default:
//       return state;
//   }
// };
