const initialState = {
  CreateEmployeeData:{
    id:null,
    uploadPhoto:null,
    workingHours:null,
    hourlyRate:null,
    fileUpload:{path:null},
    currency:null
  },
};
const EmployeeReducer = (state = initialState, action) => {

  switch (action.type) {
    case "create_employee_profile":
      return {
        ...state,
        CreateEmployeeData:action.payload,
      };

    default:
      return state;
  }
};
export default EmployeeReducer;
