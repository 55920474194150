import React, { Fragment, useState } from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import HeaderComponent from "../../component/HeaderComponent/HeaderComponent";
import Comment from "../../component/Comment/Comment";
import "./MainComponent.scss";
import TalentedResource from "../../component/TalentedResource/TalentedResource";
import TredningResource from "../../component/TredningResource/TredningResource";
import Requirments from "../../component/Requirments/Requirments";
import BlogListing from "../../component/Blog/BlogListing";
import Member from "../../component/Member/Member";
import Footer from "../../layouts/Footer/Footer";
import Login from "../../pages/Login/Login";
import HireResources from "../../pages/HireResources/HireResources";
import SignUp from "../../pages/SignUp/SignUp";

export const MainComponent = () => {
  const [show, setShow] = useState(false);
  const showModel = ( ) =>{
    setShow(true);
  }
  const hideModel = ( ) =>{
    setShow(false);
  }
  return (
    <Fragment>
      <header>
        <NavigationBar  showModel={showModel}/>
      </header>
      {show && <SignUp hideModel={hideModel}/>}
      <div className="wrapper" id="wrapper">
        <section className="container-fluid header-section" id="header-section">
          <HeaderComponent />
        </section>
        <section
          className="container-fluid talented-resources"
          id="talented-resources"
          >
          <TalentedResource />
        </section>
        <section
          className="container-fluid trending-resources"
          id="trending-resources"
        >
          <TredningResource />
        </section>
        <section className="container-fluid comment" id="comment-section">
          <Comment />
        </section>
        <section className="container-fluid requirment" id="requirment-section">
          <Requirments />
        </section>
        <section className="container-fluid blog" id="blog-section">
          <BlogListing/>
        </section>
        <section className="container-fluid member" id="member-section">
          <Member />
        </section>
        <section className="container-fluid footer" id="footer-section">
          <Footer />
        </section>
         
        
      </div>
    </Fragment>
  );
};
