import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  // Alert,
  // Container,
  // Form,
  // FormGroup,
  // Input,
  // InputGroup,
  // Label,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { EmployeeProfile } from "../../../redux/actions/action";
import { EmployeeAPI } from "../../../services/EmployeeAPI";
import PhotoDropzone from "../../Dropzone/Photo_dropzone";

const UploadImage = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const [selectedFileName, setSelectedFileName] = useState("");
  const [removeUploadPhoto, setRemoveUploadPhoto] = useState("");
  const [uploadFileData, setUploadFileData] = useState();
  console.log("uploadFileData",uploadFileData);
  const navigate = useNavigate();
  console.log("selectedFileName",selectedFileName,uploadFileData);
  useEffect(() => {
    if (state.EmployeeReducer.CreateEmployeeData.uploadPhoto) {
      setUploadFileData(
        state?.EmployeeReducer?.CreateEmployeeData?.uploadPhoto
      );
    }
    if (state.EmployeeReducer?.CreateEmployeeData?.fileUpload?.path) {
      setSelectedFileName(
        state.EmployeeReducer.CreateEmployeeData.fileUpload.path
      );
      console.log("state.EmployeeReducer.CreateEmployeeData.fileUpload.path",state.EmployeeReducer.CreateEmployeeData.fileUpload.path);
    }
  }, [state.EmployeeReducer.CreateEmployeeData.fileUpload.path]);

  const onSubmitHandler = () => {
    var inputData = new FormData();
    inputData.append("uploadPhoto", uploadFileData);

    EmployeeAPI(inputData)
      .then((result) => {
        let data = {
          id: state?.EmployeeReducer?.CreateEmployeeData?.id,
          workingHours:
            state?.EmployeeReducer?.CreateEmployeeData?.workingHours,
          hourlyRate: state?.EmployeeReducer?.CreateEmployeeData?.hourlyRate,
          currency: state?.EmployeeReducer?.CreateEmployeeData?.currency,
          fileUpload: { path: uploadFileData.path },
          uploadPhoto: uploadFileData,
        };
        dispatch(EmployeeProfile(data));
        //  navigate('/success-page')
        props.selected(7);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const selectedProfile = (files) => {
    setSelectedFileName(files?.name);
    setUploadFileData(files);
  };

  const cancelButtonHandler = (e) => {
    e.preventDefault();

    let clearData = {
      id: state?.EmployeeReducer?.CreateEmployeeData?.id,
      workingHours: state?.EmployeeReducer?.CreateEmployeeData?.workingHours,
      hourlyRate: state?.EmployeeReducer?.CreateEmployeeData?.hourlyRate,
      currency: state?.EmployeeReducer?.CreateEmployeeData?.currency,
      fileUpload: "",
      uploadPhoto: "",
    };

    dispatch(EmployeeProfile(clearData));
  };
  console.log("uplaod",uploadFileData)
  return (
    <Fragment>
      <div className="container-fluid">
        <p className="head-text">Last Step!! Upload Your Image Below</p>
        <Row className="justify-content-center mb-3 card">
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card.Body className="main-card py-0">
              <h1 className="header-cont mb-5">Upload Image</h1>
              <div className="photo">
                <div className="mb-3">
                  <PhotoDropzone
                    uploadedFile={(val) => {
                      selectedProfile(val);
                      setRemoveUploadPhoto("");
                    }}
                    value={"https://upwork-backend.testyourapp.online/"+uploadFileData}
                    removeUploadPhoto={removeUploadPhoto}
                    selectedFileName={
                      state.EmployeeReducer.CreateEmployeeData.uploadPhoto
                    }
                  />
                </div>
                {console.log("selectedFileName", selectedFileName)}
                <div className="fild-text">
                  {selectedFileName !== ""
                    ? selectedFileName
                    : "Upload Image Here"}
                  {console.log(selectedFileName)}
                </div>
              </div>
            </Card.Body>
          </Col>
        </Row>
        <div className="end-bt">
          <Row className="bt">
            <Col className="bt">
              <Button
                className="mb-3 Cancel-bt"
                // onClick={() => {
                //   setRemoveUploadPhoto(null);
                //   setSelectedFileName("");
                // }}
                onClick={(e) => cancelButtonHandler(e)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="mb-3 Save-bt " onClick={onSubmitHandler}>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};
export default UploadImage;
