import React, { Fragment } from "react";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import img from "../../assets/ContactUs.svg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "../ContactUs/ContactUs.scss";
import Footer from "../../layouts/Footer/Footer";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import img1 from "../../assets/Map.svg";
import img2 from "../../assets/logo/location.svg";
import img3 from "../../assets/logo/phone.svg";
import img4 from "../../assets/logo/contactemail.svg";
import { BsYoutube } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";

const ContactUs = () => {

  return (
    <Fragment>
      <NavigationBar />
      <div className="container4">
        <div className="row p-5">
          <div className="col-md-12">
            <div className="contact-header-text mt-4">
              <div className="contact-header-title">
                <h3>
                  <span className="contact-highlight-primary">Fill</span>The
                  Form-
                </h3>
                <h3>Its Easy !</h3>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="contact-header-content">
              <div className="contact-form-card">
                <div className="contact-text-content">
                  <h6>ContactUs</h6>
                  <p>We’ll Show You Relavant Resource For It !</p>
                </div>
                <div className="contact-form-wrapper">
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: "8px 0px", width: "100%" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="filled-basic"
                      label="Full Name"
                      variant="filled"
                    />
                    <TextField
                      id="filled-basic"
                      label="Email Address"
                      variant="filled"
                    />
                    <TextField
                      id="filled-basic"
                      label="Message"
                      variant="filled"
                    />

                    <button type="submit">Send Message</button>
                  </Box>
                </div>
              </div>
            </div>
          </div>
          <div className="reso row mt-5">
            <div className="col-md-6 col-12">
              <div className="row">
              <div className="mapouter"><div class="gmap_canvas">
                    <iframe width="auto" height="400" id="gmap_canvas" 
                    src={`https://maps.google.com/maps?q=&t=&z=13&ie=UTF8&iwloc=&output=embed`} 
                    frameborder="0" scrolling="no" 
                    marginheight="0" 
                    marginwidth="0"></iframe>
                    </div></div>
                {/* <img src={img1} className="contact-map" alt="" /> */}
              </div>
            </div>
            <div className="col-md-6 col-12 why-skipple mt-4">
              <div className="contact-txt">
                <p>
                  <img src={img2} alt="" />
                  Infomation technologies building,Victoria Island,Lagos,Nigeria.
                
                </p>
                <p>
                  <img src={img3} alt="" />
                  +234 081-1236-4568
                </p>
                <p>
                  <img src={img4} alt="" />
                  hello@info.com.ng
                </p>

                <div className="social-icon">
                  <BsInstagram />
                  <BsYoutube />
                  <BsFacebook />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container-fluid footer" id="footer-section">
        <Footer />
      </section>
    </Fragment>
  );
};

export default ContactUs;
// export default GoogleApiWrapper({
//   apiKey: ("AIzaSyAnLTgc25SIU1uEspTVp0xBt6yTsPOEpfA")
// })(ContactUs)
