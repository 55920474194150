import { combineReducers } from "redux";
// import AddUserReducer from './AddUserReducer'

// import {loginUserReducer} from './loginUserReducer'

import EmployeeReducer from "./EmployeeReducer";
import CompanyReducer from "./CompanyReducer"
import EmployeeManualResumeReducer from "./EmployeeManualResumeReducer";
// import forgotUserReducer from "./loginUserReducer/forgotUserReducer"
// import { forgotUserReducer } from "./loginUserReducer";
import { loginUserReducer } from "./loginUserReducer";

const rootReducer = combineReducers({ EmployeeReducer: EmployeeReducer,Company:CompanyReducer,EmployeeManualResumeReducer,loginUserReducer });

export default rootReducer;
