import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'


function ManualFileDropzone() {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
 
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p></p> :
          <p>
            Create resume Manually
          </p>
      }
    </div>
  )
}

export default ManualFileDropzone;