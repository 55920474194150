import { MicroServicePostRequestAPI } from "./ApiService";

export const loginAPI = async (body) => {
    return await MicroServicePostRequestAPI('api/vendor/login', 'post', body);
};

export const registerAPI = async (body) => {
    return await MicroServicePostRequestAPI("post", `/register`, body);
};

export const logoutAPI = async () => {
    return await MicroServicePostRequestAPI("get", `/logout`);
};

//forget api
export const forgetPasswordApi = async (body) => {
    return await MicroServicePostRequestAPI("post", `/forget-password`, body);
};
export const sendForgetMail = async (body) => {
    return await MicroServicePostRequestAPI("post", `/forget-mail`, body);
};


