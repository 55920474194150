const initialState = {
  PersonalInfoResumeData: {
    fullName: null,
    birthDate: null,
    gender: null,
    language: null,
    maritalStatus: null,
    aboutMe: null,
  },
  contactInfoResumeData: {
    email: null,
    alternateEmail: null,
    phone: null,
    alternatePhone: null,
    address: null,
  },
  educationalInfoResumeData: {
    qualification: null,
    courseName: null,
    university: null,
    courseType: null,
    startMonth: null,
    startYear: null,
    endMonth: null,
    endYear: null,
    isPursuing:false,
  },

  EducationalInfoResumeData: {
    fresher: {
      interestArea: null,
      description: null,
    },
    experience: {
      jobPosition: null,
      companyName: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      workExperience: null,
      workDescription: null,
    },
  },
  add_Your_Skill_Tags: [],
  experience_data: [],
  total_Education_Data:[],
  fresher_Educational_Data:[]
};

const EmployeeManualResumeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "manual_resume_data":
      console.log("manual_resume_data", action.payload);
      return {
        ...state,
        PersonalInfoResumeData: action.payload,
      };

    case "contact_info_resume_data":
      console.log("contact reducer", action.payload);
      return {
        ...state,
        contactInfoResumeData: action.payload,
      };

    case "education_info_resume_data":
      return {
        ...state,
        educationalInfoResumeData: action.payload,
      };

    case "add_Your_Skill_Tags":
      return {
        ...state,
        add_Your_Skill_Tags: action.payload,
      };

    case "experience_data":
      return {
        ...state,
        experience_data: action.payload,
      };

    case "total_Education_Data":
      return {
        ...state,
        total_Education_Data: action.payload,
      };
    case "fresher_Educational_Data":
      return {
        ...state,
        fresher_Educational_Data: action.payload,
      };

    default:
      return state;
  }
};
export default EmployeeManualResumeReducer;
