import React, { Fragment, useState } from 'react'
// import { Button } from 'react-bootstrap';
import "./HeaderComponent.scss"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const experiences = [
  {
    value: '1 Yr',
    label: '1 Yr'
  },
  {
    value: '2 Yr',
    label: '2 Yr',
  },
  {
    value: '3 Yr',
    label: '3 Yr',
  },
  {
    value: '4 Yr',
    label: '4 Yr',
  },
];
const HeaderComponent = () => {
  const [experience, setExperience] =useState('')
  const handleChange =(e)=>{
    setExperience(e.target.value);
  }
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-3 p-3">
                <div className="header-text">
                  <h6 className="header-subtitle"><span>Introducing</span></h6>
                  <div className="header-title">
                    <h3><span className="highlight-primary">Discover</span> Resources -
                      Across Popular Roles</h3></div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="header-content">
                  <div className="form-card">
                    <div className="text-content">
                      <h6>Select A Role</h6>
                      <p>We'll Show You Relavant Resource For It !</p>
                    </div>
                    <div className="form-wrapper">
                  <Box component="form"
                    sx={{
                      '& .MuiTextField-root': { m:'8px 0px', width: '100%' },
                    }}
                    noValidate
                    autoComplete="off">
                      <TextField id="filled-basic" label="What" variant="filled" />
                      <TextField
                        id="filled-select-experience"
                        select
                        label="Experience"
                        value={experience}
                        onChange={handleChange}
                        variant="filled"
                      >
                        {experiences.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                        <button type="submit">Find Resource</button>
                        </Box>     
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HeaderComponent