 

export const Blogs = [
  {
    featured: [
      {
        id: 1,
        blogImage: "../../assets/blog1.png",
        blogTitle: "Employees these days are working less ?",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Raheem Cannon",
        profileDesignation: "Web Designer",
      },
      {
        id: 6,
        blogImage: "../../assets/blog1.png",
        blogTitle: "Employees these days are working less ?",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Anthony Masional",
        profileDesignation: "Web Designer",
      },
    ],
    nonFeatured: [
      {
        id: 2,
        blogImage: "../../assets/blog2.png",
        blogTitle: "Minimal workspace for inspirations",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Anthony Masional",
        profileDesignation: "Web Developer",
      },
      {
        id: 3,
        blogImage: "../../assets/blog3.png",
        blogTitle: "Does productivity increase when working remotely ? ",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Franck Martin",
        profileDesignation: "Web Developer",
      },
      {
        id: 4,
        blogImage: "../../assets/blog4.png",
        blogTitle: "Morning routine to boost your mood",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Elizabeth swan",
        profileDesignation: "Web Developer",
      },
      {
        id: 5,
        blogImage: "../../assets/blog5.png",
        blogTitle: "5+ tips to find comfortable co-working space",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Mykola Ilschenko",
        profileDesignation: "Web Developer",
      },
      {
        id: 7,
        blogImage: "../../assets/blog.png",
        blogTitle: "Minimal workspace for inspirations",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Anthony Masional",
        profileDesignation: "Web Developer",
      },
      {
        id: 8,
        blogImage: "../../assets/blog3.png",
        blogTitle: "Does productivity increase when working remotely ? ",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Franck Martin",
        profileDesignation: "Web Developer",
      },
      {
        id: 9,
        blogImage: "../../assets/blog4.png",
        blogTitle: "Morning routine to boost your mood",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Elizabeth swan",
        profileDesignation: "Web Developer",
      },
      {
        id: 10,
        blogImage: "../../assets/blog5.png",
        blogTitle: "5+ tips to find comfortable co-working space",
        blogDescription:
          "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis metus, pulvinar ac tempus et quisque consectetur.",
        userAvtar: "../../assets/logo/bloglogo.png",
        profileName: "Mykola Ilschenko",
        profileDesignation: "Web Developer",
      },
    ],
  },
];
