import { Fragment } from "react";
import "./CustomInput.scss";

const CustomInput = (props) => {
  
  return (
    <Fragment>
      <input
        className="w-100 text-input"
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}

        // defaultValue={props.defaultValueData}
        onChange={props.onChange}
        value={props.value}
        defaultValue={props.default}
        onKeyUp={props.onKeyUp}
        disabled={props.disabled}
      />
    </Fragment>
  );
};
export default CustomInput;
