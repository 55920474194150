import { MicroServicePostRequestAPI } from "./ApiService";

const id=localStorage.getItem('id')
export const CompanyDetailsAPI = async (body) => {
    return await MicroServicePostRequestAPI('api/resource/getAllUsers', 'get', body);
};
export const personalExperienceDetails = async (id, body) => {
    return await MicroServicePostRequestAPI(`api/resource/updateResume/${id}`, 'put', body);
};
export const getAllResource = async (idData) => {
    console.log("id",idData)
    return await MicroServicePostRequestAPI(`api/resource/getResource/${idData}`, 'get');
};



