import { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  // Alert,
  // Container,
  // Form,
  // FormGroup,
  // Input,
  // InputGroup,
  // Label,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EmployeeAPI } from "../../../services/EmployeeAPI";
import CustomInput from "../../CustomInput/CustomInput";

const CreatedSuccesfullly = () => {
  const [info, setInfo] = useState({
    linkedIn: "",
    portfolio: "",
  });
  const infoInputChangeHandler = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    setInfo((values) => ({ ...values, [name]: value }));
  };

  const saveButtonHandler = () => {
    let data = {
      linkedIn: info.linkedIn,
      portfolio: info.portfolio,
    };
    EmployeeAPI(data)
      .then((result) => {
        console.log("result", result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="container-fluid">
        <Row className="justify-content-center mb-3 card">
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card.Body className="main-card py-0">
              <div>
                <h1 className="header-cont mb-5">Congratulations!!</h1>
              </div>
              <div>
                <h1 className="CreatedSuccesfullly-text mb-5">
                  Your Resume Has Been Created Succesfullly!!{" "}
                </h1>
              </div>
              <div>
                <h1 className="header-cont mb-5">Would You Like To View?</h1>
              </div>
              <div className="pre-bt">
                <Button
                  className="mb-3 Preview-bt"
                  onClick={() => navigate("/Resume-Preview")}
                >
                  Preview
                </Button>
              </div>
              <div>
                <p className="info-text">
                  Please Provide Some More Information So That Company Can Know
                  More About You
                </p>
              </div>
              <div>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">Linked In</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="linkedIn"
                        value={info.linkedIn}
                        onChange={infoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="fild-text">Portfolio URL</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="portfolio"
                        value={info.portfolio}
                        onChange={infoInputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Col>
        </Row>
        <div className="end-bt">
          <Row className="bt">
            <Col className="bt">
              <Button className="mb-3 Cancel-bt">Cancel</Button>
            </Col>
            <Col>
              <Button
                className="mb-3 Save-bt "
                onClick={(e) => saveButtonHandler(e)}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default CreatedSuccesfullly;
