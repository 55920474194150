import React from "react";
import img1 from "../../assets/member.png";
import "./Member.scss";
 

const Member = () => {
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-md-12 col-12">
          <div className="member-main">
            <div className="member-container">
              <div className="member-title">
                <div>
                  <p>Became a member</p>
                  <h3>Ready to try different work experience now?</h3>
                  <h6>
                    Get the best working experience that you never feel before
                  </h6>
                  <button type="submit">SIGN UP NOW</button>
                </div>
              </div>
            </div>
            <div className="member-img">
              <img src={img1} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member;
