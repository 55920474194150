const initialState = {
    Company_Details:"",
    get_resource_id:""
  };
  const CompanyReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case "Company_Details":
        return {
          ...state,
          Company_Details:action.payload,
        };
  
      case "get_resource_id":
        return {
          ...state,
          get_resource_id:action.payload,
        };
      default:
        return state;
    }
  };
  export default CompanyReducer;