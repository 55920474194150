import React, { Fragment } from "react";
 
import img from "../../assets/opportunityimg.svg";
import "./Requirments.scss";

const Requirments = () => {
  const HiringResources = [
    "Testing and QA",
    "Analytics",
    "Creative Service",
    "Process Outsourcing",
    "Cloud Info Management",
    "Business Analyst",
    "UI Designer",
    "UX Designer",
    "PHP Developer",
    "Cloud Engineer",
  ];
  return (
    <Fragment>
      <div className="container">
        <div className="row mt-3">
          <div className="header-text">
            <h6 className="header-subtitle">
              <span>Opportunity</span>
            </h6>
            <div className="header-title">
              <h3>Explore Software Resource Requirements</h3>
            </div>
          </div>
          <div className="col-md-5 col-12">
             
              <div class="overflow-auto">
                {HiringResources.map((resources, idx) => (
                  <div className=" resources" key={idx}>
                    {resources}
                  </div>
                ))}
              </div>
             
          </div>
          <div className="col-md-7 col-12  mt-1">
            <div className="about-skipple">
              <img src={img} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Requirments;
