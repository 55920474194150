import React, { Fragment } from "react";

const SingleBlog = ({ SendBlogData }) => {
  console.log(SendBlogData, "Simple Blogs");
  return (
    <Fragment>
      {SendBlogData.slice(0, 4).map((SingleBlog, idx) => (
        <div className="col-md-4  col-12 mb-3" key={idx}>
          <div
            className="blogs-listing blog-card card"
            id={`normal-blog-${SingleBlog.id}`}
          >
            <img
              src={SingleBlog.blogImage}
              className="card-img-top blog-images"
              alt=""
            />
            <div className="card-body single-blogdetails">
              <h5 className="card-title single-blogtitle">
                {SingleBlog.blogTitle}
              </h5>
              <p className="card-text single-blog-profile">
                {SingleBlog.profileName}
              </p>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default SingleBlog;
