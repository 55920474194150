import React, { Fragment } from "react";
// import { Blogs } from "../../data/BlogContent";
 
 
 

const FeaturedBlog = ({ SendBlogData }) => {
  
  return (
    <Fragment>
      {/* {Blogs.filter(Blogs=>Blogs.includes('Empl')).map()} */}
      {SendBlogData.slice(0, 1).map((FeatureBlog, idx) => (
        <div className="col-md-8 col-12 mb-3" key={idx}>
          <div
            className="featured-blog blog-card mb-3"
            id={`feature-blog-${FeatureBlog.id}`}
          >
            <div className="row g-0">
              <div className="col-md-6">  
                <img
                  src={FeatureBlog.blogImage}
                  className="img-fluid rounded-start blog-images"
                  alt={FeatureBlog.blogTitle}
                />
              </div>
              <div className="col-md-6">
                <div className="card-body">
                  <h5 className="card-title blog-feature-title">
                    {FeatureBlog.blogTitle}
                  </h5>
                  <p className="card-text blog-feature-description">
                    {FeatureBlog.blogDescription}
                  </p>
                  <div className="profile-details">
                    <div className="profile-image pe-2">
                      <img
                        src={FeatureBlog.userAvtar}
                        className="profile rounded-circle img-fluid"
                        alt={FeatureBlog.profileName}
                      />
                    </div>
                    <div className="profile-text pe-2">
                      <h5 className="mb-0">{FeatureBlog.profileName}</h5>
                      <p>{FeatureBlog.profileDesignation}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default FeaturedBlog;
