import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";

import "./ResumePreview.scss";
import photoresume from "../../assets/photoresume.svg";
import Address from "../../assets/AddressIcon.svg";
import Phone from "../../assets/PhoneIcon.svg";
import Email from "../../assets/EmailIcon.svg";
import LikedIN from "../../assets/LinkedINIcon.svg";
import WebIcon from "../../assets/WebIcon.svg";
import Skills from "../../assets/SkillIcon.svg";
import Personlinfo from "../../assets/PersonalInfoIcon.svg";
import aboutme from "../../assets/aboutmeicon.svg";
import EduInfoIcon from "../../assets/EducationInfoIcon.svg";
import ProfInfoIcon from "../../assets/ProfessionalInfoIcon.svg";
import { Employee_Resume_Preview_Api } from "../../services/EmployeeAPI";
import APIurl from "../../services/APIurl";
import { useNavigate } from "react-router-dom";

const Resumepreview = (props) => {
  console.log("props",props?.selected)
  const navigate=useNavigate();
  const [employeeData, setEmployeeData] = useState("");

  useEffect(() => {
    
    let id = localStorage.getItem("id");
    Employee_Resume_Preview_Api()
      .then((res) => {
        setEmployeeData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("employeeData?.linkedIn",employeeData?.linkedIn)
  // let startDate=employeeData?.education?.startDate?.split('/');
  // let startMonth=startDate[0];
  // let startYear=startDate[1];

  // let endDate=employeeData?.education?.endDate?.split('/');
  // let endMonth=endDate[0];
  // let endYear=endDate[1];

  // console.log("start",startMonth)
 
  const cancelButtonHandler = () =>{
    // props.selectedTab(7);
    navigate('/employee-manual-profile-page2',{state:7})
  }
  return (
    <Fragment>
      <div className="container resume mb-4">
        <div className="resume-content">
          <Row>
            <Col sm={4} md={4} xsm={4} className="left-content">
              <div>
                <div className="photo mb-3">
                  <img
                    // `${APIurl.backendUrl}/${props.selectedFileName}`
                    src={`${APIurl.backendUrl}/${employeeData?.uploadPhoto}`}
                    alt="not found"
                    width="200"
                    height="200"
                  />
                </div>
                <div className="name-text mb-4">{employeeData?.fullName}</div>
                <div className="mb-4">
                  <Row className="mb-2">
                    <Col sm={1} md={1} xs={1}>
                      <img src={Address} />
                    </Col>
                    <Col sm={11} md={11} xs={11} className="contac-info-text">
                      {employeeData?.address}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={1} md={1} xs={1}>
                      <img src={Phone} />
                    </Col>
                    <Col sm={11} md={11} xs={11} className="contac-info-text">
                      {employeeData.phone}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={1} md={1} xs={1}>
                      <img src={Email} />
                    </Col>
                    <Col sm={11} md={11} xs={11} className="contac-info-text">
                      {employeeData?.email}
                    </Col>
                  </Row>
                  {employeeData?.linkedIn && (
                    <>
                      <Row className="mb-2">
                        <Col sm={1} md={1} xs={1}>
                          <img src={LikedIN} />
                        </Col>
                        <Col
                          sm={11}
                          md={11}
                          xs={11}
                          className="contac-info-text"
                        >
                          {employeeData?.linkedIn}
                        </Col>
                      </Row>
                    </>
                  )}
                  {employeeData?.portfolio  && (
                    <>
                      <Row className="mb-2">
                        <Col sm={1} md={1} xs={1}>
                          <img src={WebIcon} />
                        </Col>
                        <Col
                          sm={11}
                          md={11}
                          xs={11}
                          className="contac-info-text"
                        >
                          {employeeData?.portfolio}
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <Row>
                    <Col sm={2} md={2} xs={2}>
                      <img src={Skills} />
                    </Col>
                    <Col sm={10} md={10} xs={10}>
                      <div className="skill-text mb-3">Skills </div>
                      <div className="skill-fild-text">
                        {employeeData?.skills?.map((item) => {
                          return (
                            <>
                              <p>{item}</p>
                            </>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-4">
                  <Row>
                    <Col sm={2} md={2} xs={2}>
                      <img src={Personlinfo} />
                    </Col>
                    <Col sm={10} md={10} xs={10}>
                      <div className="skill-text mb-3">
                        Personal Information
                      </div>
                      <Row>
                        {/* <Col sm={6} md={6} xs={5}>
                          <div className="PerInfo-fild-text ">
                            <div>Date Of Birth</div>
                            <div>Gender</div>
                            <div>Languages</div>
                            <div>Marital Status</div>
                          </div>
                        </Col>
                        <Col sm={6} md={6} xs={5}>
                          <div className="skill-fild-text">
                            <div>{employeeData?.birthDate}</div>
                            <div>{employeeData?.gender}</div>
                            {employeeData?.language?.map((item, index) => {
                              return <>{`${item},`}</>;
                            })}
                            <div>{employeeData?.maritalStatus}</div>
                          </div>
                        </Col> */}
                        <Col>
                          <Row>
                            <Col className="PerInfo-fild-text ">Date Of Birth</Col>
                            <Col className="skill-fild-text">{employeeData?.birthDate}</Col>
                          </Row>
                          <Row>
                            <Col className="PerInfo-fild-text ">Gender</Col>
                            <Col className="skill-fild-text">{employeeData?.gender}</Col>
                          </Row>
                          <Row>
                            <Col className="PerInfo-fild-text ">Languages</Col>
                            <Col className="skill-fild-text">
                            {employeeData?.language?.map((item, index) => {
                              return <>{`${item},`}</>;
                            })}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="PerInfo-fild-text ">
                              Marital Status
                            </Col>
                            <Col className="skill-fild-text">{employeeData?.maritalStatus}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col sm={8} md={8} xsm={8}>
              <div className="right-content">
                {employeeData?.aboutMe !== "" &&
                  employeeData?.aboutMe !== null && (
                    <>
                      <div className="mb-4">
                        <div className="resume-heading-icon">
                          <img src={aboutme} />
                        </div>
                        <div className="resume-heading-text">
                          <div className="resume-heading-in-text">About Me</div>
                        </div>
                      </div>
                      <div className="fild-text-content mb-4">
                        {employeeData?.aboutMe}
                      </div>
                    </>
                  )}
                <div className="mb-4">
                  <div className="resume-heading-icon">
                    <img src={EduInfoIcon} />
                  </div>
                  <div className="resume-heading-text">
                    <div className="resume-heading-in-text">
                      Education Information
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <Row className="d-flex">
                    <Col sm={3} md={3} xs={6} className="mb-3">
                      <div className="fild-heading-text-content">
                        <div>Qualification</div>
                        <div>University Name</div>
                        <div>Degree</div>
                      </div>
                    </Col>
                    <Col sm={6} md={6} xs={6}>
                      <div className="fild-text-content">
                        <div>{employeeData?.education?.qualification}</div>
                        <div>{employeeData?.education?.universityName}</div>
                        <div>{employeeData?.education?.courseName}</div>
                      </div>
                    </Col>
                    <Col sm={3} md={3} xs={12}>
                      <div className="fild-text-content d-flex justify-content-end">
                        {/* 2017 - 2019 */}
                        {employeeData?.education?.isPursuing === false
                          ? `${
                              employeeData?.education?.startDate?.split("/")[1]
                            }-${
                              employeeData?.education?.endDate?.split("/")[1]
                            }`
                          : employeeData?.education?.startDate?.split("/")[1]}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-4">
                  <div className="resume-heading-icon">
                    <img src={ProfInfoIcon} />
                  </div>
                  <div className="resume-heading-text">
                    <div className="resume-heading-in-text">
                      Professional Information
                    </div>
                  </div>
                </div>
                {employeeData?.experience == "Fresher" ? <Row className="d-flex">
                    <Col sm={5} md={5} xs={12} className="mb-3">
                      <div className="fild-heading-text-content">
                        <div>Area Of Interest</div>
                        <div>Area of Interest Description</div>
                      </div>
                    </Col>
                    <Col sm={7} md={7} xs={12}>
                      <div className="fild-text-content">
                        <div>{employeeData?.interestArea}</div>
                        <div>{employeeData?.descriptionOfInterest}</div>
                      </div>
                    </Col>
                    
                  </Row> : employeeData?.work?.map((item,index)=>{
                  return (<div className="mb-4">
                  <Row className="d-flex">
                    <Col sm={3} md={3} xs={6} className="mb-3">
                      <div className="fild-heading-text-content">
                        <div>Company Name</div>
                        <div>Position</div>
                      </div>
                    </Col>
                    <Col sm={6} md={6} xs={6}>
                      <div className="fild-text-content">
                        <div>{item.companyName}</div>
                        <div>{item?.jobPosition}</div>
                      </div>
                    </Col>
                    <Col sm={3} md={3} xs={12}>
                      <div className="fild-text-content d-flex justify-content-end">
                       { item?.startDate + "-" + item?.endDate}
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="fild-heading-text-content">Description</div>
                    <div className="fild-text-content">
                     {item?.workDescription}
                    </div>
                  </div>
                </div>)
                })}
               
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container">
        <div className="end-bt">
          <Row className="bt">
            <Col className="bt">
              <Button className="mb-3 Cancel-bt" onClick={cancelButtonHandler}>Cancel</Button>
            </Col>
            <Col>
              <Button className="mb-3 Save-bt ">Save</Button>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default Resumepreview;
