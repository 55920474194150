import { Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import "./UploadResume.scss";

const UploadResume = () => {
  return (
    <Fragment>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 side mt-3">
                    <Button className=" btn PreviewResume mb-3 " size="lg" type="submit">
                        Submit
                    </Button>
                    <Button className=" btn PreviewResume mb-3 " size="lg">
                        Choose Another
                    </Button>
                </div>
                <div className="col-md-9">
                    <div>

                    </div>
                </div>
            </div>
        </div>

    </Fragment>
    // <Fragment>
    //   <div className="container-fluid">
    //     <Row>
    //       <div>
    //         <Row>
    //           <Col>Personal Information</Col>
    //         </Row>
    //       </div>
    //     </Row>
    //   </div>
    // </Fragment>
  );
};

export default UploadResume;
