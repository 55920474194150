import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import { EmployeeAPI } from "../../../services/EmployeeAPI";
import CustomInput from "../../CustomInput/CustomInput";
import {
  CourseType,
  Month,
  QualificationType,
  Year,
} from "../../Dropdowns/DropdownList";
import MyComponent from "../../Dropdowns/Dropdowns2";
import { education_info_resume_data } from "../../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const EducatoinalInfo = (props) => {
  // const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const [pursuing, setPursuing] = useState(state?.EmployeeManualResumeReducer?.educationalInfoResumeData?.isPursuing) ;
  console.log("pusre", state?.EmployeeManualResumeReducer?.educationalInfoResumeData?.isPursuing);
  // const [startEndDateData, setStartEndDateData] = useState({
  //   startMonthData: "",
  //   startYearData: "",
  //   endMonthData: "",
  //   endYearData: "",
  // });

  const [educationalInfo, setEducationalInfo] = useState({
    qualification:
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.qualification,
    course_name:
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.courseName,
    course_type:
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.courseType,
    start_month:
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.startMonth,
    start_year:
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.startYear,
    end_month:
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.endMonth,
    end_year:
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.endYear,
    university:
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.universityName,
  });

  const [error, setError] = useState({
    qualificationErr: "",
    courseNameErr: "",
    universityErr: "",
    courseTypeErr: "",
    startMonthErr: "",
    startYearErr: "",
    endMonthErr: "",
    endYearErr: "",
  });
  const errorStyle = {
    fontSize: 12,
    color: "red",
  };

  useEffect(() => {
    setEducationalInfo({
      qualification:
        state.EmployeeManualResumeReducer.educationalInfoResumeData
          ?.qualification,
      course_name:
        state.EmployeeManualResumeReducer.educationalInfoResumeData?.courseName,
      course_type:
        state.EmployeeManualResumeReducer.educationalInfoResumeData?.courseType,
      start_month:
        state.EmployeeManualResumeReducer.educationalInfoResumeData?.startMonth,
      start_year:
        state.EmployeeManualResumeReducer.educationalInfoResumeData?.startYear,
      end_month:
        state.EmployeeManualResumeReducer.educationalInfoResumeData?.endMonth,
      end_year:
        state.EmployeeManualResumeReducer.educationalInfoResumeData?.endYear,
      university:
        state.EmployeeManualResumeReducer.educationalInfoResumeData?.universityName,
    });
    setPursuing(
      state.EmployeeManualResumeReducer.educationalInfoResumeData?.isPursuing 
    );
  }, [state.EmployeeManualResumeReducer.educationalInfoResumeData]);


  // useEffect(()=>{
  //   console.log("dffg",pursuing)
  // },[state.EmployeeManualResumeReducer.educationalInfoResumeData])

  const educationalInfoInputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setEducationalInfo((values) => ({ ...values, [name]: value }));
    if (name == "university") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setError({
          ...error,
          universityErr: "University name must contain Alphabet only",
        });
      } else {
        setError({ ...error, universityErr: "" });
      }
    }
    if (name == "course_name") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setError({
          ...error,
          courseNameErr: "course name must contain Alphabet only",
        });
      } else {
        setError({ ...error, courseNameErr: "" });
      }
    }
  };

  const educationalInfoDropDownHandler = (event) => {
    const name = event.name;
    const value = event.value;
    setEducationalInfo((values) => ({ ...values, [name]: value }));
    if (name === "qualification") {
      if (value != "" && value != null) {
        setError({ ...error, qualificationErr: "" });
      }
    }
    if (name === "course_type") {
      if (value != "" && value != null) {
        setError({ ...error, courseTypeErr: "" });
      }
    }
    if (name === "start_month") {
      if (value != "" && value != null) {
        setError({ ...error, startMonthErr: "" });
      }
    }
    if (name === "start_year") {
      if (value != "" && value != null) {
        setError({ ...error, startYearErr: "" });
      }
    }
    if (name === "end_month") {
      if (value != "" && value != null) {
        setError({ ...error, endMonthErr: "" });
      }
    }
    if (name === "end_year") {
      if (value != "" && value != null) {
        setError({ ...error, endYearErr: "" });
      }
    }
  };

  const validate = () => {
    let qualificationError = error.qualificationErr;
    let courseNameError = error.courseNameErr;
    let universityError = error.universityErr;
    let courseTypeError = error.courseTypeErr;
    let startMonthError = error.startMonthErr;
    let startYearError = error.startYearErr;
    let endMonthError = error.endMonthErr;
    let endYearError = error.endYearErr;

    if (
      educationalInfo.qualification == "" ||
      educationalInfo.qualification == null
    ) {
      qualificationError = "qualification is required";
    }

    if (
      educationalInfo.course_name == "" ||
      educationalInfo.course_name == null
    ) {
      courseNameError = "Course name is required";
    }

    if (
      educationalInfo.university == "" ||
      educationalInfo.university == null
    ) {
      universityError = "university is required";
    }
    if (
      educationalInfo.course_type == "" ||
      educationalInfo.course_type == null
    ) {
      courseTypeError = "course type is required";
    }
    if (
      educationalInfo.start_month == "" ||
      educationalInfo.start_month == null
    ) {
      startMonthError = "starting month  is required";
    }
    if (
      educationalInfo.start_year == "" ||
      educationalInfo.start_year == null
    ) {
      startYearError = "starting year is required";
    }
    if (pursuing == false) {
      console.log("empty");
      if (
        educationalInfo.end_month == "" ||
        educationalInfo.end_month == null
      ) {
        endMonthError = "ending month  is required";
      }
      if (educationalInfo.end_year == "" || educationalInfo.end_year == null) {
        endYearError = "ending year is required";
      }
    }

    setError({
      qualificationErr: qualificationError,
      courseNameErr: courseNameError,
      universityErr: universityError,
      courseTypeErr: courseTypeError,
      startMonthErr: startMonthError,
      startYearErr: startYearError,
      endMonthErr: endMonthError,
      endYearErr: endYearError,
    });
  };
  const onSaveHandler = (e) => {
    e.preventDefault();
    validate();
    let education;
    if (
      educationalInfo.qualification !== null &&
      educationalInfo.qualification !== "" &&
      educationalInfo.course_name !== null &&
      educationalInfo.course_name !== "" &&
      educationalInfo.university !== null &&
      educationalInfo.university !== "" &&
      educationalInfo.course_type !== null &&
      educationalInfo.course_type !== "" &&
      educationalInfo.start_month !== null &&
      educationalInfo.start_year !== ""
      // educationalInfo.end_month !== null &&
      // educationalInfo.end_year !== ""
    ) {
      if (pursuing == false) {
        if (
          educationalInfo.end_month !== null &&
          educationalInfo.end_month !== "" &&
          educationalInfo.end_year !== "" &&
          educationalInfo.end_year !== null
        ) {
          education = {
            qualification: educationalInfo.qualification,
            courseName: educationalInfo.course_name,
            courseType: educationalInfo.course_type,
            universityName: educationalInfo.university,
            startDate:
              educationalInfo.start_month + "/" + educationalInfo.start_year,
            endDate: educationalInfo.end_month + "/" + educationalInfo.end_year,
            isPursuing: pursuing,
          };
        }
      } else {
        education = {
          qualification: educationalInfo.qualification,
          courseName: educationalInfo.course_name,
          courseType: educationalInfo.course_type,
          universityName: educationalInfo.university,
          startDate:
            educationalInfo.start_month + "/" + educationalInfo.start_year,
          isPursuing: pursuing,
        };
      }

      EmployeeAPI({ education })
        .then((result) => {
          let educationalData;
          if (pursuing == false) {
            // let startDateData = result.data[0].education.startDate.split("/");
            // let endDateData = result.data[0].education.endDate.split("/");
            // let startMonthData = startDateData[0];
            // let startYearData = startDateData[1];
            // let endMonthData = endDateData[0];
            // let endYearData = endDateData[1];

            educationalData = {
              qualification: educationalInfo.qualification,
              courseName: educationalInfo.course_name,
              courseType: educationalInfo.course_type,
              universityName: educationalInfo.university,
              startMonth: educationalInfo.start_month,
              startYear: educationalInfo.start_year,
              endMonth: educationalInfo.end_month,
              endYear: educationalInfo.end_year,
              isPursuing: pursuing,
            };
          } else {
            // let startDateData = result.data[0].education.startDate.split("/");
            // let startMonthData = startDateData[0];
            // let startYearData = startDateData[1];

            educationalData = {
              qualification: educationalInfo.qualification,
              courseName: educationalInfo.course_name,
              courseType: educationalInfo.course_type,
              universityName: educationalInfo.university,
              startMonth: educationalInfo.start_month,
              startYear: educationalInfo.start_year,
              endMonth: "",
              endYear: "",
              isPursuing: pursuing,
            };
          }

          dispatch(education_info_resume_data(educationalData));
          props.selected(5);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  const cancelButtonHandler = (e) => {
    e.preventDefault();
    let educationalInfoResumeData = {
      qualification: null,
      courseName: "",
      university: "",
      courseType: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      isPursuing: false,
    };
    dispatch(education_info_resume_data(educationalInfoResumeData));
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <p className="head-text">
          Let Us Know About Your Educational Background
        </p>
        <Row className="justify-content-center mb-3 card">
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card.Body className="main-card py-0">
              <h1 className="header-cont mb-5">Educational Information</h1>
              <div>
                <Row className="mb-4">
                  <Col sm={6} md={6} className="bt">
                    <div class="form-check">
                      <input
                        class="form-check-input bt-bt"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        value={pursuing}
                        // defaultValue={pursuing}
                        onClick={() => setPursuing(!pursuing)}
                        checked={pursuing}
                        
                      />

                      <label
                        class="form-check-label bt-text"
                        for="flexRadioDefault1"
                      >
                        Pursuing
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">
                      Last Highest Qualification *
                    </div>
                    <div className="w-100">
                      <MyComponent
                        placeholder="i.e. Post Graduation"
                        options={QualificationType}
                        name="qualification"
                        value={educationalInfo.qualification}
                        default={educationalInfo.qualification}
                        onChange={educationalInfoDropDownHandler}
                      />
                    </div>
                    {error.qualificationErr && (
                      <p style={errorStyle}>{error.qualificationErr}</p>
                    )}
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="fild-text">Course Name *</div>
                    <div className="w-100">
                      <CustomInput
                        placeholder="i.e. MBA"
                        type="text"
                        name="course_name"
                        value={educationalInfo.course_name}
                        default={educationalInfo.course_name}
                        onChange={educationalInfoInputChangeHandler}
                      />
                    </div>
                    {error.courseNameErr && (
                      <p style={errorStyle}>{error.courseNameErr}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">University Name *</div>
                    <div className="w-100">
                      <CustomInput
                        placeholder="i.e. abc University/College"
                        type="text"
                        name="university"
                        // value={educationalInfo.university}
                        default={educationalInfo?.university}
                        onChange={educationalInfoInputChangeHandler}
                      />
                    </div>
                    {error.universityErr && (
                      <p style={errorStyle}>{error.universityErr}</p>
                    )}
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="fild-text">Course Type *</div>
                    <div className="w-100">
                      <MyComponent
                        placeholder="i.e. Full Time"
                        options={CourseType}
                        name="course_type"
                        value={educationalInfo.course_type}
                        default={educationalInfo.course_type}
                        onChange={educationalInfoDropDownHandler}
                      />
                    </div>
                    {error.courseTypeErr && (
                      <p style={errorStyle}>{error.courseTypeErr}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">Start Time *</div>
                    <div>
                      <Row>
                        <Col sm={6} md={6}>
                          <MyComponent
                            placeholder="Month"
                            name="start_month"

                            value={
                              Month.filter(
                                (fil) =>
                                  fil.value == educationalInfo.start_month
                              )[0]?.label
                            }
                            default={
                              Month.filter(
                                (fil) =>
                                  fil.value == educationalInfo.start_month
                              )[0]?.label
                            }
                            onChange={educationalInfoDropDownHandler}
                            options={Month}
                          />
                          {error.startMonthErr && (
                            <p style={errorStyle}>{error.startMonthErr}</p>
                          )}
                        </Col>

                        <Col sm={6} md={6}>
                          <MyComponent
                            placeholder="Year"
                            options={Year}
                            name="start_year"
                            value={educationalInfo.start_year}
                            default={educationalInfo.start_year}
                            onChange={educationalInfoDropDownHandler}
                          />
                          {error.startYearErr && (
                            <p style={errorStyle}>{error.startYearErr}</p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="fild-text">End Time *</div>
                    <div>
                      <Row>
                        {pursuing ? (
                          <p>Pursuing</p>
                        ) : (
                          <>
                            <Col sm={6} md={6}>
                              <MyComponent
                                // disabled={pursuing?true:false}
                                placeholder="Month"
                                options={Month}
                                name="end_month"
                                value={
                                  Month.filter(
                                    (fil) =>
                                      fil.value == educationalInfo.end_month
                                  )[0]?.label
                                }
                                default={
                                  Month.filter(
                                    (fil) =>
                                      fil.value == educationalInfo.end_month
                                  )[0]?.label
                                }
                                onChange={educationalInfoDropDownHandler}
                              />
                              {error.endMonthErr && (
                                <p style={errorStyle}>{error.endMonthErr}</p>
                              )}
                            </Col>
                            <Col sm={6} md={6}>
                              <MyComponent
                                // disabled={pursuing?true:false}
                                placeholder="Year"
                                options={Year.filter(
                                  (item) =>
                                    item.value > educationalInfo.start_year
                                )}
                                name="end_year"
                                value={educationalInfo.end_year}
                                default={educationalInfo.end_year}
                                onChange={educationalInfoDropDownHandler}
                              />
                              {error.endYearErr && (
                                <p style={errorStyle}>{error.endYearErr}</p>
                              )}
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col sm={3} md={3} className="bt">
                    <Button className="mb-3 back-bt">Back</Button>
                    <Button className="mb-3 add-bt ">Add</Button>
                  </Col>
                </Row> */}
              </div>
            </Card.Body>
          </Col>
        </Row>
        <div className="end-bt">
          <Row className="bt">
            <Col className="bt">
              <Button
                className="mb-3 Cancel-bt"
                onClick={(e) => cancelButtonHandler(e)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="mb-3 Save-bt " onClick={(e)=>onSaveHandler(e)}>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};
export default EducatoinalInfo;
