import React, { useRef } from "react";
import "./Comment.scss";
import img from "../../assets/Ellipse 1.png";
import leftArrow from "../../assets/leftArrow.svg";
import rightArrow from "../../assets/rightArrow.svg";

import { Carousel } from "react-bootstrap";

const Comment = () => {
  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };
  return (
    <div className="container">
      <div className="col-md-12">
        <div className="header-text">
          <h6 className="header-subtitle">
            <span>Reviews</span>
          </h6>
          <div className="header-title">
            <h3> What Our Customers Are Saying</h3>
          </div>
        </div>

        <div className="col-md-0 text-end">
          <button
            className=" btn-slider md-0"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
            onClick={onPrevClick}
          >
            <img src={leftArrow} alt="left-icon" className="me-3" />
          </button>
          <button
            className=" btn-slider "
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
            onClick={onNextClick}
          >
            <img src={rightArrow} alt="right-icon" />
          </button>
        </div>
        <div className="col-md-12 mt-3">
          <Carousel ref={ref} indicators={false} controls={false}>
            <Carousel.Item interval={5000}>
              <div className="row">
                <div className="col-md-4 col-12 mb-3">
                  <div className="card resource-card-back">
                    <div className="com-main">
                      <div className="com-1">
                        <p className="com-text">
                          Lorem ipsum dolor sit amet, consectetur adipis cing
                          elit. Mollis metus, pulvinar ac tempus et quisque
                          consectetur. Sed vitae, enim, in tellus sed. Elementum
                          non cum eget massa.Sed vitae, enim, in tellus sed.
                          Elementum non cum eget massa.
                        </p>
                        <div className="comi">
                          <div>
                            <img src={img}  className='img-fluid'  alt=""/> 
                          </div>
                          <div>
                            <p className="blog-writer">Devon Baxter</p>
                            <h6 className="blog-post">UI Designer </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cta-btn"></div>
                  </div>
                </div>
                <div className="col-md-4 col-12 mb-3">
                  <div className="card resource-card-back">
                    <div className="com-main">
                      <div className="com-1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipis cing
                          elit. Mollis metus, pulvinar ac tempus et quisque
                          consectetur. Sed vitae, enim, in tellus sed. Elementum
                          non cum eget massa.Sed vitae, enim, in tellus sed.
                          Elementum non cum eget massa.
                        </p>
                        <div className="comi">
                          <div>
                            <img src={img} className='img-fluid' alt=""/> 
                          </div>
                          <div>
                            <p>Devon Baxter</p>
                            <h6>UI Designer </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cta-btn"></div>
                  </div>
                </div>
                <div className="col-md-4 col-12 mb-3">
                  <div className="card resource-card-back">
                    <div className="com-main">
                      <div className="com-1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipis cing
                          elit. Mollis metus, pulvinar ac tempus et quisque
                          consectetur. Sed vitae, enim, in tellus sed. Elementum
                          non cum eget massa.Sed vitae, enim, in tellus sed.
                          Elementum non cum eget massa.
                        </p>
                        <div className="comi">
                          <div>
                            <img src={img}  className='img-fluid'  alt=""/> 
                          </div>
                          <div>
                            <p>Devon Baxter</p>
                            <h6>UI Designer </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cta-btn"></div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <div className="row">
                <div className="col-md-4 col-12 mb-3">
                  <div className="card resource-card-back">
                    <div className="com-main">
                      <div className="com-1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipis cing
                          elit. Mollis metus, pulvinar ac tempus et quisque
                          consectetur. Sed vitae, enim, in tellus sed. Elementum
                          non cum eget massa.Sed vitae, enim, in tellus sed.
                          Elementum non cum eget massa.
                        </p>
                        <div className="comi">
                          <div>
                            <img src={img}  className='img-fluid' alt=""/> 
                          </div>
                          <div>
                            <p>Devon Baxter</p>
                            <h6>UI Designer </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cta-btn"></div>
                  </div>
                </div>
                <div className="col-md-4 col-12 mb-3">
                  <div className="card resource-card-back">
                    <div className="com-main">
                      <div className="com-1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipis cing
                          elit. Mollis metus, pulvinar ac tempus et quisque
                          consectetur. Sed vitae, enim, in tellus sed. Elementum
                          non cum eget massa.Sed vitae, enim, in tellus sed.
                          Elementum non cum eget massa.
                        </p>
                        <div className="comi">
                          <div>
                            <img src={img} className='img-fluid'  alt=""/>
                          </div>
                          <div>
                            <p>Devon Baxter</p>
                            <h6>UI Designer </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cta-btn"></div>
                  </div>
                </div>
                <div className="col-md-4 col-12 mb-3">
                  <div className="card resource-card-back">
                    <div className="com-main">
                      <div className="com-1">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipis cing
                          elit. Mollis metus, pulvinar ac tempus et quisque
                          consectetur. Sed vitae, enim, in tellus sed. Elementum
                          non cum eget massa.Sed vitae, enim, in tellus sed.
                          Elementum non cum eget massa.
                        </p>
                        <div className="comi">
                          <div>
                            <img src={img} className='img-fluid'  alt=""/> 
                          </div>
                          <div>
                            <p>Devon Baxter</p>
                            <h6>UI Designer </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cta-btn"></div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Comment;
