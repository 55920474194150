import React from "react";
import Select from "react-select";
import "./Dropdown2.scss";

const MyComponent = (props) => {
    
  return (
    <Select    
      className="drop"
      placeholder={props.placeholder}
      defaultInputValue={props.default}      
      options={props.options}
      name={props.name}
      onChange={(e)=>props.onChange({name:props.name,value:e.value})}
      defaultValue={props.default}
      isDisabled={props.disabled}
      // inputValue = {props.value}
      // value = {props.value}
    />
  );
};
export default MyComponent;
