import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import "./ContactInfo.scss";
import CustomInput from "../../CustomInput/CustomInput";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { EmployeeAPI } from "../../../services/EmployeeAPI";
import {
  contact_info_resume_data,
  EmployeeManualResume,
} from "../../../redux/actions/action";

const ContactInfo = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const contactInfoState = useSelector(
    (state) => state.EmployeeManualResumeReducer.contactInfoResumeData
  );

  const [contactInfo, setContactInfo] = useState({
    email: state.EmployeeManualResumeReducer.contactInfoResumeData.email,
    alternate_email:
      state.EmployeeManualResumeReducer.contactInfoResumeData.alternateEmail,
    address: state.EmployeeManualResumeReducer.contactInfoResumeData.address,
    phone: state.EmployeeManualResumeReducer.contactInfoResumeData.phone,
    alternate_phone:
      state.EmployeeManualResumeReducer.contactInfoResumeData.alternatePhone,
  });
  // console.log("data submit", contactInfo.email);
  const [error, setError] = useState({
    emailErr: "",
    phoneErr: "",
    alternateEmailErr: "",
    alternatePhoneErr: "",
  });

  useEffect(() => {
    if (state.EmployeeManualResumeReducer.contactInfoResumeData) {
      setContactInfo({
        email: state.EmployeeManualResumeReducer.contactInfoResumeData.email,
        alternate_email:
          state.EmployeeManualResumeReducer.contactInfoResumeData
            .alternateEmail,
        address:
          state.EmployeeManualResumeReducer.contactInfoResumeData.address,
        phone: state.EmployeeManualResumeReducer.contactInfoResumeData.phone,
        alternate_phone:
          state.EmployeeManualResumeReducer.contactInfoResumeData
            .alternatePhone,
      });
    }
  }, [state.EmployeeManualResumeReducer.contactInfoResumeData]);

  const errorStyle = {
    fontSize: 12,
    color: "red",
  };
  const validate = () => {
    let emailError = error.emailErr;
    let phoneError = error.phoneErr;

    if (contactInfo.email == "" || contactInfo.email == null) {
      emailError = "Email is required";
    }
    if (contactInfo.phone == "" || contactInfo.phone == null) {
      phoneError = "Phone number is required";
    }

    setError({
      emailErr: emailError,
      phoneErr: phoneError,
    });
  };
  console.log("contactInfo", contactInfo);
  const cancelButtonHandler = (e) => {
    e.preventDefault();
    let contactInfoResumeData = {
      email: state.EmployeeManualResumeReducer.contactInfoResumeData.email,
      alternateEmail: "",
      phone: "",
      alternatePhone: "",
      address: "",
    };
    // setContactInfo(contactInfoResumeData)
    dispatch(contact_info_resume_data(contactInfoResumeData));
    // window.location.reload()
  };
  const saveButtonHandler = () => {
    validate();

    if (
      contactInfo.email !== null &&
      error.emailErr == "" &&
      error.phoneErr == "" &&
      contactInfo.email !== "" &&
      contactInfo.phone !== null &&
      contactInfo.phone !== ""
    ) {
      let data = {
        email: contactInfo.email,
        alternateEmail: contactInfo.alternate_email,
        address: contactInfo.address,
        phone: contactInfo.phone,
        alternatePhone: contactInfo.alternate_phone,
      };
      EmployeeAPI(data)
        .then((result) => {
          let contactInfoData = {
            email: contactInfo.email,
            alternateEmail: contactInfo.alternate_email,
            address: contactInfo.address,
            phone: contactInfo.phone,
            alternatePhone: contactInfo.alternate_phone,
          };
          dispatch(contact_info_resume_data(contactInfoData));
          props.selected(3);
          // navigate('/employee-manual-profile-contact-information');
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  const contactInfoInputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setContactInfo((values) => ({ ...values, [name]: value }));

    if (name === "email") {
      if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
        setError({ ...error, emailErr: "Please Enter Valid Email" });
      } else {
        setError({ ...error, emailErr: "" });
      }
    }
    if (name === "alternate_email") {
      if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
        setError({ ...error, alternateEmailErr: "Please Enter Valid Email" });
      } else {
        setError({ ...error, alternateEmailErr: "" });
      }
    }
    if (name === "phone") {
      if (
        !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)
      ) {
        setError({ ...error, phoneErr: "Please Enter Valid  Phone no" });
      } else {
        setError({ ...error, phoneErr: "" });
      }
    }
    if (name === "alternate_phone") {
      if (
        !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)
      ) {
        setError({
          ...error,
          alternatePhoneErr: "Please Enter Valid  Phone no",
        });
      } else {
        setError({ ...error, alternatePhoneErr: "" });
      }
    }
  };
  // useEffect(() => {
  //   console.log("contactInfo", contactInfo);
  // }, [contactInfo]);
  return (
    <Fragment>
      <div className="container-fluid">
        <p className="head-text">
          Reqruiter Will Contact You, Provide Your Contact Information Here
        </p>
        {/* {contactInfo && (
          <Fragment> */}
        <Row className="justify-content-center mb-3 card">
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card.Body className="main-card py-0">
              <h1 className="header-cont mb-5">Contact Information</h1>
              <div>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">Email ID *</div>
                    <div className="w-100">
                      <CustomInput
                        placeholder="Email Id"
                        type="email"
                        name="email"
                        disabled="true"
                        value={contactInfo?.email}
                        defaultValue={contactInfo?.email}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                    {error.emailErr && (
                      <p style={errorStyle}>{error.emailErr}</p>
                    )}
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="fild-text">Alternate Email ID</div>
                    <div className="w-100">
                      <CustomInput
                        placeholder="Alternate Email Id"
                        type="email"
                        name="alternate_email"
                        defaultValue={contactInfo?.alternate_email}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                    {error.alternateEmailErr && (
                      <p style={errorStyle}>{error.alternateEmailErr}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">Phone Number *</div>
                    <div className="w-100">
                      <CustomInput
                        placeholder="Phone Number"
                        name="phone"
                        type="text"
                        value={contactInfo?.phone}
                        defaultValue={contactInfo?.phone}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                    {error.phoneErr && (
                      <p style={errorStyle}>{error.phoneErr}</p>
                    )}
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="fild-text">Alternate Phone Number</div>
                    <div className="w-100">
                      <CustomInput
                        placeholder="Alternate Phone Number"
                        name="alternate_phone"
                        type="text"
                        defaultValue={contactInfo.alternate_phone}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                    {error.alternatePhoneErr && (
                      <p style={errorStyle}>{error.alternatePhoneErr}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={12} md={12}>
                    <div className="fild-text">Address</div>
                    <div>
                      <textarea
                        placeholder="Address"
                        className="text-area area"
                        name="address"
                        defaultValue={contactInfo?.address}
                        onChange={contactInfoInputChangeHandler}
                      ></textarea>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Col>
        </Row>
        <div className="end-bt">
          <Row className="bt">
            <Col className="bt">
              <Button
                className="mb-3 Cancel-bt"
                onClick={(e) => cancelButtonHandler(e)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="mb-3 Save-bt " onClick={saveButtonHandler}>
                Save
              </Button>
            </Col>
          </Row>
        </div>
        {/* </Fragment>
        )} */}
      </div>
    </Fragment>
  );
};
export default ContactInfo;
