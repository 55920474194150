import { Fragment, useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    Alert,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Button,
} from "react-bootstrap";
import './SuperAdminLogin.scss'
import email from '../../assets/email.svg'
import password from '../../assets/password.svg'
import eye from '../../assets/opne-eyeicon.svg'
import { ToastContainer, toast } from "react-toastify";
import { MicroServicePostRequestAPI } from "../../services/ApiService";
import { useNavigate } from "react-router-dom";


const SuperAdminLogin = () => {
    const [inputs, setInputs] = useState({ Email: '', Password: '' });
    const [error, setError] = useState({
        password: "",
        emailErr: "",
        allError: ""
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const errorStyle = {
        fontSize: 12,
        color: "red",
    }
    const navigate = useNavigate();
    const signupHandler = (e) => {
        e.preventDefault();
        navigate("/new-signup");
    };
    const forgotpassword = (e) => {
        e.preventDefault();
        navigate("/forgotpassword");
    };
    const inputChangeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))

        if (name === "Password") {
            if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value)) {
                setError({ ...error, password: "Password must be 8 or more characters in length && Use upper, lower, digit and special character" })
            }
            else {
                setError({ ...error, password: null })
            }
        }
        if (name === "Email") {
            if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
                setError({ ...error, emailErr: "Please Enter Valid Email" });

            }
            else {
                setError({ ...error, emailErr: null })
            }
        }
    }

    useEffect(() => {
        if (error?.allError !== null && error?.allError !== "") {
            if (inputs.Email === "" || inputs.Password === "") {
                setError({ ...error, allError: "All Field Required" })
            }
            else {
                setError({ ...error, allError: null })
            }
        }
    }, [inputs])

    const handleSubmit = (e) => {

        e.preventDefault();

        if (inputs.Email === "" || inputs.Password === "") {
            setError({ ...error, allError: "All Field Required" })
        }
        else {
            setError({ ...error, allError: null })

            if (error.password == null && error.emailErr == null) {
                // setError({ allError: '' })
                const inputData = {
                    email: inputs.Email,
                    password: inputs.Password
                }

                MicroServicePostRequestAPI('api/auth/admin/login', 'post', inputData).then((result) => {
                    if (result) {
                        localStorage.setItem('token', result.data.token);
                        toast.success('login successfully');
                    }
                }).catch((err) => {
                    if (err.data.success === false) {
                        toast.error(err.data.description)
                    }
                });
            }
        }

    }
    return (
        <Fragment>
            {/* <Container> */}
                <div className="container-fluid">               
                 <Row className="justify-content-center back">
                    <Col xs={10} md={8} lg={6} xl={5}>
                        <Card className="mt-5 p-3 card-back">
                            <Card.Body className="main-card py-0 ">
                                <div className="header-content">
                                    <h4 className="mt-3 skiple-text">Skiple</h4>
                                    <h5 className="login-to-Skiple-text">Login to Skiple</h5>
                                    <Form className="mt-4">
                                        <Form.Group className="mb-3 " controlId="formBasicEmail">
                                            <label className="form-label">Email Address</label>
                                            <InputGroup className=" d-flex align-items-center icon">
                                                <span className="p-2 email-icon ">
                                                    <img src={email} />
                                                </span>
                                                <Form.Control
                                                    type="email"
                                                    name="Email"
                                                    id="form2Example1"
                                                    placeholder="Email Address"
                                                    className="border-0 email-input from-input"
                                                    style={{ borderRadius: "5px" }}
                                                    onChange={inputChangeHandler}
                                                    defaultValue={inputs.Email}
                                                />
                                            </InputGroup>
                                            {error.emailErr && <p style={errorStyle}>{error.emailErr}</p>}
                                        </Form.Group>
                                        <Form.Group className="mb-1" controlId="formPassword">
                                            <label className="form-label">Password</label>
                                            <InputGroup className=" d-flex align-items-center icon ">
                                                <span className="p-2 email-icon ">
                                                    <img src={password} />
                                                </span>
                                                <Form.Control
                                                    type={passwordShown ? 'text' : 'password'}
                                                    name="Password"
                                                    placeholder="Password"
                                                    className="input-field password-field border-0 email-input"
                                                    style={{ borderRadius: "5px" }}
                                                    onChange={inputChangeHandler}
                                                    defaultValue={inputs.Password}
                                                />
                                                <span className="p-2 eye-icon ">
                                                    <img src={eye} />
                                                </span>
                                            </InputGroup>
                                            {error.password && <p style={errorStyle}>{error.password}</p>}
                                            {error.allError && <p style={errorStyle}>{error.allError}</p>}
                                        </Form.Group>
                                        <Form.Group>
                                            <div className="keep-signedin mb-4">
                                                <div class="form-check">
                                                    <input class="form-check-input check" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        Keep me signed in
                                                    </label>
                                                </div>
                                                <div>
                                                    Forgot password?
                                                </div>
                                            </div>
                                        </Form.Group>

                                        <Button className="sign-in" size="lg" type="submit" onClick={handleSubmit}>
                                            Sign In
                                        </Button>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            {/* </Container> */}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                icon={false}
            />
        </Fragment>
    )
}

export default SuperAdminLogin;

