import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import "./Employe.scss";
// import GoogleIcon from "../../assets/google-icon.svg";
// import EmailIcon from "../../assets/mail-icon.svg";
// import EyeIcon from "../../assets/eye-icon.svg";
const EmployeProfile = () => {
  const [selectedImage, setSelectedImage] = useState();

  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };
  return (
    <React.Fragment>
      <header>
        <NavigationBar />
      </header>
      <div className="auth-wrraper">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} xl={7}>
              <Card className="mt-5 p-3">
                <Card.Body className="main-card py-0">
                  <div className="header-content">
                    <h4 className="my-4">Create Profile As Employee</h4>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#9A9A9A",
                        }}
                      />

                      <div>
                        <p className="line-style">OR</p>
                      </div>

                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#9A9A9A",
                        }}
                      />
                    </div>
                    <Form>
                      <Form.Group className="mb-3">
                        <label className="filelabel">
                          <span className="title">
                            Upload Your Photo (.png, .jpg/.jpeg)
                          </span>
                          <input
                            className="FileUpload1"
                            id="FileInput"
                            name="photoUpload"
                            type="file"
                            accept=".png, .jpg/.jpeg"
                            onChange={imageChange}
                          />
                        </label>
                        {selectedImage && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              minHeight: "90px",
                              width: "90px",
                              position: "relative",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(selectedImage)}
                              style={{
                                marginTop: 20,
                                display: "flex",
                                flexDirection: "column",
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                              alt="Thumb"
                            />
                            <button
                              className="cross-icon"
                              onClick={removeSelectedImage}
                            >
                              X
                            </button>
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formFullname">
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          className="input-field"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formLastname">
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          className="input-field"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formUserComp">
                        <Form.Select className="input-field selectpicker form-control">
                          <option>Education Background</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formRate">
                        <Form.Control
                          type="text"
                          placeholder="Enter Your Hourly Rate"
                          className="input-field"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formHour">
                        <Form.Control
                          type="text"
                          placeholder="Enter Your Working Hours"
                          className="input-field"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <label className="filelabel">
                          <span className="title">
                            Upload Your Resume (.Pdf, .Doc)
                          </span>
                          <input
                            className="FileUpload1"
                            id="FileInputOne"
                            name="photoUpload"
                            type="file"
                            accept=".pdf, .doc"
                            onChange={imageChange}
                          />
                        </label>
                        {selectedImage && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              minHeight: "90px",
                              width: "90px",
                              position: "relative",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(selectedImage)}
                              style={{
                                marginTop: 20,
                                display: "flex",
                                flexDirection: "column",
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                              alt="Thumb"
                            />
                            <button
                              className="cross-icon"
                              onClick={removeSelectedImage}
                            >
                              X
                            </button>
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <label className="filelabel">
                          <span className="title">
                            Upload Certificate If Any (.Pdf, .Doc)
                          </span>
                          <input
                            className="FileUpload1"
                            id="FileInputTwo"
                            name="photoUpload"
                            type="file"
                            accept=".pdf, .doc"
                            onChange={imageChange}
                          />
                        </label>
                        {selectedImage && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              minHeight: "90px",
                              width: "90px",
                              position: "relative",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(selectedImage)}
                              style={{
                                marginTop: 20,
                                display: "flex",
                                flexDirection: "column",
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                              alt="Thumb"
                            />
                            <button
                              className="cross-icon"
                              onClick={removeSelectedImage}
                            >
                              X
                            </button>
                          </div>
                        )}
                      </Form.Group>
                      <Button
                        className="submit-btn my-2"
                        size="lg"
                        type="submit"
                      >
                        Create Resume
                      </Button>
                      <Button
                        className="submit-btn my-2"
                        size="lg"
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        className="submit-btn-cancel my-2"
                        size="lg"
                        type="submit"
                      >
                        Cancel
                      </Button>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmployeProfile;
