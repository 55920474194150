import {applyMiddleware, createStore} from "redux";
// import RootReducer from './reducer/RootReducer';
import { persistStore,persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducer/RootReducer";
// import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = { 
    key: 'root',
    storage:storage 
}
const persistedReducer = persistReducer(persistConfig, rootReducer) 

const store = createStore(
    persistedReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
    
)


const  persistor = persistStore(store); 

export {store, persistor}
