import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'


function FileDropzone(props) {
  const [preview,setPreview] = useState(null);
  const [fileName,setFileName]=useState(null);

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    props.uploadedFile(acceptedFiles[0])   

    if(acceptedFiles[0].type==='application/pdf'){
      const previewVal = window.URL.createObjectURL(acceptedFiles[0])
      setPreview(previewVal);
      setFileName(acceptedFiles[0].name);
    }
    else{
      alert("file is not acceptable")
    }
   
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  return (
 
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p></p> :
          <p>
            {preview !== null ? fileName: "Upload Your Resume (.png, .doc)"}
          </p>
      }
    </div>
  )
}

export default FileDropzone;