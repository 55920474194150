import React, { Fragment, useEffect, useState } from "react";
import "../../pages/Profile/Profile.scss";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import Profile1 from "../../assets/Profile1.svg";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Footer from "../../layouts/Footer/Footer";
import PersonalInformtion from "../../component/EmployeeProfileView/PersonalInformation";
import ProfessionalInformation from "../../component/EmployeeProfileView/ProfessionalInformation";
import EducatoinalInformation from "../../component/EmployeeProfileView/EducatoinalInformation";
import { getAllResource } from "../../services/CompanyAPI";
import { useLocation } from "react-router-dom";
import data from "../HireResources/data";
import { useSelector } from "react-redux";
import APIurl from "../../services/APIurl";
import Designer1 from "../../assets/Designer1.png";
const Profile = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [skillData, setSkillData] = useState();
  const state = useSelector((state) => state?.Company?.get_resource_id);

  const HiringResources = [
    "Testing and QA",
    "Analytics",
    "Creative Service",
    "Process Outsourcing",
    "Cloud Info Management",
    "Business Analyst",
    "UI Designer",
    "UX Designer",
    "PHP Developer",
    "Cloud Engineer",
  ];

  useEffect(() => {
    console.log("state",state);
    getAllResource(state)
      .then((result) => {
        console.log("result?.data?.work",result);
        setData(result?.data[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
      
  }, [state]);
  // console.log("data",data?.currency?.currency)
  // console.log("data",data?.work[data?.work?.length-1]?.jobPosition)
// console.log("work",data?.work)

  useEffect(() => {
    setSkillData(data?.skills?.join(",").split(","));
   
  }, [data]);

  return (
    <Fragment>
      <NavigationBar />
      <div className="container2">
        <div className="reso row mt-3">
          <div className="col-md-6   col-12">
            <div className="row p-5">
              <img
                src={
                  data?.uploadPhoto
                    ? `${APIurl.backendUrl}/${data?.uploadPhoto}`
                    : Designer1
                }
                // src={`${APIurl.backendUrl}/${data?.uploadPhoto}`}
                className="img-fluid"
                alt=""
                style={{height:"400px",width:"400px"}}
              />
            </div>
          </div>
          <div className="col-md-5 col-12 why-skipple">
            <div className="about-profile mt-5">
              <div className="profile-info">
                <div>
                  <h3>{data?.fullName}</h3>
                  <p>
                    {/* {data?.work[data?.work?.length-1]?.jobPosition}{" "} */}
                    {data?.work?.length>0 && data?.work[data?.work?.length-1]?.jobPosition}
                    <Stack spacing={1}>
                      <Rating name="size-medium" defaultValue={2} />
                    </Stack>
                  </p>
                  <div className="profile-badges">
                    {/* <div className="badge">
                      <span>Adobe XD</span>
                    </div>
                    <div className="badge">
                      <span>UX Design</span>
                    </div>
                    <div className="badge">
                      <span>Web Design</span>
                    </div>
                    <div className="badge">
                      <span>Figma</span>
                    </div>
                    <div className="badge">
                      <span>Photoshop</span>
                    </div> */}
                    {skillData?.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="badge">
                            <span>{item}</span>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <h6 className="profile-time mt-1">{data?.currency?.currency} {data?.hourlyRate}/Hour</h6>
                </div>
              </div>
              <p className="profile-btn mt-5">Invite For Interview</p>
              {/* <button className="mt-5" type="submit">
                Invite for interview
              </button> */}
            </div>
          </div>
        </div>
        <div className="row mt-3 p-5">
          <div className="profile-header-subtext mb-5">
            <h6 className="header-subtitle1">
              <span>Profile Information</span>
            </h6>
            <div className="header-title1">
              <h3>View Profile Infromation Before Hiring</h3>
            </div>
          </div>
          {/* <div className="col-md-5 col-12">
            <div class="overflow-auto">
              {HiringResources.map((resources, idx) => (
                <di v className=" resources" key={idx}>
                  {resources}
                </di>
              ))}
            </div>
          </div> */}
          {/* <div className="col-md-7 col-12  mt-1">
            <div className="profile-about-skipple p-5">
              <h3>Personal Information</h3>
              <p>Date of Birth: 13-09-1996</p>
              <p>Gender: Female</p>
              <p>Martial Status: Single</p>
              <p>About Raven:</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis
                metus, pulvinar ac tempus et quisque consectetur. Sed vitae,
                enim, in tellus sed. Elementum non cum eget massa.
              </p>
            </div>
          </div> */}
          <div class="d-flex align-items-start personal-content">
            <div
              class="nav flex-column nav-pills col-md-3 col-12 me-3 mb-5"
              id="side-column2"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                class="nav-link active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                Personal Information
              </button>
              <button
                class="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                Professional Information
              </button>
              <button
                class="nav-link"
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                Educational Information
              </button>
            </div>
            <div class="tab-content col-md-9 col-12 " id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                {" "}
                <PersonalInformtion />
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                {" "}
                <ProfessionalInformation />
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                {" "}
                <EducatoinalInformation />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container-fluid footer" id="footer-section">
        <Footer />
      </section>
    </Fragment>
  );
};

export default Profile;
