export const Company_Details = (data)=>{

    return {
        type:"Company_Details",
        payload:data
    }
}
export const get_resource_id = (data)=>{

    return {
        type:"get_resource_id",
        payload:data
    }
}