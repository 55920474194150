import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import "./Auth.scss";
import GoogleIcon from "../../assets/google-icon.svg";
import EmailIcon from "../../assets/mail-icon.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import EyeIcon from "../../assets/eye-icon.svg";
import { MicroServicePostRequestAPI } from "../../services/ApiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserType } from "../../redux/actions/authAction";
import {useDispatch} from "react-redux"

// import MetaTags from "react-meta-tags";
const SignUp = () => {
  const [inputs, setInputs] = useState({
    FullName: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    CompanyName: "",
    UserType: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [companyNameVisible, setCompanyNameVisible] = useState(false);
  const [disableCheckBox, setDisableCheckBox] = useState(true);
  const dispatch=useDispatch();

  const [error, setError] = useState({
    nameErr: "",
    password: "",
    confirmPassErr: "",
    emailErr: "",
    companyNameErr: "",
    allError: "",
  });

  const navigate = useNavigate();
  const loginHandler = (e) => {
    e.preventDefault();
    navigate("/new-login");
  };

  const errorStyle = {
    fontSize: 12,
    color: "red",
  };
  const dataSubmit = () => {
    return checked ? setIsDisabled(true) : setIsDisabled(false);
  };

  const onCheckboxClick = () => {
    setChecked(!checked);
    return dataSubmit();
  };
  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    let nameRegex = /^[a-zA-Z\s]*$/;
    if (name === "FullName") {
      if (!nameRegex.test(value)) {
        setError({ ...error, nameErr: "Please Enter Valid Name" });
      } else {
        setError({ ...error, nameErr: null });
      }
    }
    if (name === "Password") {
      if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        )
      ) {
        setError({
          ...error,
          password:
            "Password must be 8 or more characters in length && Use upper, lower, digit and special character",
        });
      } else {
        setError({ ...error, password: null });
      }
    }
    if (name === "ConfirmPassword") {
      if (value !== inputs.Password) {
        setError({ ...error, confirmPassErr: "Password does not match" });
      } else {
        setError({ ...error, confirmPassErr: null });
      }
    }
    if (name === "Email") {
      if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
        setError({ ...error, emailErr: "Please Enter Valid Email" });
      } else {
        setError({ ...error, emailErr: null });
      }
    }

    // if (name == "UserType") {
    //   if (value === "Vendor") {
    //     if (inputs.CompanyName.length < 0) {
    //       setError({ ...error, companyNameErr: "Please Enter Company Name" });
    //     } else {
    //       setError({ ...error, companyNameErr: null });
    //     }
    //   } else {
    //     setError({ ...error, companyNameErr: null });
    //   }
    // }
  };
  useEffect(() => {
    if (error?.allError !== null && error?.allError !== "") {
      if (
        inputs.FullName === "" ||
        inputs.Email === "" ||
        inputs.Password === "" ||
        inputs.UserType === ""
      ) {
        setError({ ...error, allError: "All Field Required" });
      } else {
        setError({ ...error, allError: null });
      }
    }
    if (
      error.nameErr === null &&
      error.password === null &&
      error.emailErr === null &&
      error.confirmPassErr === null &&
      inputs.UserType !== ""
    ) {
      if (inputs.UserType === "Vendor" && inputs.CompanyName === "") {
        setDisableCheckBox(true);
      } else {
        setDisableCheckBox(false);
      }
    } else {
      setDisableCheckBox(true);
    }
  }, [inputs]);
  const registrationHandler = (e) => {
    e.preventDefault();

    // let data = inputs.FullName.split(" ");

    let dataInputs = {
      // first_name: data[0],
      // last_name: data[1],
      fullName: inputs.FullName,
      email: inputs.Email,
      password: inputs.Password,
      userType: inputs.UserType,
      companyName: inputs.CompanyName,
    };
    if (
      inputs.FullName === "" ||
      inputs.Email === "" ||
      inputs.Password === "" ||
      inputs.ConfirmPassword === "" ||
      inputs.UserType === ""
    ) {
      setError({ ...error, allError: "All Field Required" });
    } else {
      setError({ ...error, allError: null });
      if (
        error.nameErr === null &&
        error.password === null &&
        error.emailErr === null
      ) {
        setBtnLoading(true);
        MicroServicePostRequestAPI("api/vendor/register", "post", dataInputs)
          .then((result) => {
            if (result) {
              setBtnLoading(false);
              // toast.success(
              //   "your profile is under verification.you can login after  approval"
              // );
              localStorage.setItem("id", result.data.userType);
              toast.success("Registration Successfull");
              dispatch(getUserType(""))
              setTimeout(() => {
                window.location.replace("new-login");
              }, 2000);
            }
          })
          .catch((error) => {
            setBtnLoading(false);
            if (error.data.auth === false) {
              toast.error(error.data.message);
            }
          });
      }
    }
  };

  return (
    <React.Fragment>
      {/* <header>
        <NavigationBar />
      </header> */}
      <div className="auth-wrraper">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} xl={7}>
              <Card className="mt-5 mb-5 yp-3">
                <Card.Body className="main-card py-0">
                  <div className="header-content">
                    <h4 className="mt-3">I am Here to Register As Corporate</h4>
                    <div className="d-flex align-items-center justify-content-center my-3">
                      <button className="google-btn">
                        <img src={GoogleIcon} /> Continue with Google
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "3px",
                          backgroundColor: "#10375C",
                        }}
                      />

                      <div>
                        <p className="or-text">OR</p>
                      </div>

                      <div
                        style={{
                          flex: 1,
                          height: "3px",
                          backgroundColor: "#10375C",
                        }}
                      />
                    </div>
                    <Form>
                      <Form.Group className="mb-3" controlId="formFullname">
                        <Form.Control
                          type="text"
                          name="FullName"
                          placeholder="Full Name"
                          className="input-field"
                          defaultValue={inputs.FullName}
                          onChange={inputChangeHandler}
                        />
                        {error.nameErr && (
                          <p style={errorStyle}>{error.nameErr}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <InputGroup className=" d-flex align-items-center">
                          <Form.Control
                            type="email"
                            name="Email"
                            placeholder="Email Address"
                            className="input-field email-field border-0"
                            defaultValue={inputs.Email}
                            onChange={inputChangeHandler}
                          />
                          <span className="p-2">
                            <img src={EmailIcon} />
                          </span>
                        </InputGroup>
                        {error.emailErr && (
                          <p style={errorStyle}>{error.emailErr}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formPassword">
                        <InputGroup className=" d-flex align-items-center">
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            name="Password"
                            placeholder="Password"
                            className="input-field password-field border-0"
                            defaultValue={inputs.Password}
                            onChange={inputChangeHandler}
                          />
                          {/* <span className="p-2">
                            <img src={EyeIcon} onClick={() => setPasswordShown(!passwordShown)} />
                          </span> */}
                          {passwordShown ? (
                            <FaEye
                              className="trailIcon"
                              size={25}
                              style={{ color: "#9A9A9A", margin: "5px" }}
                              onClick={() => setPasswordShown((pass) => !pass)}
                            />
                          ) : (
                            <FaEyeSlash
                              className="trailIcon"
                              size={25}
                              style={{ color: "#9A9A9A", margin: "5px" }}
                              onClick={() => setPasswordShown((pass) => !pass)}
                            />
                          )}
                        </InputGroup>
                        {error.password && (
                          <p style={errorStyle}>{error.password}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formPassword">
                        <InputGroup className=" d-flex align-items-center">
                          <Form.Control
                            type={confirmPasswordShown ? "text" : "password"}
                            name="ConfirmPassword"
                            placeholder="Confirm Password"
                            className="input-field password-field border-0"
                            defaultValue={inputs.ConfirmPassword}
                            onChange={inputChangeHandler}
                          />
                          {/* <span className="p-2">
                            <img src={EyeIcon} onClick={() => setPasswordShown(!passwordShown)} />
                          </span> */}
                          {confirmPasswordShown ? (
                            <FaEye
                              className="trailIcon"
                              size={25}
                              style={{ color: "#9A9A9A", margin: "5px" }}
                              onClick={() =>
                                setConfirmPasswordShown((pass) => !pass)
                              }
                            />
                          ) : (
                            <FaEyeSlash
                              className="trailIcon"
                              size={25}
                              style={{ color: "#9A9A9A", margin: "5px" }}
                              onClick={() =>
                                setConfirmPasswordShown((pass) => !pass)
                              }
                            />
                          )}
                        </InputGroup>
                        {error.confirmPassErr && (
                          <p style={errorStyle}>{error.confirmPassErr}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formUserComp">
                        <Form.Select
                          className="input-field selectpicker form-control"
                          name="UserType"
                          onChange={inputChangeHandler}
                        >
                          <option value="" disabled selected>
                            User Type
                          </option>
                          <option value="Resource">Resource</option>
                          <option value="Vendor">Vendor</option>
                        </Form.Select>
                      </Form.Group>
                      {inputs.UserType === "Vendor" && (
                        <Form.Group className="mb-3" controlId="formcompany">
                          <Form.Control
                            type="text"
                            name="CompanyName"
                            placeholder="Company Name"
                            className="input-field"
                            defaultValue={inputs.CompanyName}
                            onChange={inputChangeHandler}
                          />
                          {error.companyNameErr && (
                            <p style={errorStyle}>{error.companyNameErr}</p>
                          )}
                        </Form.Group>
                      )}
                      {error.allError && (
                        <p style={errorStyle}>{error.allError}</p>
                      )}
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <div class="mb-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            disabled={disableCheckBox}
                            onClick={onCheckboxClick}
                          />
                          <label
                            className="form-check-label"
                            for="exampleCheck1"
                          >
                            Yes I Understand and{" "}
                            <span className="orange">Agree to Terms</span> and
                            Use of Skiple Including{" "}
                            <span className="orange">Privacy Policy.</span>
                          </label>
                        </div>
                      </Form.Group>
                      {btnLoading ? (
                        <Button className="submit-btn" size="lg" type="submit">
                          <span className="spinner-border spinner-border-sm"></span>
                        </Button>
                      ) : (
                        <Button
                          className="submit-btn"
                          size="lg"
                          type="submit"
                          onClick={registrationHandler}
                          disabled={checked===true?false:true}
                        >
                          Create Account
                        </Button>
                      )}
                    </Form>
                    <span className="form-check-label-dark d-flex justify-content-center my-3">
                      Already Have an Account ?{" "}
                      <span className="orange-dark ms-2" onClick={loginHandler}>
                        {" "}
                        Login Now{" "}
                      </span>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={false}
      />
    </React.Fragment>
  );
};

export default SignUp;
