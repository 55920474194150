import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getAllResource } from "../../services/CompanyAPI";
const ProfessionalInformation = () => {
  const [data, setData] = useState([]);
  const state = useSelector((state) => state?.Company?.get_resource_id);

  useEffect(() => {
    getAllResource(state)
      .then((result) => {
        setData(result?.data[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [state]);
  return (
    <div className="profile-about-skipple">
      <h3>Professional Information</h3>
      {/* <p>Job Position: 13-09-1996</p>
              <p>Company Name: Female</p>
              <p>Work Experience : Single</p>
              <p>Description of  Your Work:</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis
                metus, pulvinar ac tempus et quisque consectetur. Sed vitae,
                enim, in tellus sed. Elementum non cum eget massa.
              </p> */}
      {data?.experience == "Fresher" ? <Row className="d-flex">
                    <Col sm={3} md={3} xs={6} className="mb-3">
                      <div className="fild-heading-text-content">
                        <div>Area Of Interest</div>
                        <div>Area of Interest Description</div>
                      </div>
                    </Col>
                    <Col sm={6} md={6} xs={6}>
                      <div className="fild-text-content">
                        <div>{data?.interestArea}</div>
                        <div>{data?.descriptionOfInterest}</div>
                      </div>
                    </Col>
                    
                  </Row> : data?.work?.map((item, index) => {
        return (
          <>
            <Row className="d-flex">
              <Col sm={4} md={4} xs={6} className="mb-3">
                <div className="fild-heading-text-content">
                  <div>Job Position:</div>
                  <div>Company Name:</div>
                  <div>Work Experience :</div>
                  <div>Description of Your Work:</div>
                </div>
              </Col>
              <Col sm={8} md={8} xs={6}>
                <div className="fild-text-content">
                  <div>{item.jobPosition}</div>
                  <div>{item.companyName}</div>
                  <div>{item.workExperience}</div>
                  <div>
                  {item.workDescription}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        );
      })
    }
    </div>
  );
};
export default ProfessionalInformation;
