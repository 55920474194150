import { Fragment } from "react";
import {
    Row,
    Col,
    Card,
    Alert,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Button,
} from "react-bootstrap";
import './Superadminresetpassword.scss'
import password from '../../assets/password.svg'
// import email from '../../assets/email.svg'
// import password from '../../assets/password.svg'
import eye from '../../assets/opne-eyeicon.svg'


const Superadminresetpassword = () => {
    return (
        <Fragment>
            <Container>
                <Row className="justify-content-center back">
                    <Col xs={12} md={10} lg={8} xl={7}>
                        <Card className="mt-5 p-3 card-back">
                            <Card.Body className="main-card py-0 ">
                                <div className="header-content">
                                    <h4 className="mt-3 skiple-text">Skiple</h4>
                                    <h5 className="login-to-Skiple-text">Reset Password</h5>
                                    <Form className="mt-4">
                                        <Form.Group className="mb-4 " controlId="formBasicEmail">
                                            <label className="form-label">Password</label>
                                            <InputGroup className=" d-flex align-items-center icon">
                                                <span className="p-2 email-icon ">
                                                    <img src={password} />
                                                </span>
                                                <Form.Control
                                                    type="password"
                                                    id="form2Example1"
                                                    placeholder="Password"
                                                    className="border-0 email-input from-input"
                                                    style={{ borderRadius: "5px" }}
                                                />
                                                <span className="p-2 eye-icon ">
                                                    <img src={eye} />
                                                </span>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formPassword">
                                            <label className="form-label">Confirm Password</label>
                                            <InputGroup className=" d-flex align-items-center icon ">
                                                <span className="p-2 email-icon ">
                                                    <img src={password} />
                                                </span>
                                                <Form.Control
                                                    type="password"
                                                    name="Password"
                                                    placeholder="Confirm Password"
                                                    className="input-field password-field border-0 email-input"
                                                    style={{ borderRadius: "5px"}}
                                                />
                                                <span className="p-2 eye-icon ">
                                                    <img src={eye} />
                                                </span>
                                            </InputGroup>
                                        </Form.Group>

                                        <Button className="sign-in" size="lg" type="submit">
                                        Reset Password
                                        </Button>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Superadminresetpassword;

