import axios from "axios";
import APIurl from "./APIurl";


const axiosInstance = axios.create({
  baseURL: `${APIurl.backendUrl}/`,
});


export function MicroServicePostRequestAPI(url, method, paramsData) {
  let config = {
    method: method,
    url,
    headers: headers,
    data: paramsData,
  };

  return new Promise(function (resolve, reject) {
    return axiosInstance(config)
      .then((response) => {
        console.log('result', response)
        if (response.status == 200) {
          console.log("resolve State", response);
          resolve(response.data);
          return response.data;
        } else {
          console.log("reject State", response);
          reject(response);
          return response;
        }
      })
      .catch((error) => {
        console.log(error.response);
        reject(error.response);
      });
  });
}

export const headers = { "Content-Type": "application/json" }
