import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getAllResource } from "../../services/CompanyAPI";

const PersonalInformtion =()=>
{
  const [data, setData] = useState([]);
  const state = useSelector((state) => state?.Company?.get_resource_id);

  useEffect(() => {
    
    getAllResource(state)
    .then((result) => {
        setData(result?.data[0]);
        console.log("id",state)
        console.log("result item",result)
      })
      .catch((err) => {
        console.log("error", err);
      });
    

  }, [state]);

  console.log("data item",data)
    return(
            <div className="profile-about-skipple">
              <h3>Personal Information</h3>              
              <Row className="d-flex">
                    <Col sm={4} md={4} xs={6} className="mb-3">
                      <div className="fild-heading-text-content">
                        <div>Date of Birth:</div>
                        <div>Gender:</div>
                        <div>Martial Status:</div>
                        <div>About:</div>
                      </div>
                    </Col>
                    <Col sm={8} md={8} xs={6}>
                      <div className="fild-text-content">
                        <div>{data?.birthDate}</div>
                        <div>{data?.gender}</div>
                        <div>{data?.maritalStatus}</div>
                        <div>{data?.aboutMe}</div>
                      </div>
                    </Col>
                  </Row>
            </div>
    )
}
export default PersonalInformtion;
