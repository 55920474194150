import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import { MainComponent } from "./layouts/MainComponent/MainComponent";
import { Fragment } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import HireResources from "./pages/HireResources/HireResources";
import Profile from "./pages/Profile/Profile";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import NewSignUp from "./pages/Auth/NewSignUp";
import NewLogIn from "./pages/Auth/NewLogIn";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import EmployeProfile from "./pages/Employe/EmployeProfile";
import Superadminforgotpassword from "./pages/Superadmin-forgotpassword/Superadminforgotpassword";
import SuperAdminLogin from "./pages/SuperAdminLogin/SuperAdminLogin";
import Superadminresetpassword from "./pages/Superadminresetpassword/Superadminresetpassword";
import CreateEmployeeProfile from "./pages/CreateEmployeeProfile/CreateEmployeeProfile";
import CreateEmployeeProfile2 from "./pages/CreateEmployeeProfile-page2/CreateEmployeeProfile2";
import EmployeeManualProfile from "./pages/EmployeeManualProfile/EmployeeManualProfile";
import UploadResume from "./pages/UploadResume/UploadResume";
import Resumepreview from "./pages/ResumePreview/ResumePreview";
import EmployeeManualProfile2 from "./pages/EmployeeManualProfile2/EmployeeManualProfile2";
import ContactInfo from "./component/CreateEmployeeProperty/ContactInfo/ContactInfo";
import CreatedSuccesfullly from "./component/CreateEmployeeProperty/CreatedSuccesfullly/CreatedSuccesfullly";

function App() {
  function ProtectedRoutes({ children }) {
    const auth = localStorage.getItem("token");
    return auth ? children : <Navigate to="/" />;
  }
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<MainComponent />}></Route>
          <Route exact path="/new-signup" element={<NewSignUp />}></Route>
          <Route exact path="/new-login" element={<NewLogIn />}></Route>
          <Route
            exact
            path="/forgotpassword"
            element={<ForgotPassword />}
          ></Route>
          <Route
            exact
            path="/resetpassword"
            element={<ResetPassword />}
          ></Route>

          <Route
            exact
            path="/hireresources"
            element={
              <ProtectedRoutes>
                <HireResources />
              </ProtectedRoutes>
            }
          />

          <Route exact path="/profile" element={<Profile />}></Route>

          <Route exact path="/aboutus" element={<AboutUs />}></Route>
          <Route exact path="/contactus" element={<ContactUs />}></Route>

          <Route
            exact
            path="/employe-profile"
            element={<EmployeProfile />}
          ></Route>
          <Route
            exact
            path="/superadmin-login"
            element={<SuperAdminLogin />}
          ></Route>
          <Route
            exact
            path="/superadmin-forgotpassword"
            element={<Superadminforgotpassword />}
          ></Route>
          <Route
            exact
            path="/superadmin-resetpassword"
            element={<Superadminresetpassword />}
          ></Route>
          <Route
            exact
            path="/create-employeeprofile"
            element={<CreateEmployeeProfile />}
          ></Route>
          <Route
            exact
            path="/create-employeeprofile-page2"
            element={<CreateEmployeeProfile2 />}
          ></Route>
          <Route
            exact
            path="/employee-manual-profile"
            element={<EmployeeManualProfile />}
          ></Route>
          <Route exact path="/upload-resume" element={<UploadResume />}></Route>
          <Route
            exact
            path="/Resume-Preview"
            element={<Resumepreview />}
          ></Route>
          <Route
            exact
            path="/employee-manual-profile-page2"
            element={<EmployeeManualProfile2 />}
          ></Route>
          <Route
            exact
            path="/employee-manual-profile-contact-information"
            element={<ContactInfo/>}
          ></Route>
          <Route
            exact
            path="/success-page"
            element={<CreatedSuccesfullly/>}
          ></Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
