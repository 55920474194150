import { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
  Dropdown,
} from "react-bootstrap";

import "./EmployeeManualProfile.scss";
import photoupload from "../../assets/photoupload.svg";
import Download from "../../assets/Download.svg";
import Edit from "../../assets/Edit.svg";
import save from "../../assets/save.svg";
import CustomInput from "../../component/CustomInput/CustomInput";
import Dropdowns from "../../component/Dropdowns/Dropdowns";
import { MicroServicePostRequestAPI } from "../../services/ApiService";

const EmployeeManualProfile = () => {
  const genderOptions = ["Male", "Female", "Other"];
  const [personalInfo, setPersonalInfo] = useState({
    full_name: "",
    birth_date: "",
    gender: "",
    language: "",
    marital_status: "",
    about_me: "",
  });
  const [contactInfo, setContactInfo] = useState({
    email: "",
    alternate_email: "",
    address: "",
    phone: "",
    alternate_phone: "",
  });
  const [professionalInfo, setProfessionalInfo] = useState({
    designation: "",
    job_title: "",
    linkedin: "",
    department: "",
    sub_department: "",
    portfolio: "",
  });
  const [educationalInfo, setEducationalInfo] = useState({
    qualification: "",
    course_name: "",
    course_type: "",
    college: "",
    start_year: "",
    end_year: "",
    university: "",
  });
  const [skillInfo, setSkillInfo] = useState();
  const [dropdownData, setDropDownData] = useState();
  const personalInfoinputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPersonalInfo((values) => ({ ...values, [name]: value }));
  };
  const contactInfoInputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setContactInfo((values) => ({ ...values, [name]: value }));
  };
  const professionalInfoInputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setProfessionalInfo((values) => ({ ...values, [name]: value }));
  };
  const educationalInfoInputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setEducationalInfo((values) => ({ ...values, [name]: value }));
  };
  const personalInfoDropDownHandler = (val) => {

    setPersonalInfo((values) => ({ ...values, [val.name]: val.value }));
  };
  const educationalInfoDropDownHandler = (event) => {
    const name = event.name;
    const value = event.value;
    setEducationalInfo((values) => ({ ...values, [name]: value }));
  };
  const skillInfoInputChangeHandler = (event) =>{
    const name = event.target.name;
    const value = event.target.value;
    setSkillInfo((values) => ({ ...values, [name]: value }));
  }

const submitHandler = (e) =>{
e.preventDefault();
    let id=localStorage.getItem('id');
    let skills=skillInfo.skill.split(',');
    let finalObject={...personalInfo,...contactInfo,...professionalInfo,...educationalInfo,skills}

    MicroServicePostRequestAPI(`api/users/updateResume/${id}`, 'put', finalObject).then((result) => {
        if (result) {
         console.log("result",result)
        }
      }).catch((error) => {
        console.log("errror",error)
      })

}

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 side mt-3">
            <div className="photo mb-3">
              <label htmlFor="profile">
                <img src={photoupload} className="mb-4" />
              </label>
              <input type="file" id="profile" />
              <p>Change Profile Image</p>
            </div>
            <Button className=" btn PreviewResume mb-3 " size="lg" onClick={submitHandler}>
              Preview Resume
            </Button>
            <Button className=" btn PreviewResume mb-3 " size="lg">
              <img src={Download} />
            </Button>
          </div>
          <div className="col-md-9">
            <div className="list">
              <div className="Information mb-3">
                <Row className="mb-3">
                  <Col className="text" sm={10} md={10}>
                    <div>Personal Information</div>
                  </Col>
                  <Col sm={1} md={1}>
                    <div>
                      <button className="">
                        <img src={Edit} />
                      </button>
                    </div>
                  </Col>
                  <Col sm={1} md={1}>
                    <div>
                      <button className="">
                        <img src={save} />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3 list-text">
                  <Col sm={2} md={2}>
                    <div className="text">Name</div>
                    <div className="w-100">
                      <CustomInput
                        name="full_name"
                        type="text"
                        value={personalInfo.full_name}
                        onChange={personalInfoinputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={2} md={2}>
                    <div className="text">Date of Birth</div>
                    <div>
                      <input
                        type="date"
                        name="birth_date"
                        value={personalInfo.birth_date}
                        onChange={personalInfoinputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={2} md={2}>
                    <div className="text">Gender</div>
                    <div>
                      <Dropdowns
                        name="gender"
                        options={["Male", "Female", "Other"]}
                        value={personalInfo.gender}
                        state={personalInfo}
                        onChange={(val)=> personalInfoDropDownHandler(val)}
                      />
                      {/* <Dropdown
                      defaultValue={personalInfo.gender}
                        onSelect={(e) => {
                          console.log("eventdata", e);
                          personalInfoDropDownHandler(e);
                        }}
                        // selected={personalInfo.gender}
                        
                      >
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          variant=""
                        >
                          select
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="light">
                          {genderOptions.map((genderOptions, i) => (
                            <Dropdown.Item key={i} eventKey={genderOptions}>
                              {genderOptions}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </Col>
                  <Col sm={2} md={2}>
                    <div className="text">Language</div>
                    <div className="w-100">
                      <CustomInput
                        name="language"
                        type="text"
                        value={personalInfo.language}
                        onChange={personalInfoinputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="list-text">
                  <Col sm={2} md={2}>
                    <div className="text">Martial Status</div>
                    <div>
                      <Dropdowns
                        name="marital_status"
                        options={["Single", "Married "]}
                        value={personalInfo.marital_status}
                        onChange={personalInfoDropDownHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={8} md={8}>
                    <div className="text">About Me</div>
                    <div className="w-100">
                      <CustomInput
                        name="about_me"
                        type="text"
                        value={personalInfo.about_me}
                        onChange={personalInfoinputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="Information mb-3">
                <Row className="mb-3">
                <Col className="text" sm={12} md={12}>Contact Information<div className="kk"><button><img src={Edit} /></button><button className="mx-5"><img src={save}/></button></div></Col> 
                </Row>
                <Row className="mb-3 list-text">
                  <Col sm={3} md={3}>
                    <div className="text">Email ID</div>
                    <div className="w-100">
                      <CustomInput
                        name="email"
                        type="email"
                        value={contactInfo.email}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">Alternate Email ID</div>
                    <div className="w-100">
                      <CustomInput
                        name="alternate_email"
                        type="email"
                        value={contactInfo.alternate_email}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">Address</div>
                    <div className="w-100">
                      <CustomInput
                        name="address"
                        type="text"
                        value={contactInfo.address}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="list-text">
                  <Col sm={3} md={3}>
                    <div className="text">Phone Number</div>
                    <div className="w-100">
                      <CustomInput
                        name="phone"
                        type="text"
                        value={contactInfo.phone}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">Alternate Phone Number</div>
                    <div className="w-100">
                      <CustomInput
                        name="alternate_phone"
                        type="text"
                        value={contactInfo.alternate_phone}
                        onChange={contactInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="Information mb-3">
                <Row className="mb-3">

                <Col className="text" sm={12} md={12}>Professional Information<div className="kk"><button><img src={Edit} /></button><button className="mx-5"><img src={save}/></button></div></Col> 
                </Row>
                <Row className="mb-3 list-text">
                  <Col sm={3} md={3}>
                    <div className="text">Designation</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="designation"
                        value={professionalInfo.designation}
                        onChange={professionalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">Job Title</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="job_title"
                        value={professionalInfo.job_title}
                        onChange={professionalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">LinkeIn Profile</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="linkedin"
                        value={professionalInfo.linkedin}
                        onChange={professionalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="list-text ">
                  <Col sm={3} md={3}>
                    <div className="text">Department</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="department"
                        value={professionalInfo.department}
                        onChange={professionalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">Sub Department</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="sub_department"
                        value={professionalInfo.sub_department}
                        onChange={professionalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">Portfolio URL</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="portfolio"
                        value={professionalInfo.portfolio}
                        onChange={professionalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="Information mb-3">
                <Row className="mb-3">
                <Col className="text" sm={12} md={12}>Educational Information<div className="kk"><button><img src={Edit} /></button><button className="mx-5"><img src={save}/></button></div></Col> 
                </Row>
                <Row className="mb-3 ">
                  <Col sm={3} md={3}>
                    <div className="text">Qualification Type</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="qualification"
                        value={educationalInfo.qualification}
                        onChange={educationalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">Course Name</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="course_name"
                        value={educationalInfo.course_name}
                        onChange={educationalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">Course Type</div>
                    <div>
                      <Dropdowns
                        options={["Full Time", "Part Time", "Regular"]}
                        name="course_type"
                        value={educationalInfo.course_type}
                        onChange={educationalInfoDropDownHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">College Name</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="college"
                        value={educationalInfo.college}
                        onChange={educationalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3} md={3}>
                    <div className="text">Start Year</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="start_year"
                        value={educationalInfo.start_year}
                        onChange={educationalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">End Year</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="end_year"
                        value={educationalInfo.end_year}
                        onChange={educationalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                  <Col sm={3} md={3}>
                    <div className="text">University Name</div>
                    <div className="w-100">
                      <CustomInput
                        type="text"
                        name="university"
                        value={educationalInfo.university}
                        onChange={educationalInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="Information mb-3">
                <Row className="mb-3">
                <Col className="text" sm={12} md={12}>Your Skills<div className="kk"><button><img src={Edit} /></button><button className="mx-5"><img src={save}/></button></div></Col> 
                </Row>
                <Row>
                  <Col sm={3} md={3}>
                  <div className="w-100">
                      <CustomInput
                        type="text"
                        name="skill"
                        // value={skillInfo}
                        onChange={skillInfoInputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EmployeeManualProfile;
