import {
    Row,
    Col,
    Card,
    Alert,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Button,
} from "react-bootstrap";
import './Superadminforgotpassword.scss'
import { Fragment } from "react";
import email from '../../assets/email.svg'


const Superadminforgotpassword=()=>
{
    return(
        <Fragment>
            <Container>
                <Row className="justify-content-center back">
                    <Col xs={12} md={10} lg={8} xl={7}>
                        <Card className="mt-5 p-3 card-back">
                            <Card.Body className="main-card py-0 ">
                                <div className="header-content">
                                    <h4 className="mt-3 skiple-text">Skiple</h4>
                                    <h5 className="login-to-Skiple-text">Forgot Password</h5>
                                    <Form className="mt-4">
                                        <Form.Group className="mb-3 " controlId="formBasicEmail">
                                            <label className="form-label">Email Address</label>
                                            <InputGroup className=" d-flex align-items-center iocn">
                                                <span className="p-2 email-icon ">
                                                    <img src={email} />
                                                </span>
                                                <Form.Control
                                                    type="email"
                                                    id="form2Example1"
                                                    placeholder="Email Address"
                                                    className="border-0 email-input from-input"
                                                    style={{ borderRadius: "5px" }}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Button className="sign-in" size="lg" type="submit">
                                            Sign In
                                        </Button>
                                    </Form>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default Superadminforgotpassword;