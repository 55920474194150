import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import "./Auth.scss";
import GoogleIcon from "../../assets/google-icon.svg";
import { useNavigate } from "react-router-dom";
import EmailIcon from "../../assets/mail-icon.svg";
import EyeIcon from "../../assets/eye-icon.svg";
import { MicroServicePostRequestAPI } from "../../services/ApiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  contact_info_resume_data,
  EmployeeManualResume,
  loginData,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { loginAPI } from "../../services/AuthAPI";
import { getUserType, login_user_data } from "../../redux/actions/authAction";

const LogIn = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state?.loginUserReducer?.userType
  );
// console.log("state",state)
  const [isChecked, setIsChecked] = useState(false);
  const [flag, setFlag] = useState(false);
  const [inputs, setInputs] = useState({
    UserType: state?state:"",
    Email: "",
    Password: "",
  });
  const [error, setError] = useState({
    password: "",
    emailErr: "",
    allError: "",
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const errorStyle = {
    fontSize: 12,
    color: "red",
  };
  const navigate = useNavigate();
  const signupHandler = (e) => {
    e.preventDefault();
    navigate("/new-signup");
  };
  const forgotpassword = (e) => {
    e.preventDefault();
    navigate("/forgotpassword");
  };
  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    if (name === "Password") {
      if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        )
      ) {
        setError({
          ...error,
          password:
            "Password must be 8 or more characters in length && Use upper, lower, digit and special character",
        });
      } else {
        setError({ ...error, password: null });
      }
    }
    if (name === "Email") {
      if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
        setError({ ...error, emailErr: "Please Enter Valid Email" });
      } else {
        setError({ ...error, emailErr: null });
      }
    }
  
    if(name=="UserType"){
      console.log("inputs usertype",inputs.UserType)
      setFlag(true)
    }
   
  };

  useEffect(()=>{
    dispatch(getUserType(inputs.UserType));
    setFlag(false)
  },[flag])
  // const userTypeHandler = () =>{
  //    dispatch(getUserType(inputs.UserType));
  // }
  console.log("inputs usertype1",inputs.UserType)
  useEffect(() => {
    if (error?.allError !== null && error?.allError !== "") {
      if (inputs.Email === "" || inputs.Password === "") {
        setError({ ...error, allError: "All Field Required" });
      } else {
        setError({ ...error, allError: null });
      }
    }
  }, [inputs]);

  const handleSubmit = (e) => {
    e.preventDefault();
console.log("inputs",inputs)
    if (inputs.Email === "" || inputs.Password === "") {
      setError({ ...error, allError: "All Field Required" });
    } else {
      setError({ ...error, allError: null });

      if (error.password == null && error.emailErr == null) {
        // setError({ allError: '' })
        const inputData = {
          email: inputs.Email,
          password: inputs.Password,
          userType: inputs.UserType,
        };
        if (isChecked && inputs.Email !== "") {
          localStorage.Email = inputs.Email;
          localStorage.UserType = inputs.UserType;
          localStorage.Password = inputs.Password;
          localStorage.checkbox = isChecked;
        }
        console.log("inputData",inputData)
        loginAPI(inputData)
          .then((result) => {
            if (result.data.user) {
              localStorage.setItem("token", result.data.token);
              localStorage.setItem("UserType", result.data.user.userType);
              localStorage.setItem("id", result.data.user._id);
              localStorage.setItem("fullName", result.data.user.fullName);
              toast.success(result.message);
              setTimeout(() => {
                window.location.replace("/create-employeeprofile");
              }, 2000);
              let personalData = {
                fullName: result.data.user.fullName,
                birthDate: null,
                gender: null,
                language: null,
                maritalStatus: null,
                aboutMe: null,
              };
              let contactData = {
                email: result.data.user.email,
                alternateEmail: null,
                phone: null,
                alternatePhone: null,
                address: null,
              };
              console.log("email", result.data.user.email);
              dispatch(EmployeeManualResume(personalData));
              dispatch(contact_info_resume_data(contactData));
            }
            if (result.data.vendor) {
              // alert(result.data.vendor.userType);
              localStorage.setItem("token", result.data.token);
              localStorage.setItem("UserType", result.data.vendor.userType);
              localStorage.setItem("id", result.data.vendor._id);
              toast.success(result.message);
              setTimeout(() => {
                window.location.replace("/hireresources");
              }, 2000);
            }
          })
          .catch((err) => {
            if (err?.data?.success === false) {
              toast.error(err.data.description);
            }
          });
      }
    }
  };
  const onChangeCheckbox = (event) => {
    // this.setState({
    //   isChecked: event.target.checked,
    // });
    setIsChecked(event.target.checked);
  };
  useEffect(() => {
    // Your code here
    if (localStorage.checkbox && localStorage.Email !== "") {
      // this.setState({
      //   isChecked: true,
      //   email: localStorage.email,
      //   password: localStorage.password,
      // });
      setIsChecked(true);
      setInputs({
        Email: localStorage.Email,
        Password: localStorage.Password,
        UserType: localStorage.UserType,
      });
    }
  }, []);
  return (
    <React.Fragment>
      {/* <header>
        <NavigationBar />
      </header> */}
      <div className="auth-wrraper">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} xl={7}>
              <Card className="mt-5 mb-5 p-3">
                <Card.Body className="main-card py-0">
                  <div className="header-content">
                    <h4 className="mt-3">Sign In to Explore More</h4>
                    <div className="d-flex align-items-center justify-content-center my-3">
                      <button className="google-btn">
                        <img src={GoogleIcon} /> Continue with Google
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#10375C",
                        }}
                      />

                      <div>
                        <p className="or-text">OR</p>
                      </div>

                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#10375C",
                        }}
                      />
                    </div>
                    <Form className="mt-3">
                      <Form.Group className="mb-3" controlId="formUserComp">
                        <Form.Select
                          className="input-field selectpicker form-control"
                          name="UserType"
                          onChange={inputChangeHandler}
                          value={inputs.UserType}
                        >
                          <option value="" disabled selected>
                            User Type
                          </option>
                          <option value="Resource">Resource</option>
                          <option value="Vendor">Vendor</option>
                        </Form.Select>
                      </Form.Group>
                      {inputs.UserType !== "" && (
                        <>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <InputGroup className=" d-flex align-items-center">
                              <Form.Control
                                type="email"
                                name="Email"
                                placeholder="Email Address"
                                className="input-field email-field border-0"
                                onChange={inputChangeHandler}
                                defaultValue={inputs.Email}
                              />
                              <span className="p-2">
                                <img src={EmailIcon} />
                              </span>
                            </InputGroup>
                            {error.emailErr && (
                              <p style={errorStyle}>{error.emailErr}</p>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formPassword">
                            <InputGroup className=" d-flex align-items-center">
                              <Form.Control
                                type={passwordShown ? "text" : "password"}
                                name="Password"
                                placeholder="Password"
                                className="input-field password-field border-0"
                                onChange={inputChangeHandler}
                                defaultValue={inputs.Password}
                              />
                              {/* <span className="p-2">
                            <img src={EyeIcon} />
                          </span> */}
                              {passwordShown ? (
                                <FaEye
                                  className="trailIcon"
                                  size={25}
                                  style={{ color: "#9A9A9A", margin: "5px" }}
                                  onClick={() =>
                                    setPasswordShown((pass) => !pass)
                                  }
                                />
                              ) : (
                                <FaEyeSlash
                                  className="trailIcon"
                                  size={25}
                                  style={{ color: "#9A9A9A", margin: "5px" }}
                                  onClick={() =>
                                    setPasswordShown((pass) => !pass)
                                  }
                                />
                              )}
                            </InputGroup>
                            {error.password && (
                              <p style={errorStyle}>{error.password}</p>
                            )}
                            {error.allError && (
                              <p style={errorStyle}>{error.allError}</p>
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                          >
                            <div className="mb-3 form-check d-flex justify-content-between">
                              <div>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck1"
                                  checked={isChecked}
                                  onChange={onChangeCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck1"
                                >
                                  Remember Me ?
                                </label>
                              </div>
                              <div>
                                <span
                                  className="form-check-label"
                                  onClick={forgotpassword}
                                  style={{ cursor: "pointer" }}
                                >
                                  Forgot Password?
                                </span>
                              </div>
                            </div>
                          </Form.Group>

                          <Button
                            className="submit-btn"
                            size="lg"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Continue
                          </Button>
                        </>
                      )}
                    </Form>
                    <span className="form-check-label-dark d-flex justify-content-center my-3">
                      Don’t Have an Account ?
                      <span
                        className="orange-dark ms-2"
                        onClick={signupHandler}
                      >
                        Create Your Account Now{" "}
                      </span>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={false}
      />
    </React.Fragment>
  );
};

export default LogIn;
