import { Fragment } from "react";
import React from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import CustomInput from "../../CustomInput/CustomInput";
// import { Gender, Language, MaritalStatus } from "../../Dropdowns/DropdownList";
// import { placeGender } from "../../Dropdowns/placeholders";
// import Dropdowns from "../../Dropdowns/Dropdowns";
import MyComponent from "../../Dropdowns/Dropdowns2";
// import "./PersonalInfo.scss";
import TagsInput from "./TagsInput";
import cancleicon from "../../../assets/cancleicon.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { add_Your_Skill_Tags } from "../../../redux/actions/action";
import { EmployeeAPI } from "../../../services/EmployeeAPI";
import { Language } from "../../Dropdowns/DropdownList";
// import Multiselect from "multiselect-react-dropdown";
// import MultiSelect from "editable-creatable-multiselect";
import { skillsData } from "../../Dropdowns/DropdownList";

const AddYourSkills = (props) => {
  const dispatch = useDispatch();
  const add_your_skill_state = useSelector(
    (state) => state.EmployeeManualResumeReducer.add_Your_Skill_Tags
  );
  const [tags, setTags] = useState(add_your_skill_state);

  const [newAddedTags, setNewAddedTags] = useState([]);
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const errorStyle = {
    fontSize: 12,
    color: "red",
  };

  const selectedTags = (tags) => {
    setNewAddedTags(tags);
  };

  useEffect(() => {
    setNewAddedTags(tags);
  }, [tags]);

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      selectedTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const handleAddField = () => {
    addTags(data);
  };
  const handleSubmit = () => {
    if (data == "") {
      setError("Skills are rquired");
    } else {
      let skillData = {
        skills: newAddedTags,
      };
      EmployeeAPI(skillData)
        .then((result) => {
          dispatch(add_Your_Skill_Tags(newAddedTags));
          props.selected(6);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  const cancelButtonHandler = () => {
    let newAddedTags = "";
    setNewAddedTags("");
    dispatch(add_Your_Skill_Tags(newAddedTags));
  };
  console.log("addnew", data);

  return (
    <Fragment>
      <div className="container-fluid">
        <p className="head-text">
          Your Skills Will Help Us To Find A Perfect Job For You
        </p>
        <Row className="justify-content-center mb-3 card">
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card.Body className="main-card py-0">
              <h1 className="header-cont mb-5">Add Your Skills</h1>
              <div>
                <Row className="mb-4">
                  <Col sm={12} md={12}>
                    <div className="fild-text">Choose Skills * </div>
                    <Row>
                      <Col sm={10} md={10}>
                        <CustomInput
                          placeholder="Add"
                          name="full_name"
                          type="text"
                          onKeyUp={(event) =>
                            event.key === "Enter" ? addTags(event) : null
                          }
                          onChange={(e) => {setData(e)
                            if(data!==""){
                                setError("")
                            }
                        }}
                        />

                        {/* <MultiSelect
                          suggestions={skillsData}
                          // selectedItems={selectedList}
                          // updateSuggestions={(response) => {
                          //   console.log("suggestion: ");
                          //   console.log(response);
                          // }}
                          // updateSelectedItems={(response) => {
                          //   console.log("selection: ");
                          //   console.log(response);
                          //   // let's add removed items to the suggestion
                          //   if (response.removedItem) {
                          //     setSuggestions([
                          //       ...suggestions,
                          //       response.removedItem,
                          //     ]);
                          //   }
                          //   setSelectedList(response.list);
                          // }}
                          displayField={"name"}
                          maxDisplayedItems={5}
                          disabled={false}
                          editFieldPosBelow={true}
                          placeholder={
                            "Type the name of the desired company or choose one."
                          }
                        /> */}

                        {error && <p style={errorStyle}>{error}</p>}
                      </Col>
                      <Col sm={2} md={2}>
                        <Button className="add-bt" onClick={handleAddField}>
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="addYourSkill d-flex">
                    {newAddedTags?.length >= 1 &&
                      newAddedTags?.map((item, index) => {
                        return (
                          <React.Fragment>
                            <div className="skill-list">
                              <div className="skill-text ">
                                {item}
                                <img
                                  src={cancleicon}
                                  onClick={() => removeTags(index)}
                                  className="close-bt"
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Col>
        </Row>
        <div className="end-bt">
          <Row className="bt">
            <Col className="bt">
              <Button className="mb-3 Cancel-bt" onClick={cancelButtonHandler}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="mb-3 Save-bt" onClick={handleSubmit}>
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default AddYourSkills;
