import React, { Fragment } from "react";
import "../AboutUs/AboutUs.scss";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import img from "../../assets/AboutUs.svg";
import img2 from "../../assets/Aboutimage.svg";
import img3 from "../../assets/orange.svg";
import Footer from "../../layouts/Footer/Footer";

const AboutUs = () => {
  return (
    <Fragment>
      <NavigationBar />
      <div className="container3">
        <div className="row">
          <div className="banner">
            <img src={img} alt="" />
            <h4 className="heading">About Skiple</h4>
          </div>
        </div>

        <div className="row mt-3 p-5 about-detail">
          <div className="  col-md-6 col-12">
            <h6 className="header-subtitle">
              <span>About Us</span>
            </h6>
            <div className="about-skipple">
              <h3 className="about-title">Want to know more about skiple ?</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis
                metus, pulvinar ac tempus et quisque consectetur. Sed vitae,
                enim, in tellus sed. Elementum non cum eget massa.
              </p>
              <p className="getstart-btn">Get Started</p>
            </div>
          </div>
          <div className="col-md-6 col-12 about-img mt-4 m-0">
            <div className="divyang mb-5">
              <img src={img3} alt="" />
            </div>
            <div className="about-final "><img src={img2} alt=''/> </div>
          </div>
        </div>
      </div>
      <section className="container-fluid footer mt-5" id="footer-section">
        <Footer />
      </section>
    </Fragment>
  );
};

export default AboutUs;
