export const Gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

  export const NumberOfHours = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
  ]

  export const MaritalStatus = [
    { value: 'single', label: 'Single' },
    { value: 'married ', label: 'Married ' },
  ]
  export const CourseType = [
    { value: 'Full-Time', label: 'Full Time' },
    { value: 'Part-Time', label: 'Part Time' },
    { value: 'Regular', label: 'Regular' }
  ]
  
  export const QualificationType = [
    { value: 'Secondary(10th Pass)', label: 'Secondary(10th Pass)' },
    { value: 'Higher Secondary(12th Pass)', label: 'Higher Secondary(12th Pass)' },
    { value: 'Diploma', label: 'Diploma' },
    { value: 'Graduation', label: 'Graduation'},
    { value: 'Post Graduation ', label: 'Post Graduation ' },
    { value: 'Master’s', label: 'Master’s' },
    { value: 'PHD ', label: 'PHD ' },
  ]

// export const QualificationType = [
//   { value: "Secondary(10th Pass)", label: "Secondary(10th Pass)" },
//   {
//     value: "Higher Secondary(12th Pass)",
//     label: "Higher Secondary(12th Pass)",
//   },
//   { value: "Diploma", label: "Diploma" },
//   { value: "Graduation", label: "Graduation" },
//   { value: "Post Graduation ", label: "Post Graduation " },
//   { value: "Master’s", label: "Master’s" },
//   { value: "PHD ", label: "PHD " },
// ];

export const Month = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const skillsData=[
  {value:"UI/UX",label:"UI/UX"},
  {value:"HTML",label:"HTML"},
  {value:"CSS",label:"CSS"},
  {value:"NODE",label:"NODE"},
  {value:"JAVASCRIPT",label:"JAVASCRIPT"},
]
export const Year = [
  { value: "1970 ", label: "1970" },
  { value: "1971  ", label: "1971 " },
  { value: "1972 ", label: "1972 " },
  { value: "1973  ", label: "1973 " },
  { value: "1974 ", label: "1974 " },
  { value: "1975  ", label: "1975 " },
  { value: "1976 ", label: "1976 " },
  { value: "1977  ", label: "1977 " },
  { value: "1978", label: "1978" },
  { value: "1979 ", label: "1979" },

  { value: "1980 ", label: "1980" },
  { value: "1981  ", label: "1981 " },
  { value: "1982 ", label: "1982 " },
  { value: "1983  ", label: "1983 " },
  { value: "1984 ", label: "1984 " },
  { value: "1985  ", label: "1875 " },
  { value: "1986 ", label: "1986 " },
  { value: "1987  ", label: "1987 " },
  { value: "1988", label: "1988" },
  { value: "1989 ", label: "1989" },

  { value: "1990 ", label: "1990" },
  { value: "1991  ", label: "1991 " },
  { value: "1992 ", label: "1992 " },
  { value: "1993  ", label: "1993 " },
  { value: "1994 ", label: "1994 " },
  { value: "1995  ", label: "1995 " },
  { value: "1996 ", label: "1996 " },
  { value: "1997  ", label: "1997 " },
  { value: "1998", label: "1998" },
  { value: "1999 ", label: "1999" },

  { value: "2000 ", label: "2000" },
  { value: "2001  ", label: "2001 " },
  { value: "2002 ", label: "2002 " },
  { value: "2003  ", label: "2003 " },
  { value: "2004 ", label: "2004 " },
  { value: "2005  ", label: "2005 " },
  { value: "2006 ", label: "2006 " },
  { value: "2007  ", label: "2007 " },
  { value: "2008", label: "2008" },
  { value: "2009 ", label: "2009" },

  { value: "2010 ", label: "2010" },
  { value: "2011  ", label: "2011 " },
  { value: "2012 ", label: "2012 " },
  { value: "2013  ", label: "2013 " },
  { value: "2014 ", label: "2014 " },
  { value: "2015  ", label: "2015 " },
  { value: "2016 ", label: "2016 " },
  { value: "2017  ", label: "2017 " },
  { value: "2018", label: "2018" },
  { value: "2019 ", label: "2019" },
  { value: "2020  ", label: "2020 " },
  { value: "2021", label: "2021" },
  { value: "2022 ", label: "2022" },
];

export const Language = [
  { value: "arabic", label: "Arabic" },
  { value: "abaza ", label: "Abaza" },
  { value: "Abua", label: "Abua" },
  { value: "Aku ", label: "Aku" },
  { value: "Ahlo", label: "Ahlo" },
  { value: "Aranese ", label: "Aranese" },
  { value: "Adyghe", label: "Adyghe" },
  { value: "Aztec ", label: "Aztec" },
  { value: "Aymara", label: "Aymara" },
  { value: "Armenian ", label: "Armenian" },
  { value: "Azerbaijani ", label: "Azerbaijani" },

  { value: "Bengali", label: "Bengali" },
  { value: "Bulgarian ", label: "Bulgarian" },
  { value: "Burushaski", label: "Burushaski" },
  { value: "Bacairi ", label: "Bacairi" },
  { value: "Bihari", label: "Bihari" },
  { value: "Bhili ", label: "Bhili" },
  { value: "Batsbiy", label: "Batsbiy" },

  { value: "Croatian", label: "Croatian" },
  { value: "Czech ", label: "Czech" },
  { value: "Chinese", label: "Chinese" },
  { value: "Celtiberian ", label: "Celtiberian" },
  { value: "Catalonian", label: "Catalonian" },
  { value: "Chiswina ", label: "Chiswina" },

  { value: "Danish", label: "Danish" },
  { value: "Dutch ", label: "Dutch" },
  { value: "Dyula", label: "Dyula" },
  { value: "Dagbane ", label: "Dagbane" },
  { value: "Dari", label: "Dari" },
  { value: "Dholuo ", label: "Dholuo" },

  { value: "English", label: "English" },
  { value: "Esperanto ", label: "Esperanto" },
  { value: "European", label: "European" },
  { value: "Ewe ", label: "Ewe" },

  { value: "Finnish", label: "Finnish" },
  { value: "French ", label: "French" },
  { value: "Farsi", label: "Farsi" },
  { value: "Faliscan ", label: "Faliscan" },
  { value: "Friulian", label: "Friulian" },

  { value: "Gujarati", label: "Gujarati" },
  { value: "German ", label: "German" },
  { value: "Greek", label: "Greek" },
  { value: "Ganda ", label: "Ganda" },
  { value: "Gikuyu", label: "Gikuyu" },
  { value: "Ge’ez ", label: "Ge’ez" },

  { value: "Haitian", label: "Haitian" },
  { value: "Hindi ", label: "Hindi" },
  { value: "Hebrew", label: "Hebrew" },
  { value: "Hungarian ", label: "Hungarian" },
  { value: "Hottentot", label: "Hottentot" },
  { value: "Hawaiian ", label: "Hawaiian" },

  { value: "Indonesian", label: "Indonesian" },
  { value: "Italian ", label: "Italian" },
  { value: "Igorot", label: "Igorot" },
  { value: "Inupiak ", label: "Inupiak" },

  { value: "Japanese", label: "Japanese" },
  { value: "Jerriais ", label: "Jerriais" },
  { value: "Jonkha", label: "Jonkha" },

  { value: "Korean", label: "Korean" },
  { value: "Kamba ", label: "Kamba" },
  { value: "Kiga", label: "Kiga" },
  { value: "Komi ", label: "Komi" },
  { value: "Kotopo", label: "Kotopo" },
  { value: "Kilinga ", label: "Kilinga" },
  { value: "Kannada", label: "Kannada" },
  { value: "Khoekhoe ", label: "Khoekhoe" },
  { value: "Kashmiri", label: "Kashmiri" },

  { value: "Latin", label: "Latin" },
  { value: "Lakota ", label: "Lakota" },
  { value: "Lomwe", label: "Lomwe" },
  { value: "Llocano ", label: "Llocano" },

  { value: "Malayalam", label: "Malayalam" },
  { value: "Marathi ", label: "Marathi" },
  { value: "Maya", label: "Maya" },
  { value: "Mundang ", label: "Mundang" },
  { value: "Meithei", label: "Meithei" },
  { value: "Mongo ", label: "Mongo" },

  { value: "Norwegian", label: "Norwegian" },
  { value: "Nuuchahnulth ", label: "Nuuchahnulth" },
  { value: "Nestorian", label: "Nestorian" },
  { value: "Niuean ", label: "Niuean" },
  { value: "Nyanja", label: "Nyanja" },

  { value: "Otomi", label: "Otomi" },
  { value: "Occidental ", label: "Occidental" },
  { value: "Ostyak", label: "Ostyak" },
  { value: "Oneida ", label: "Oneida" },
  { value: "Oscan", label: "Oscan" },

  { value: "Punjabi", label: "Punjabi" },
  { value: "Portuguese ", label: "Portuguese" },
  { value: "Polish", label: "Polish" },
  { value: "Persian ", label: "Persian" },
  { value: "Pima", label: "Pima" },

  { value: "Quebocis", label: "Quebocis" },
  { value: "Quinalt ", label: "Quinalt" },

  { value: "Russian", label: "Russian" },
  { value: "Romanian ", label: "Romanian" },
  { value: "Rarotongan", label: "Rarotongan" },
  { value: "Romani ", label: "Romani" },

  { value: "Serbian", label: "Serbian" },
  { value: "Swedish ", label: "Swedish" },
  { value: "Spanish", label: "Spanish" },
  { value: "Sukuma ", label: "Sukuma" },
  { value: "Savara", label: "Savara" },
  { value: "Shuna ", label: "Shuna" },
  { value: "Samoan ", label: "Samoan" },

  { value: "Tamil", label: "Tamil" },
  { value: "Telugu ", label: "Telugu" },
  { value: "Tagalog", label: "Tagalog" },
  { value: "Tswana ", label: "Tswana" },
  { value: "Tzotzil", label: "Tzotzil" },
  { value: "Toucouleur ", label: "Toucouleur" },
  { value: "Toucouleur", label: "Toucouleur" },
  { value: "Tok Pisin ", label: "Tok Pisin" },
  { value: "Temmne", label: "Temmne" },
  { value: "Tetum ", label: "Tetum" },
  { value: "Teluga ", label: "Teluga" },

  { value: "Ukrainian", label: "Ukrainian" },
  { value: "Urdu ", label: "Urdu" },
  { value: "Uanacross", label: "Uanacross" },
  { value: "Uzbek ", label: "Uzbek" },

  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Votyak ", label: "Votyak" },

  { value: "Wolof", label: "Wolof" },
  { value: "Welsh ", label: "Welsh" },
  { value: "Warlpiri", label: "Warlpiri" },

  { value: "Yoeme", label: "Yoeme" },
  { value: "Yup’ik ", label: "Yup’ik" },
  { value: "Yucatec", label: "Yucatec" },
  { value: "Yaunde ", label: "Yaunde" },

  { value: "Zuni", label: "Zuni" },
  { value: "Zakkur ", label: "Zakkur  " },
];
