import { MultiSelectUnstyled } from "@mui/base";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import CustomInput from "../../CustomInput/CustomInput";
import { Gender, Language, MaritalStatus } from "../../Dropdowns/DropdownList";
// import { placeGender } from "../../Dropdowns/placeholders";
// import Dropdowns from "../../Dropdowns/Dropdowns";
import MyComponent from "../../Dropdowns/Dropdowns2";
import Multiselect from "multiselect-react-dropdown";
import "./PersonalInfo.scss";
import { EmployeeAPI } from "../../../services/EmployeeAPI";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeManualResume } from "../../../redux/actions/action";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const PersonalInfo = (props) => {
  const [dropLang, setDropLang] = useState();
  const [flag, setFlag] = useState(false);
  const current = new Date();
  let date = new Date();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const state = useSelector((state) => state);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  const [personalInfo, setPersonalInfo] = useState({
    full_name:
      state.EmployeeManualResumeReducer.PersonalInfoResumeData.fullName,
    birth_date:
      state?.EmployeeManualResumeReducer?.PersonalInfoResumeData.birthDate,
    gender: state.EmployeeManualResumeReducer.PersonalInfoResumeData.gender,
    language: state.EmployeeManualResumeReducer.PersonalInfoResumeData.language,
    marital_status:
      state.EmployeeManualResumeReducer.PersonalInfoResumeData.maritalStatus,
    about_me: state.EmployeeManualResumeReducer.PersonalInfoResumeData.aboutMe,
  });

  const [error, setError] = useState({
    nameErr: "",
    dobErr: "",
    genderErr: "",
    languageErr: "",
    MaritalStatusErr: "",
  });
  const errorStyle = {
    fontSize: 12,
    color: "red",
  };

  useEffect(() => {
    setPersonalInfo({
      full_name:
        state?.EmployeeManualResumeReducer?.PersonalInfoResumeData?.fullName,
      birth_date:
        state?.EmployeeManualResumeReducer?.PersonalInfoResumeData?.birthDate,
      gender:
        state?.EmployeeManualResumeReducer?.PersonalInfoResumeData?.gender,
      language:
        state?.EmployeeManualResumeReducer?.PersonalInfoResumeData?.language,
      marital_status:
        state?.EmployeeManualResumeReducer?.PersonalInfoResumeData?.maritalStatus,
      about_me:
        state?.EmployeeManualResumeReducer?.PersonalInfoResumeData?.aboutMe,
    });
  }, [state?.EmployeeManualResumeReducer?.PersonalInfoResumeData]);

  const personalInfoinputChangeHandler = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (name === "birth_date") {
      value = moment(value).format("MM/DD/YYYY");
    }
    setPersonalInfo((values) => ({ ...values, [name]: value }));
    if (name === "full_name") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setError({ ...error, nameErr: "Full Name must contain Alphabet only" });
      } else {
        setError({ ...error, nameErr: "" });
      }
    }
    if (name === "birth_date") {
      if (value != "" && value != null) {
        setError({ ...error, dobErr: "" });
      }
    }
  };

  const personalInfoDropDownHandler = (event) => {
    const name = event.name;
    const value = event.value;
    setPersonalInfo((values) => ({ ...values, [name]: value }));
    if (name === "gender") {
      if (value != "" && value != null) {
        setError({ ...error, genderErr: "" });
      }
    }
    // if (name === "marital_status") {
    //   if (value != "") {
    //     setError({ ...error, MaritalStatusErr: "" });
    //   }
    // }
  };
  const validate = () => {
    let fullNameError = error.nameErr;
    let dobError = error.dobErr;
    let genderError = error.genderErr;
    let languageError = error.languageErr;
    let maritalStatusError = error.MaritalStatusErr;

    if (personalInfo.full_name == "" || personalInfo.full_name == null) {
      fullNameError = "Full Name is required";
    }
    if (personalInfo.birth_date == "" || personalInfo.birth_date == null) {
      dobError = "Date of birth is required";
    }
    if (personalInfo.gender == "" || personalInfo.gender == null) {
      genderError = "gender is required";
    }
    if (personalInfo.language == "" || personalInfo.language == null) {
      languageError = "language is required";
    }
    // if (
    //   personalInfo.marital_status == "" ||
    //   personalInfo.marital_status == null
    // ) {
    //   maritalStatusError = "Marital status is required";
    // }

    setError({
      nameErr: fullNameError,
      dobErr: dobError,
      genderErr: genderError,
      languageErr: languageError,
      // MaritalStatusErr: maritalStatusError,
    });
  };
  const saveButtonHandler = () => {
    validate();
    if (
      personalInfo.full_name !== null &&
      personalInfo.full_name !== "" &&
      personalInfo.birth_date !== null &&
      personalInfo.birth_date !== "" &&
      personalInfo.gender !== null &&
      personalInfo.gender !== "" &&
      personalInfo.language !== null &&
      personalInfo.language !== ""
    ) {
      let data = {
        fullName: personalInfo.full_name,
        birthDate: personalInfo.birth_date,
        gender: personalInfo.gender,
        language: personalInfo.language,
        maritalStatus: personalInfo.marital_status,
        aboutMe: personalInfo.about_me,
      };
      EmployeeAPI(data)
        .then((result) => {
          let personalInfoData = {
            fullName: personalInfo.full_name,
            birthDate: personalInfo.birth_date,
            gender: personalInfo.gender,
            language: personalInfo.language,
            maritalStatus: personalInfo.marital_status,
            aboutMe: personalInfo.about_me,
          };
          dispatch(EmployeeManualResume(personalInfoData));
          props.selected(2);
          // navigate('/employee-manual-profile-contact-information');
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };
  const cancelButtonHandler = (e) => {
    e.preventDefault();
    let PersonalInfoResumeData = {
      fullName: "",
      birthDate: "",
      gender: "",
      language: "",
      maritalStatus: "",
      aboutMe: "",
    };
    dispatch(EmployeeManualResume(PersonalInfoResumeData));
  };
  
  const [selectedValue, setSelectedValue] = useState([]);
  
  // handle onChange event of the dropdown

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={false}
      />
      <div className="container-fluid">
        <p className="head-text">
          Give Your Basic Information So Reqruiter can Know About You{" "}
        </p>
        <Row className="justify-content-center mb-3 card">
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card.Body className="main-card py-0">
              <h1 className="header-cont mb-5">Personal Information</h1>
              <div>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">Full Name *</div>
                    <div className="w-100">
                      <CustomInput
                        placeholder="Full Name"
                        name="full_name"
                        type="text"
                        value={personalInfo.full_name}
                        onChange={personalInfoinputChangeHandler}
                      />
                    </div>
                    {error.nameErr && <p style={errorStyle}>{error.nameErr}</p>}
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="fild-text">Date of Birth *</div>
                    <div>
                      <input
                        type="date"
                        name="birth_date"
                        className="date-input"
                        max={moment(current).format("YYYY-MM-DD")}
                        value={moment(personalInfo.birth_date).format(
                          "YYYY-MM-DD"
                        )}
                        // defaultValue={new Date(personalInfo.birth_date)}
                        onChange={personalInfoinputChangeHandler}
                      />
                    </div>
                    {error.dobErr && <p style={errorStyle}>{error.dobErr}</p>}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">Gender * </div>
                    <div>
                      <MyComponent
                        name="gender"
                        placeholder="Choose Gender"
                        options={Gender}
                        value={personalInfo.gender}
                        default={personalInfo.gender}
                        onChange={personalInfoDropDownHandler}
                      />
                    </div>
                    {error.genderErr && (
                      <p style={errorStyle}>{error.genderErr}</p>
                    )}
                  </Col>
                  <Col sm={6} md={6}>
                    <div className="fild-text">Language *</div>
                    <div>
                      <Multiselect
                        isObject={false}
                        options={Language.map((item) => {
                          return item.label;
                        })}
                        onSelect={(e) => {
                          setPersonalInfo({ ...personalInfo, language: e });

                          if (personalInfo.language !== "") {
                            setError({ ...error, languageErr: "" });
                          }
                        }}
                        // defaultValue={personalInfo.language.map((item)=>{return item.label })}
                        selectedValues={personalInfo.language}
                        name="language"
                        placeholder="Choose Language"
                        showCheckbox
                      />
                    </div>
                    {error.languageErr && (
                      <p style={errorStyle}>{error.languageErr}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={6} md={6}>
                    <div className="fild-text">Martial Status </div>
                    <div className="">
                      <MyComponent
                        placeholder="Choose Marital Status"
                        name="marital_status"
                        options={MaritalStatus}
                        value={personalInfo.marital_status}
                        default={personalInfo.marital_status}
                        onChange={personalInfoDropDownHandler}
                      />
                    </div>
                    {/* {error.MaritalStatusErr && (
                      <p style={errorStyle}>{error.MaritalStatusErr}</p>
                    )} */}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col sm={12} md={12}>
                    <div className="fild-text">About Me</div>
                    <div>
                      <textarea
                        placeholder="About Me"
                        className="text-area area"
                        name="about_me"
                        value={personalInfo.about_me}
                        onChange={personalInfoinputChangeHandler}
                      ></textarea>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Col>
        </Row>
        <div className="end-bt">
          <Row className="bt">
            <Col className="bt">
              <Button
                className="mb-3 Cancel-bt"
                onClick={(e) => cancelButtonHandler(e)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="mb-3 Save-bt "
                onClick={() => saveButtonHandler()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default PersonalInfo;
