import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import "./Auth.scss";
import GoogleIcon from "../../assets/google-icon.svg";
import EmailIcon from "../../assets/mail-icon.svg";
import EyeIcon from "../../assets/eye-icon.svg";
import { MicroServicePostRequestAPI } from "../../services/ApiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResetPassword = () => {
  const [inputs, setInputs] = useState({ Password: "", ConfirmPassword: "" });
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [error, setError] = useState({
    password: "",
    cfnPassword: "",
    allError: "",
  });
  const navigate = useNavigate();
  const location = useLocation();

  const errorStyle = {
    fontSize: 12,
    color: "red",
  };
  let token = location.search.split("=")[1];
  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    if (name === "Password") {
      if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        )
      ) {
        setError({
          ...error,
          password:
            "Password must be 8 or more characters in length && Use upper, lower, digit and special character",
        });
      } else {
        setError({ ...error, password: null });
      }
    }
    if (name === "ConfirmPassword") {
      if (value !== inputs.Password) {
        setError({ ...error, cfnPassword: "Password does not match" });
      } else {
        setError({ ...error, cfnPassword: null });
      }
    }
  };
  useEffect(() => {
    if (error?.allError !== null && error?.allError !== "") {
      if (inputs.Password === "" || inputs.ConfirmPassword === "") {
        setError({ ...error, allError: "All Field Required" });
      } else {
        setError({ ...error, allError: null });
      }
    }
  }, [inputs]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputs.ConfirmPassword === "" || inputs.Password === "") {
      setError({ ...error, allError: "All Field Required" });
    } else {
      setError({ ...error, allError: null });
      if (error.password === null && error.cfnPassword === null) {
        setError({ allError: "", cfnPassword: "" });
        const inputData = {
          password: inputs.Password,
          confirmPassword: inputs.ConfirmPassword,
          token,
        };
        MicroServicePostRequestAPI(
          `api/vendor/reset-password?token=${token}`,
          "post",
          inputData
        )
          .then((result) => {
            if (result) {
              toast.success("Password Changed");
              setTimeout(() => {
                window.location.replace("new-login");
              }, 2000);
            }
          })
          .catch((err) => {
            console.log("error", err);
          });
      }
    }
  };
  const loginHandler = (e) => {
    e.preventDefault();
    navigate("/new-login");
  };
  return (
    <React.Fragment>
      <header>
        <NavigationBar />
      </header>
      <div className="auth-wrraper">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} xl={7}>
              <Card className="mt-5 p-3">
                <Card.Body className="main-card py-0">
                  <div className="header-content">
                    <h4 className="mt-3">Reset Password</h4>
                    <div className="d-flex align-items-center justify-content-center my-3">
                      <button className="google-btn">
                        <img src={GoogleIcon} /> Continue with Google
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#9A9A9A",
                        }}
                      />

                      <div>
                        <p className="line-style">OR</p>
                      </div>

                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#9A9A9A",
                        }}
                      />
                    </div>
                    <Form className="mt-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <InputGroup className=" d-flex align-items-center">
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            name="Password"
                            placeholder="Enter New Password"
                            className="input-field email-field border-0"
                            onChange={inputChangeHandler}
                            defaultValue={inputs.Password}
                          />
                          {passwordShown ? (
                            <FaEye
                              className="trailIcon"
                              size={30}
                              style={{ color: "#9A9A9A", margin: "5px" }}
                              onClick={() => setPasswordShown((pass) => !pass)}
                            />
                          ) : (
                            <FaEyeSlash
                              className="trailIcon"
                              size={30}
                              style={{ color: "#9A9A9A", margin: "5px" }}
                              onClick={() => setPasswordShown((pass) => !pass)}
                            />
                          )}
                          {/* <span className="p-2">
                            <img src={EyeIcon} />
                          </span> */}
                        </InputGroup>
                        {error.password && (
                          <p style={errorStyle}>{error.password}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formPassword">
                        <InputGroup className=" d-flex align-items-center">
                          <Form.Control
                            type={confirmPasswordShown ? "text" : "password"}
                            name="ConfirmPassword"
                            placeholder="Confirm New Password"
                            className="input-field password-field border-0"
                            onChange={inputChangeHandler}
                            defaultValue={inputs.ConfirmPassword}
                          />
                          {confirmPasswordShown ? (
                            <FaEye
                              className="trailIcon"
                              size={30}
                              style={{ color: "#9A9A9A", margin: "5px" }}
                              onClick={() =>
                                setConfirmPasswordShown((pass) => !pass)
                              }
                            />
                          ) : (
                            <FaEyeSlash
                              className="trailIcon"
                              size={30}
                              style={{ color: "#9A9A9A", margin: "5px" }}
                              onClick={() =>
                                setConfirmPasswordShown((pass) => !pass)
                              }
                            />
                          )}
                          {/* <span className="p-2">
                            <img src={EyeIcon} />
                          </span> */}
                        </InputGroup>
                        {error.cfnPassword && (
                          <p style={errorStyle}>{error.cfnPassword}</p>
                        )}
                        {error.allError && (
                          <p style={errorStyle}>{error.allError}</p>
                        )}
                      </Form.Group>
                      <Button
                        className="submit-btn"
                        size="lg"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Form>
                    <span className="form-check-label-dark d-flex justify-content-center">
                      <span
                        className="orange-dark  my-3"
                        onClick={loginHandler}
                      >
                        Go To Login Page Now
                      </span>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={false}
      />
    </React.Fragment>
  );
};

export default ResetPassword;
