export const loginData = (data)=>{
    return {
        type:"login_data",
        payload:data
    }
}
export const EmployeeProfile = (data)=>{
   
    return {
        type:"create_employee_profile",
        payload:data
    }
}
export const EmployeeManualResume = (data)=>{
    return {
        type:"manual_resume_data",
        payload:data
    }
}

export const contact_info_resume_data = (data)=>{
      return {
          type:"contact_info_resume_data",
          payload:data
      }
  }

  export const education_info_resume_data = (data)=>{
      return {
          type:"education_info_resume_data",
          payload:data
      }
  }
export const add_Your_Skill_Tags = (data)=>{

    return {
        type:"add_Your_Skill_Tags",
        payload:data
    }
}
export const experience_data = (data)=>{
    return {
        type:"experience_data",
        payload:data
    }
}
export const total_Education_Data = (data)=>{
        return {
            type:"total_Education_Data",
            payload:data
        }
    }
    export const fresher_Educational_Data = (data)=>{
        return {
            type:"fresher_Educational_Data",
            payload:data
        }
    }
