import { Modal, Button } from 'react-bootstrap/Modal';
import React from 'react'
import { Link } from "react-router-dom";
import './SignUp.scss'
import { useState } from 'react';
import { MicroServicePostRequestAPI } from '../../services/ApiService';
const SignUp = ({ hideModel }) => {
  const [inputs, setInputs] = useState({ Email: '', Password: '', ConfirmPassword: '' });
  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  const dataObj={
    email: 'John@gmail.com',
    username: 'johnd',
    password: 'm38rmF$',
    name: {
      firstname: 'John',
      lastname: 'Doe'
    },
    address: {
      city: 'kilcoole',
      street: '7835 new road',
      number: 3,
      zipcode: '12926-3874',
      geolocation: {
        lat: '-37.3159',
        long: '81.1496'
      }
    },
    phone: '1-570-236-7033'
  }

  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const registrationHandler = () => {
    if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(inputs.Email)) {
      setEmailErr(true)
    }
    else{
      setEmailErr(false)
    }
    if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(inputs.Password)){
      setPasswordErr(true)
    }
    else if(inputs.Password!==inputs.ConfirmPassword){
      alert('password does not match')
    }
    else {
      setPasswordErr(false);
      MicroServicePostRequestAPI('users', 'post', dataObj).then((result) => {
        if (result) {
          console.log('result', result)
        }
      }).catch((error) => {
        console.log('error', error)
      })
    }
  }
  return (
    <>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Registration</h5>
            <button type="button" class="btn-close" aria-label="Close" onClick={hideModel}></button>
          </div>
          <div class="modal-body">
            <label>Email</label>
            <br />
            <input type="email" defaultValue={inputs.Email} placeholder="Email" name="Email" onChange={inputChangeHandler} required />
            {emailErr && <p className="error">Please Enter Valid Email</p>}
            <br />
            <label>Password</label>
            <br />
            <input type="password" defaultValue={inputs.Password} placeholder="Password" name="Password" onChange={inputChangeHandler} required />
            {passwordErr && <p className="error">Please Enter Valid Password</p>}
            <br />
            <label>Confirm Password</label>
            <br />
            <input type="password" defaultValue={inputs.ConfirmPassword} placeholder="Confirm Password" name="ConfirmPassword" onChange={inputChangeHandler} required />
            <br />
            <label>Select</label>
            <br />
            <input type="radio" name="select" defaultValue="Vendor" onChange={inputChangeHandler} />Vendor
            <input type="radio" name="select" defaultValue="Employee" onChange={inputChangeHandler} />Employee
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" onClick={registrationHandler}>Register</button>
            <br />
            <button type="button" class="btn btn-primary" >Cancel</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp