import { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import "./CreateEmployeeProfile2.scss";
import creatpro2 from "../../assets/creatpro2.svg";
import PhotoDropzone from "../../component/Dropzone/Photo_dropzone";
import FileDropzone from "../../component/Dropzone/File_Dropzone";
import addResume from "../../assets/addResume.svg";
import ManualFileDropzone from "../../component/Dropzone/ManualFile_Dropzone";
import { MicroServicePostRequestAPI } from "../../services/ApiService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const CreateEmployeeProfile2 = () => {
  const navigate = useNavigate();

  const location = useLocation();
  // const history=useHistory();
  const [fileUpload, setFileUpload] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const id = localStorage.getItem("id");

  const selectedProfile = (files) => {
    setSelectedFileName(files?.name);
    setFileUpload(files);
  };
  const backButtonHandler = (e) => {
    e.preventDefault();
    navigate("/create-employeeprofile", { state: location.state });
    // e.preventDefault();
    // window.history.back();
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    var inputData = new FormData();
    inputData.append("upload_resume", fileUpload);
    // let inputData={
    //     upload_photo:fileUpload,
    //     working_hours:workingHours,
    //     hourly_rate:hourlyRate,
    // }
    MicroServicePostRequestAPI(`api/users/uploadResume/${id}`, "put", inputData)
      .then((result) => {
        if (result) {
          console.log("result", result);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <Row className="justify-content-center back">
          <Col xs={10} md={8} lg={6} xl={5}>
            <Card className="mt-5 p-3 card-back">
              <Card.Body className="main-card py-0 ">
                <div
                  className="btn back-text"
                  onClick={(e) => {
                    // navigate("/create-employeeprofile");
                    backButtonHandler(e);
                  }}
                >
                  Back
                </div>
                <div className="header-content">
                  <h4 className="mt-3 creat-text">
                    Create Profile As Employee
                  </h4>
                  <div className="icon">
                    <img src={creatpro2} />
                  </div>
                  <Form className="mt-4">
                    <Form.Group>
                      <div className="file-dropzone mb-3">
                        <FileDropzone
                          uploadedFile={(val) => selectedProfile(val)}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="Create-resume-link mb-3">
                        <div>
                          <Link to="/employee-manual-profile-page2">
                            <img src={addResume} />
                            Create resume Manually
                          </Link>
                        </div>
                      </div>
                    </Form.Group>
                    <Button
                      className="sign-in mb-3"
                      size="lg"
                      type="submit"
                      onClick={onSubmitHandler}
                    >
                      Submit & Next
                    </Button>
                    <Button
                      className=" btn cancle-bt mb-3 "
                      size="lg"
                      type="reset"
                    >
                      Cancle
                    </Button>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
export default CreateEmployeeProfile2;
