import { Fragment, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

const Dropdowns = (props) => {
    
    const [item,setItem]=useState("select");

    useEffect(()=>{
        if(item != "select"){
            props.onChange({name:props.name,value:item})
        }
    },[item])
   
    return (
        <Fragment>
            <Dropdown className="w-100 "  name={props.name} >
                <Dropdown.Toggle variant="" id="dropdown-basic" >
                    {item}
                </Dropdown.Toggle>

                <Dropdown.Menu name={props.name} >
                    {props?.options?.map((item)=>{
                        return(
                            <Dropdown.Item onClick={()=>setItem(item)} >{item}</Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </Fragment>
    )
}
export default Dropdowns;