import { Fragment, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";

import "./CreateEmployeeProfile.scss";
import creatpro from "../../assets/creatpro.svg";
import PhotoDropzone from "../../component/Dropzone/Photo_dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { MicroServicePostRequestAPI } from "../../services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import {
  EmployeeProfile,
  EmployeeProfileDataUpdate,
} from "../../redux/actions/action";
import Select from "react-select";
import MyComponent from "../../component/Dropdowns/Dropdowns2";
import { NumberOfHours } from "../../component/Dropdowns/DropdownList";

const CreateEmployeeProfile = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    workingHours: state.EmployeeReducer.CreateEmployeeData.workingHours,
    hourlyRate: state.EmployeeReducer.CreateEmployeeData.hourlyRate,
    currency: state.EmployeeReducer.CreateEmployeeData.currency,
  });

  const [fileUpload, setFileUpload] = useState(
    state.EmployeeReducer.CreateEmployeeData.fileUpload
  );
  const [selectedFileName, setSelectedFileName] = useState(
    state.EmployeeReducer.CreateEmployeeData.fileUpload?.path
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [flag, setFlag] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [updatedCurrency, setUpdatedCurrency] = useState({
    label: "",
    value: "",
  });
  const location = useLocation();

  const dispatch = useDispatch();

  const id = localStorage.getItem("id");
  const [error, setError] = useState({
    imgErr: "",
    workinghrErr: "",
    hourlyRateErr: null,
    allErr: "",
  });
  const errorStyle = {
    fontSize: 12,
    color: "red",
  };
  useEffect(() => {
    setInputs({
      workingHours: state.EmployeeReducer.CreateEmployeeData.workingHours,
      hourlyRate: state.EmployeeReducer.CreateEmployeeData.hourlyRate,
      currency: state.EmployeeReducer.CreateEmployeeData.currency,
    });
    setSelectedFileName(
      state.EmployeeReducer.CreateEmployeeData.fileUpload?.path
    );
  }, [state.EmployeeReducer.CreateEmployeeData]);

  console.log(state.EmployeeReducer.CreateEmployeeData.currency);
  useEffect(() => {
    setUpdatedCurrency({
      label: state?.EmployeeReducer?.CreateEmployeeData?.currency?.label,
      value: state?.EmployeeReducer?.CreateEmployeeData?.currency?.value,
    });
  }, [flag]);

  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    if (name === "hourlyRate") {
      if (!/^[0-9\b]+$/.test(value) || value==0) {
        setError({ ...error, hourlyRateErr: "Please Enter Valid Hourly Rate" });
      } else {
        setError({ ...error, hourlyRateErr: null });
      }
      // if(value==0){
      //   setError({ ...error, hourlyRateErr: "Please Enter Valid Hourly Rate" });
      // }
      // else{
      //   setError({ ...error, hourlyRateErr: "" });
      // }
    }
  };

  const dropDownHandler = (event) => {
    const name = event.name;
    const value = event.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    console.log("fileUpload", fileUpload);
    console.log("inputs.workingHours", inputs.workingHours);
    console.log("inputs.hourlyRate", inputs.hourlyRate);
    console.log("error.hourlyRateErr", error.hourlyRateErr);
    console.log("inputs.currency", updatedCurrency);
    if (
      fileUpload !== null &&
      fileUpload !== "" &&
      inputs.workingHours !== null &&
      inputs.workingHours !== "" &&
      inputs.hourlyRate !== null &&
      inputs.hourlyRate !== "" &&
      error.hourlyRateErr === null &&
      updatedCurrency?.label !== "" &&
      updatedCurrency.label !== null &&
      updatedCurrency?.label !== undefined
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    fileUpload,
    inputs.workingHours,
    inputs.hourlyRate,
    error.hourlyRateErr,
    updatedCurrency.label,
  ]);

  useEffect(() => {
    MicroServicePostRequestAPI(`api/currency`, "get")
      .then((result) => {
        if (result) {
          setCurrency(
            result.data.map((item) => ({
              value: item._id,
              label: item.currency,
            }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    console.log("currency", updatedCurrency);
  }, [updatedCurrency]);

  const selectedProfile = (files) => {
    setSelectedFileName(files?.name);
    setFileUpload(files);
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    console.log("selected file name", fileUpload);
    console.log("selected file name", inputs.workingHours);
    console.log("selected file name", inputs.hourlyRate);
    console.log("selected file name", updatedCurrency.value);

    if (
      selectedFileName != "" &&
      inputs.workingHours !== "" &&
      inputs.hourlyRate !== "" &&
      error.hourlyRateErr == null
    ) {
      var inputData = new FormData();
      inputData.append("uploadPhoto", fileUpload);
      inputData.append("workingHours", inputs.workingHours);
      inputData.append("hourlyRate", inputs.hourlyRate);
      inputData.append("currency", updatedCurrency.value);
      // let inputData={
      //     upload_photo:fileUpload,
      //     working_hours:workingHours,
      //     hourly_rate:hourlyRate,
      // }
      MicroServicePostRequestAPI(
        `api/resource/createResource/${id}`,
        "put",
        inputData
      )
        .then((result) => {
          if (result) {
            let employeeData = {
              id: result.data._id,
              uploadPhoto: result.data.uploadPhoto,
              workingHours: result.data.workingHours,
              hourlyRate: result.data.hourlyRate,
              fileUpload: fileUpload,
              currency: updatedCurrency,
            };
            setFlag(true);
            dispatch(EmployeeProfile(employeeData));
            navigate("/create-employeeprofile-page2", {
              state: employeeData.id,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  const cancelButtonHandler = (e) => {
    let emptyCreateProfileData = {
      id: "",
      uploadPhoto: "",
      workingHours: "",
      hourlyRate: "",
      fileUpload: "",
      currency: "",
    };
    dispatch(EmployeeProfile(emptyCreateProfileData));
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <Row className="justify-content-center back">
          <Col xs={10} md={8} lg={6} xl={5}>
            <Card className="mt-5 p-3 card-back">
              <Card.Body className="main-card1 py-0 ">
                <div className="">
                  <h4 className="mt-3 creat-text">
                    Create Profile As Employee
                  </h4>
                  <div className="icon">
                    <img src={creatpro} />
                  </div>
                  <Form className="mt-4">
                    <Form.Group>
                      <div className="card-drozone">
                        <div className="">
                          <PhotoDropzone
                            uploadedFile={(val) => selectedProfile(val)}
                            value={fileUpload}
                            selectedFileName={
                              state.EmployeeReducer.CreateEmployeeData
                                .uploadPhoto
                            }
                          />
                        </div>
                        <div className="mb-3">
                          {selectedFileName != ""
                            ? selectedFileName
                            : "Upload Your Photo (.png, .jpg/.jpeg)"}
                        </div>
                      </div>
                      {error.imgErr && <p style={errorStyle}>{error.imgErr}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formUserComp">
                      <MyComponent
                        className="drop"
                        placeholder="Enter Your Working Hours"
                        options={NumberOfHours}
                        name="workingHours"
                        onChange={dropDownHandler}
                        value={inputs.workingHours}
                        default={inputs.workingHours}
                      />
                    </Form.Group>
                    {error.workinghrErr && (
                      <p style={errorStyle}>{error.workinghrErr}</p>
                    )}
                    <Form.Group className="mb-3">
                      <Row>
                        <Col md={4} sm={4}>
                          <Select
                            className="input-field selectpicker "
                            name="currency"
                            defaultInputValue={inputs?.currency?.label}
                            options={currency}
                            onChange={(e) =>
                              setUpdatedCurrency({
                                label: e.label,
                                value: e.value,
                              })
                            }
                          >
                            {/* <option>{currency}</option> */}
                          </Select>
                        </Col>
                        <Col md={8} sm={8}>
                          <InputGroup className=" d-flex align-items-center">
                            <Form.Control
                              type="text"
                              placeholder="Enter Your Hourly Rate"
                              className="input-field password-field border-0"
                              style={{ borderRadius: "5px" }}
                              name="hourlyRate"
                              value={inputs.hourlyRate}
                              onChange={inputChangeHandler}
                            />
                          </InputGroup>
                          {error.hourlyRateErr && (
                            <p style={errorStyle}>{error.hourlyRateErr}</p>
                          )}
                        </Col>
                      </Row>
                      {error.allErr && <p style={errorStyle}>{error.allErr}</p>}
                    </Form.Group>
                    <Button
                      className="sign-in mb-3"
                      size="lg"
                      type="submit"
                      disabled={isDisabled}
                      onClick={(e) => onSubmitHandler(e)}
                    >
                      Submit & Next
                    </Button>
                    <Button
                      className=" btn cancle-bt mb-3 "
                      size="lg"
                      type="reset"
                      onClick={(e) => cancelButtonHandler(e)}
                    >
                      Cancel
                    </Button>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
export default CreateEmployeeProfile;
