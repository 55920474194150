import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavigationBar from "../../layouts/NavigationBar/NavigationBar";
import "./Auth.scss";
import GoogleIcon from "../../assets/google-icon.svg";
import EmailIcon from "../../assets/mail-icon.svg";
import { MicroServicePostRequestAPI } from "../../services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPassword } from "../../redux/actions/authAction";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({ email: '' });
  const [error, setError] = useState({
    emailErr: "",
    allError: ""
  });
  const dispatch=useDispatch();
  // const [user,setUser]=useState(false);
  const loginHandler = (e) => {
    e.preventDefault();
    navigate("/new-login");
  };
  const errorStyle = {
    fontSize: 12,
    color: "red",
  }
  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
    if (name === "email") {
      if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
        setError({ ...error, emailErr: "Please Enter Valid Email" });
      }
      else {
        setError({ ...error, emailErr: null })
      }
    }
  }
  useEffect(() => {
    if(error?.allError !== null && error?.allError !== ""){
      if(inputs.email === "" ){
        setError({...error, allError: "All Field Required" })
      }
      else{
        setError({ ...error, allError: null })
      }
    }
  }, [inputs])
  const forgotPasswordHandler = (e) => {
    e.preventDefault();
    if (inputs.email==="") {
      setError({ ...error,allError: "All Field Required" })
    }
    else{
      setError({ ...error,allError: null})
      let userType=localStorage.getItem("id")
      let data={
        email:inputs.email,
        userType:userType
      }
      if(error.emailErr===null){
        MicroServicePostRequestAPI('api/vendor/forgot-password', 'post', data).then((result) => {
          if (result) {
            toast.success(result.message);
            // dispatch(forgotPassword(true));
            setTimeout(() => { window.location.replace("new-login") }, 2000)
          }
        }).catch((err) => {
          if(err.data.success===false){
            toast.error(err.data.description)
          }
        });
      }
    }
  }
  return (
    <React.Fragment>
      <header>
        <NavigationBar />
      </header>
      <div className="auth-wrraper">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} xl={7}>
              <Card className="mt-5 p-3">
                <Card.Body className="main-card py-0">
                  <div className="header-content">
                    <h4 className="mt-3">Forgot Password</h4>
                    <div className="d-flex align-items-center justify-content-center my-3">
                      <button className="google-btn">
                        <img src={GoogleIcon} /> Continue with Google
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#9A9A9A",
                        }}
                      />

                      <div>
                        <p className="line-style">OR</p>
                      </div>

                      <div
                        style={{
                          flex: 1,
                          height: "1px",
                          backgroundColor: "#9A9A9A",
                        }}
                      />
                    </div>
                    <Form className="mt-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <InputGroup className=" d-flex align-items-center">
                          <Form.Control
                            type="email"
                            placeholder="Email Address"
                            name="email"
                            className="input-field email-field border-0"
                            defaultValue={inputs.email}
                            onChange={inputChangeHandler}
                          />
                          <span className="p-2">
                            <img src={EmailIcon} />
                          </span>
                        </InputGroup>
                        {error.emailErr && <p style={errorStyle}>{error.emailErr}</p>}
                        {error.allError && <p style={errorStyle}>{error.allError}</p>}
                      </Form.Group>
                      <Button className="submit-btn" size="lg" type="submit" onClick={forgotPasswordHandler}>
                        Submit
                      </Button>
                    </Form>
                    <span className="form-check-label-dark d-flex justify-content-center">
                      <span
                        className="orange-dark  my-3"
                        onClick={loginHandler}
                      >
                        Go To Login Page Now
                      </span>
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={false}
      />
    </React.Fragment>
  );
};

export default ForgotPassword;
