import React, { Fragment, useRef } from "react";
import { Carousel } from "react-bootstrap";
import leftArrow from "../../assets/leftArrow.svg";
import rightArrow from "../../assets/rightArrow.svg";
import TalentedOne from "../../assets/card1.png";
import TalentedTwo from "../../assets/card2.png";
import TalentedThree from "../../assets/card3.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./TalentedResource.scss";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";

const TalentedResource = () => {
  const [value, setValue] = React.useState(2);
  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="mb-3">Top Talented Resources</h3>
          </div>
          <div className="col-md-6 text-end">
            <button
              className=" btn-slider mb-3"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
              onClick={onPrevClick}
            >
              <img src={leftArrow} alt="left-icon" className="me-3" />
            </button>
            <button
              className=" btn-slider mb-3"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
              onClick={onNextClick}
            >
              <img src={rightArrow} alt="right-icon" />
            </button>
          </div>
          <div className="col-md-12 mt-3">
            <Carousel ref={ref}  indicators={true} controls={false}>
              <Carousel.Item interval={5000}>
                <div className="row">
                      <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-back">
                      <img
                        className="card-img-top"
                        src={TalentedOne}
                        alt="talented resource"
                      />
                      <div className="cta-btn">
                        <button className="btn-cta">
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-front">
                      <img
                        className="card-img-top"
                        src={TalentedTwo}
                        alt="talented resource"
                      />
                      <span className="top-content">600 / Hr</span>
                      <div className="card-content">
                        <h4 className="profile-name">Raven Baxter</h4>
                        <span className="designation">UI / UX Designer</span>
                        <div className="profile-badges">
                          <div className="badge">
                            <span>Adobe XD</span>
                          </div>
                          <div className="badge">
                            <span>UX Design</span>
                          </div>
                          <div className="badge">
                            <span>Web Design</span>
                          </div>
                          <div className="badge">
                            <span>Figma</span>
                          </div>
                          <div className="badge">
                            <span>Photoshop</span>
                          </div>
                        </div>
                      </div>
                      <div className="cta-btn">
                        <button className="btn-cta2">
                          View Profile <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-back">
                      <img
                        className="card-img-top"
                        src={TalentedThree}
                        alt="talented resource"
                      />
                      <div className="cta-btn">
                        <button className="btn-cta">
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <div className="row">
                  <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-back">
                      <img
                        className="card-img-top"
                        src={TalentedOne}
                        alt="talented resource"
                      />
                      <div className="cta-btn">
                        <button className="btn-cta">
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-front">
                      <img
                        className="card-img-top"
                        src={TalentedTwo}
                        alt="talented resource"
                      />
                      <span className="top-content">600 / Hr</span>
                      <div className="card-content">
                        <h4 className="profile-name">Raven Baxter</h4>
                        <span className="designation">UI / UX Designer</span>
                        <div className="profile-badges">
                          <div className="badge">
                            <span>Adobe XD</span>
                          </div>
                          <div className="badge">
                            <span>UX Design</span>
                          </div>
                          <div className="badge">
                            <span>Web Design</span>
                          </div>
                          <div className="badge">
                            <span>Figma</span>
                          </div>
                          <div className="badge">
                            <span>Photoshop</span>
                          </div>
                        </div>
                      </div>
                      <div className="cta-btn">
                        <button className="btn-cta2">
                          View Profile <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-back">
                      <img
                        className="card-img-top"
                        src={TalentedThree}
                        alt="talented resource"
                      />
                      <div className="cta-btn">
                        <button className="btn-cta">
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <div className="row">
                  <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-back">
                      <img
                        className="card-img-top"
                        src={TalentedOne}
                        alt="talented resource"
                      />
                      <div className="cta-btn">
                        <button className="btn-cta">
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-front">
                      <img
                        className="card-img-top"
                        src={TalentedTwo}
                        alt="talented resource"
                      />
                      <span className="top-content">600 / Hr</span>
                      <div className="card-content">
                        <h4 className="profile-name">Raven Baxter</h4>
                        {/* <Stack spacing={1}>
                          <Rating
                            name="half-rating"
                            defaultValue={2.5}
                            precision={0.5}
                          />
                        </Stack> */}
                        <span className="designation">UI / UX Designer</span>
                        <div className="profile-badges">
                          <div className="badge">
                            <span>Adobe XD</span>
                          </div>
                          <div className="badge">
                            <span>UX Design</span>
                          </div>
                          <div className="badge">
                            <span>Web Design</span>
                          </div>
                          <div className="badge">
                            <span>Figma</span>
                          </div>
                          <div className="badge">
                            <span>Photoshop</span>
                          </div>
                        </div>
                      </div>
                      <div className="cta-btn">
                        <button className="btn-cta2">
                          View Profile <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <div className="card resource-card-back">
                      <img
                        className="card-img-top"
                        src={TalentedThree}
                        alt="talented resource"
                      />
                      <div className="cta-btn">
                        <button className="btn-cta">
                          <MdKeyboardArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TalentedResource;
