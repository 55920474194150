import { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import RightArrow from "../../assets/RightMenuArrow.svg";
import LeftArrow from "../../assets/LeftMenuArrow.svg";
import ContactInfo from "../../component/CreateEmployeeProperty/ContactInfo/ContactInfo";
import CreatedSuccesfullly from "../../component/CreateEmployeeProperty/CreatedSuccesfullly/CreatedSuccesfullly";
import EducatoinalInfo from "../../component/CreateEmployeeProperty/EducatoinalInfo/EducatoinalInfo";
import PersonalInfo from "../../component/CreateEmployeeProperty/PersonalInfo/PersonalInfo";
import ExperiencedProInfo from "../../component/CreateEmployeeProperty/ProfessionalInfo/ExperiencedProInfo";
import ProfessionalInfo from "../../component/CreateEmployeeProperty/ProfessionalInfo/ProfessionalInfo";
import UploadImage from "../../component/CreateEmployeeProperty/UploadImage/UploadImage";
import SideProperty from "../../component/SideProperty/SideProperty";
import "./EmployeeManualProfile2.scss";
import AddYourSkills from "../../component/CreateEmployeeProperty/AddYourSkills/AddYourSkills";
import EmployeeProfileHeading from "../../component/CreateEmployeeProperty/EmployeeProfileHeading/EmployeeProfileHeading";
import { useSelector } from "react-redux";
import Resumepreview from "../ResumePreview/ResumePreview";
import { useLocation } from "react-router-dom";

const EmployeeManualProfile2 = () => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const location=useLocation();
 const state = useSelector((state) => state?.EmployeeManualResumeReducer);
 
console.log("hi",state.PersonalInfoResumeData)

const [selectedTab,setSelectedTab]=useState(location.state ? location.state:1);
  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const selected = ( val) =>{
    setSelectedTab(val)
  }
  return (
    <Fragment>
      {/* <div className="row">
        <div className="col-md-4 col-sm-4"></div>
        <div className="col-md-12 col-sm-12 info">
          <SideProperty />
          <div className="main__content">
            <PersonalInfo />
            <ContactInfo />
            <ProfessionalInfo />
            <ExperiencedProInfo />
            <EducatoinalInfo />
            <UploadImage />
            <CreatedSuccesfullly />
          </div>
        </div>
      </div> */}
      <div>
        <EmployeeProfileHeading />
        {/* <SideProperty /> */}
      </div>
      <div className="row">
        <div className="col-md-12" collapsed={menuCollapse}>
          <div className="closemenu" onClick={menuIconClick}>          
            {menuCollapse ? (
              <div className="right-arrow">
                <img src={RightArrow} />
              </div>
            ) : (
              <div className="left-arrow">
                <img src={LeftArrow} />
              </div>
            )}
          </div>
          <div className="d-flex align-items-start">
            <div
              className="nav flex-column nav-pills me-3 side-column"
              id="side-column"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className={`nav-link ${selectedTab == 1 && "active"} `}
                onClick={()=>{setSelectedTab(1)}}
              >
                <div className="menu-title d-flex align-items-center custom-text">
                  Personal Information <span>1</span>
                </div>
              </button>
              <button
                className={`nav-link ${selectedTab == 2 && "active"} `}
                onClick={()=>{setSelectedTab(2)}}
                // disabled={(state.PersonalInfoResumeData.birthDate!="" && state.PersonalInfoResumeData.birthDate!=null)?false:true}
              >
                <div className="menu-title d-flex align-items-center custom-text">
                  Contact Information <span>2</span>
                </div>
              </button>
              <button
                className={`nav-link ${selectedTab == 3 && "active"} `}
                onClick={()=>{setSelectedTab(3)}}
                // disabled={(state.contactInfoResumeData.phone!="" && state.PersonalInfoResumeData.phone!=null)?false:true}
              >
                <div className="menu-title d-flex align-items-center custom-text">
                  Professional Information<span>3</span>
                </div>
              </button>
              <button
                className={`nav-link ${selectedTab == 4 && "active"} `}
                onClick={()=>{setSelectedTab(4)}}
                // disabled={((state.EducationalInfoResumeData.fresher.interestArea!="" && state.EducationalInfoResumeData.fresher.interestArea!=null) ||(state.EducationalInfoResumeData.experience.jobPosition!="" && state.EducationalInfoResumeData.fresher.interestArea!=null))?false:true}
              >
                <div className="menu-title d-flex align-items-center custom-text">
                  Educational Information<span>4</span>
                </div>
              </button>
              <button
                className={`nav-link ${selectedTab == 5 && "active"} `}
                onClick={()=>{setSelectedTab(5)}}
                
              >
                <div className="menu-title d-flex align-items-center custom-text">
                  Add Your Skills<span>5</span>
                </div>
              </button>
              <button
                className={`nav-link ${selectedTab == 6 && "active"} `}
                onClick={()=>{setSelectedTab(6)}}
              >
                <div className="menu-title d-flex align-items-center custom-text">
                  Upload Image <span>6</span>
                </div>
              </button>
            </div>
            <div className="tab-content custom-tabs " id="v-pills-tabContent">
              {selectedTab == 1 && 
              <div
                className={`tab-pane fade show ${selectedTab == 1 && "active"}`}
                role="tabpanel"
              >
                {" "}
                <PersonalInfo selected={selected}/>
              </div>
              }
              {selectedTab == 2 && 

              <div
                className={`tab-pane fade show ${selectedTab == 2 && "active"}`}
                role="tabpanel"
              >
                {" "}
                <ContactInfo selected={selected}/>          
              </div>
              }
              {selectedTab == 3 &&
              <div
                className={`tab-pane fade show ${selectedTab == 3 && "active"}`}
                role="tabpanel"
              >
                <ProfessionalInfo selected={selected} />
              </div>
              }
              {selectedTab == 4 &&
              <div
                className={`tab-pane fade show ${selectedTab == 4 && "active"}`}
                role="tabpanel"
              >
                {" "}
                <EducatoinalInfo selected={selected} />
              </div>
              }
              {selectedTab == 5 &&
              <div
                className={`tab-pane fade show ${selectedTab == 5 && "active"}`}
                role="tabpanel"
              >
                {" "}
                <AddYourSkills selected={selected}/>
              </div>
              }
              {selectedTab == 6 &&
              <div
                className={`tab-pane fade show ${selectedTab == 6 && "active"}`}
                role="tabpanel"
              >
                {" "}
                <UploadImage selected={selected}/>
              </div>
              }
                  {selectedTab == 7 &&
              <div
                className={`tab-pane fade show ${selectedTab == 7 && "active"}`}
                role="tabpanel"
              >
                {" "}
                <CreatedSuccesfullly selected={selected}/>
                
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmployeeManualProfile2;
