export const login_user_data = (data) => {
  return {
    type: "login_user_data",
    payload: data,
  };
};
export const forgotPassword = (data) => {
  return {
    type: "forgot_password",
    payload: data,
  };
};
export const getUserType = (data) => {
  console.log("user type",data)
  return {
    type: "get_user_type",
    payload: data,
  };
};