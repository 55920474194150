import React from 'react'
import './TredningResource.scss'
const HiringResources=[
    "Business Analyst",
    "UI Designer",
    "UX Designer",
    "PHP Developer",
    "Cloud Engineer",
    "Web Designer",
    "Android Developer",
    "Content Writer",
    "SEO Expert",
    "WP Developer"
]

const TredningResource = () => {
  return (
    <div className="container">
      <div className="reso row mt-3">
          <div className="  col-md-7 col-12">
              <h3>Trending Hiring Resources</h3>
              <div className="row">
                  {HiringResources.map((resources,idx)=>(
                      <div className="col-4 resources" key={idx}>
                          {resources}
                      </div>
                  ))}
              </div>
          </div>
          <div className="col-md-5 col-12 why-skipple mt-4">
           <div className="about-skipple">
           <h6 className="header-subtitle"><span>Why Skipple</span></h6>
           <h3 className="about-title">We revolutionize your workspace</h3>
           <p>Lorem ipsum dolor sit amet, consectetur adipis
                cing elit. Mollis metus, pulvinar ac tempus et 
                quisque consectetur. Sed vitae, enim, in tellus
                sed. Elementum non cum eget massa.</p>
          </div>
          </div>
      </div>
    </div>
  )
}

export default TredningResource
