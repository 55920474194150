import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import DropImage from "../../assets/photo-drop.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIurl from "../../services/APIurl";


function PhotoDropzone(props) {
  const [preview, setPreview] = useState(null);

  // useEffect(()=>{
  //   if(props.removeUploadPhoto==null){
  //     setPreview(null)
  //   }
  // },[props])
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (
      acceptedFiles[0].type === "image/png" ||
      acceptedFiles[0].type === "image/jpeg" ||
      acceptedFiles[0].type === "image/jpg"
    ) {
      const previewVal = window.URL.createObjectURL(acceptedFiles[0]);
      setPreview(previewVal);
      props.uploadedFile(acceptedFiles[0]);
    } else {
      toast.error("file is not acceptable");
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p></p>
      ) : (
        <p>
          <img
            height="150"
            width="150"
            src={preview !== null ? preview : props.selectedFileName ?  `${APIurl.backendUrl}/${props.selectedFileName}` :  DropImage}
            alt=""
          />
        </p>
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        icon={false}
      />
    </div>
  );
}

export default PhotoDropzone;
