import React from "react";
import "../../layouts/Footer/Footer.scss";
import { BsYoutube } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-lg-4    col-md-12">
          <div className="">
            <h2 className="header-subtitle">Skiple</h2>
            <div className="header-title">
              <h6>
                Lorem ipsum dolor sit amet, consectetur adipis cing elit. Mollis
                metus, pulvinar ac tempus et quisque consectetur. Sed vitae,
                enim, in tellus sed.
              </h6>
            </div>
            <div className="social-icon">
              <BsInstagram />
              <BsYoutube />
              <BsFacebook />
            </div>
            <div className="copy-right">
              <h6>&copy;Skiple Website 2022</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12">
          <h3 className="links-container">Quick Links</h3>
          <div className="row link">
            <div className="  link-container col-lg-4 col-sm-6 col-12 text-white">
              Hire Resource
            </div>
            <div className=" link-container col-lg-4 col-sm-6 col-12">
              Contact Us
            </div>
            <div className="link-container col-lg-4 col-sm-6 col-12">
              Privacy Policy
            </div>
            <div className="link-container col-lg-4 col-sm-6 col-12">
              About Skiple
            </div>
            <div className="link-container col-lg-4 col-sm-6 col-12">
              News and Articles
            </div>
            <div className="link-container col-lg-4 col-sm-6 col-12">
              Terms of Use
            </div>
            <div className="link-container col-lg-4 col-sm-6 col-12">
              Post Requirement
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
