import { useEffect, useState } from "react";
import {
    Row,
    Col,
  } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getAllResource } from "../../services/CompanyAPI";
const EducatoinalInformation =()=>
{
  const [data, setData] = useState([]);
  const state = useSelector((state) => state?.Company?.get_resource_id);

  useEffect(() => {
    getAllResource(state)
      .then((result) => {
        setData(result?.data[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [state]);
    return(
            <div className="profile-about-skipple">
              <h3>Educatoinal Information</h3>
              <Row className="d-flex">
                    <Col sm={4} md={4} xs={6} className="mb-3">
                      <div className="fild-heading-text-content">
                        <div>Last Highest Qualification:</div>
                        <div>Course Name:</div>
                        <div>University Name:</div>
                        <div>Course Type:</div>
                      </div>
                    </Col>
                    <Col sm={8} md={8} xs={6}>
                      <div className="fild-text-content">
                        <div>{data?.education?.qualification}</div>
                        <div>{data?.education?.courseName}</div>
                        <div>{data?.education?.universityName}</div>
                        <div>{data?.education?.courseType}</div>
                      </div>
                    </Col>
                  </Row>
            </div>
    )
}
export default EducatoinalInformation;