import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Button,
} from "react-bootstrap";
import "./ProfessionalInfo.scss";
import CustomInput from "../../CustomInput/CustomInput";
import ExperiencedProInfo from "./ExperiencedProInfo";
import MyComponent from "../../Dropdowns/Dropdowns2";
import { Month, Year } from "../../Dropdowns/DropdownList";
import {
  experience_data,
  fresher_Educational_Data,
  total_Education_Data,
} from "../../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { personalExperienceDetails } from "../../../services/CompanyAPI";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Employee_Resume_Preview_Api } from "../../../services/EmployeeAPI";

const ProfessionalInfo = (props) => {
  const dispatch = useDispatch()
  const all_experience_state = useSelector(state => state?.EmployeeManualResumeReducer?.total_Education_Data)
  const fresher_data_state = useSelector(state => state?.EmployeeManualResumeReducer?.fresher_Educational_Data)
  const [radioValue, setRadioValue] = useState("")
  const [flag, setFlag] = useState(false)
  const [flag1, setFlag1] = useState(false)
  const [professionalInformation, setProfessionalInfo] = useState({
    isWorking: false,
    jobPosition: "",
    companyName: "",
    startMonth: null,
    endMonth: null,
    startYear: null,
    endYear: null,
    workDescription: "",
    workExperience: "",
  })
  const [interestArea, setInterestArea] = useState({
    fresherIntrestedArea: "",
    description: "",
  });
  const [apiCallingData, setApiCAllingData] = useState()
  let id = localStorage.getItem("id");

  console.log("apiCallingData", apiCallingData);
  // useEffect(() => {
  //   setApiCAllingData(all_experience_state)
  // }, [all_experience_state])
  const inputChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setProfessionalInfo((values) => ({ ...values, [name]: value }));
  };
  const experienceInfoDropDownHandler = (event) => {
    const name = event.name;
    const value = event.value;
    setProfessionalInfo((values) => ({ ...values, [name]: value }));
  };
  const handleChecked = (e) => {
    let check = e.target.checked
    setProfessionalInfo({ ...professionalInformation, isWorking: check })
  }

  useEffect(() => {
    if (all_experience_state) {
      setApiCAllingData(all_experience_state)
    }
  }, [all_experience_state])

  useEffect(() => {
    if (flag) {
      personalExperienceDetails(id, apiCallingData)
        .then((res) => {
          // const position = res.data.length - 1
          setFlag1(true)
          setFlag(false)
          props.selected(4);
          // dispatch(total_Education_Data(res.data[position]))
          setProfessionalInfo({
            jobPosition: "",
            companyName: "",
            startMonth: null,
            endMonth: null,
            startYear: null,
            endYear: null,
            workDescription: "",
            workExperience: "",
            isWorking: false,
          })
        })
        .catch((err) => console.log(err));
    }
  }, [flag])

  useEffect(() => {
    let id = localStorage.getItem("id");
    Employee_Resume_Preview_Api()
      .then((res) => {
        console.log("res", res?.data[0]?.work);
        if (res?.data[0]?.work) {
          let totalData = res?.data[0]?.work
          let newObj = {
            experience: "Experienced",
            work: totalData,
          };
          dispatch(total_Education_Data(newObj))
        }
        setFlag1(false)
        // setEmployeeData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [flag1])








  const handleAddExperience = () => {
    let data = {
      jobPosition: professionalInformation?.jobPosition,
      companyName: professionalInformation?.companyName,
      startDate: professionalInformation?.startMonth + "/" + professionalInformation?.startYear,
      workDescription: professionalInformation?.workDescription,
      workExperience: professionalInformation?.workExperience,
      isWorking: professionalInformation?.isWorking,
    }
    if (professionalInformation?.endMonth) {
      data.endDate = professionalInformation?.endMonth + "/" + professionalInformation?.endYear
    }

    let newObj = {
      experience: "Experienced",
      work: [data],
    };
    if (professionalInformation?.jobPosition !== null) {
      if (!apiCallingData || apiCallingData?.length === 0 || apiCallingData == {}) {
        setApiCAllingData(newObj)
        dispatch(total_Education_Data(newObj))
        setProfessionalInfo({
          jobPosition: "",
          companyName: "",
          startMonth: null,
          endMonth: null,
          startYear: null,
          endYear: null,
          workDescription: "",
          workExperience: "",
          isWorking: false,
        })
      } else {
        // console.log("aaa", apiCallingData)
        if (apiCallingData?.work) {
          setApiCAllingData({ ...apiCallingData, work: [...apiCallingData?.work, data] });
          dispatch(total_Education_Data({ ...apiCallingData, work: [...apiCallingData?.work, data] }))
        } else {
          setApiCAllingData({ ...apiCallingData, work: [data] });
          dispatch(total_Education_Data({ ...apiCallingData, work: [data] }))
        }
        setProfessionalInfo({
          jobPosition: "",
          companyName: "",
          startMonth: null,
          endMonth: null,
          startYear: null,
          endYear: null,
          workDescription: "",
          workExperience: "",
          isWorking: false,
        })

      }
    } else {
      toast.error("plss")
      alert("please fill all fields")
    }
    console.log(data);
  }

  const saveButtonHandler = () => {
    let data = {
      jobPosition: professionalInformation?.jobPosition,
      companyName: professionalInformation?.companyName,
      startDate: professionalInformation?.startMonth + "/" + professionalInformation?.startYear,
      workDescription: professionalInformation?.workDescription,
      workExperience: professionalInformation?.workExperience,
      isWorking: professionalInformation?.isWorking,
    }
    if (professionalInformation?.endMonth) {
      data.endDate = professionalInformation?.endMonth + "/" + professionalInformation?.endYear
    }

    let newObj = {
      experience: "Experienced",
      work: [data],
    };

    if (radioValue == "Experience") {
      if (professionalInformation?.jobPosition !== "") {
        if (!apiCallingData || apiCallingData?.length === 0 || apiCallingData == {}) {
          dispatch(total_Education_Data(newObj))
          console.log("jaiideep");
          personalExperienceDetails(id, newObj).then((res) => {

            setProfessionalInfo({
              jobPosition: "",
              companyName: "",
              startMonth: null,
              endMonth: null,
              startYear: null,
              endYear: null,
              workDescription: "",
              workExperience: "",
              isWorking: false,
            })
          }).catch((err) => {

          })
        } else {
          console.log("ram");
          if (apiCallingData?.work) {
            setApiCAllingData({ ...apiCallingData, work: [...apiCallingData?.work, data] });
            dispatch(total_Education_Data({ ...apiCallingData, work: [...apiCallingData?.work, data] }))
            setFlag(true)

            console.log("jai");
          } else {
            setApiCAllingData({ ...apiCallingData, work: [data] });
            dispatch(total_Education_Data({ ...apiCallingData, work: [data] }))
            setFlag(true)

            console.log("deep");
          }
          // let data = ({ ...apiCallingData, work: [...apiCallingData?.work, data] })
          // console.log("requestData", data);
          // dispatch(total_Education_Data(requestData))
          // personalExperienceDetails(id, requestData)
          //   .then((res) => {
          //     const position = res.data.length - 1

          //     dispatch(total_Education_Data(res.data[position]))
          //     setProfessionalInfo({
          //       jobPosition: "",
          //       companyName: "",
          //       startMonth: null,
          //       endMonth: null,
          //       startYear: null,
          //       endYear: null,
          //       workDescription: "",
          //       workExperience: "",
          //       isWorking: false,
          //     })
          //   })
          //   .catch((err) => console.log(err));
        }
      } else {
        alert("Please Fill all data")
      }
    } else {
      if (interestArea?.fresherIntrestedArea !== "") {

        let data = {
          experience: "Fresher",
          descriptionOfInterest: interestArea?.fresherIntrestedArea,
          interestArea: interestArea?.description
        }
        dispatch(fresher_Educational_Data(data))
        personalExperienceDetails(id, data).then((res) => {
          console.log(res);
          props.selected(4)
        }).catch((err) => {
          console.log(err)
        })

      }
    }


  }
  return (
    <Fragment>
      <div className="container-fluid">
        {radioValue == "Fresher" && (
          <p className="head-text">
            Are You A Fresher? Enter Your Interest Area To Get Relevant Results
          </p>
        )}
        {radioValue == "Experience" && (
          <p className="head-text">
            You Have Experience, We Have Perfect Job For You. Fill The Below
            Information
          </p>
        )}
        <Row className="justify-content-center mb-3 card">
          <Col xs={12} md={12} lg={12} xl={12}>
            <Card.Body className="main-card py-0">
              <h1 className="header-cont mb-5">Professional Information</h1>
              <div>
                <Row className="mb-4">
                  <Col sm={6} md={6} className="bt">
                    <div class="form-check">
                      <input
                        class="form-check-input bt-bt"
                        type="radio"
                        name="radio"
                        id="flexRadioDefault1"
                        value="Fresher"
                        // disabled={employeeData == "Fresher" ? false : true}
                        onChange={() => setRadioValue("Fresher")}
                      />
                      <label
                        class="form-check-label bt-text"
                        for="flexRadioDefault1"
                      >
                        Fresher
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radio"
                        id="flexRadioDefault2"
                        value="Experience"
                        // disabled={employeeData == "Experienced" ? false : true}
                        onChange={() => setRadioValue("Experience")}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Experienced
                      </label>
                    </div>
                  </Col>
                </Row>
                {radioValue == "Fresher" && (
                  <>
                    <Row className="mb-4">
                      <Col sm={6} md={6}>
                        <div className="fild-text">Interest Area *</div>
                        <div className="w-100">
                          <CustomInput
                            placeholder="i.e. Designing "
                            type="text"
                            name="interestArea"
                            default={fresher_data_state && fresher_data_state?.interestArea}
                            onChange={(e) => {
                              setInterestArea({
                                ...interestArea,
                                fresherIntrestedArea: e.target.value,
                              });
                            }}
                          />
                        </div>
                        {/* {interestAreaErr && (
                          <p style={errorStyle}>{interestAreaErr}</p>
                        )} */}
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col sm={12} md={12}>
                        <div className="fild-text">
                          Description About Your Interest
                        </div>
                        <div>
                          <textarea
                            placeholder="i.e. I like Designing, I did a course  in designing."
                            className="text-area area"
                            defaultValue={fresher_data_state && fresher_data_state?.descriptionOfInterest}
                            onChange={(e) => {
                              setInterestArea({
                                ...interestArea,
                                description: e.target.value,
                              });
                            }}
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}


                {radioValue == "Experience" &&
                  <>{apiCallingData ? apiCallingData?.work?.map((item) => {
                    return (
                      <Fragment>
                        <div className="container-fluid">

                          <Row className="justify-content-center mb-3 main-card">
                            <Col xs={12} md={12} lg={12} xl={12}>
                              <div>
                                <Row className="mb-4">
                                  <Col sm={6} md={6} className="bt">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        defaultChecked={
                                          item.isWorking == true
                                            ? true
                                            : false
                                        }

                                      />
                                      <label
                                        class="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        Presently Working
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col sm={6} md={6}>
                                    <div className="fild-text">
                                      Job Position
                                    </div>
                                    <div className="w-100">
                                      <CustomInput
                                        type="text"
                                        name="jobPosition"
                                        default={
                                          item?.jobPosition
                                        }
                                      // onChange={
                                      //   experienceInfoChangeHandler
                                      // }
                                      />
                                    </div>
                                    {/* {error.jobPositionErr && (
                                            <p style={errorStyle}>
                                              {error.jobPositionErr}
                                            </p>
                                          )} */}
                                  </Col>
                                  <Col sm={6} md={6}>
                                    <div className="fild-text">
                                      Company Name
                                    </div>
                                    <div className="w-100">
                                      <CustomInput
                                        type="text"
                                        name="companyName"
                                        default={
                                          item?.companyName
                                        }
                                      // onChange={
                                      //   experienceInfoChangeHandler
                                      // }
                                      />
                                    </div>
                                    {/* {error.companyNameErr && (
                                            <p style={errorStyle}>
                                              {error.companyNameErr}
                                            </p>
                                          )} */}
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col sm={6} md={6}>
                                    <div className="fild-text">
                                      Start Time
                                    </div>
                                    <div>
                                      <Row>
                                        <Col sm={6} md={6}>
                                          <MyComponent
                                            name="startMonth"
                                            // onChange={
                                            //   experienceInfoDropDownHandler
                                            // }
                                            options={Month}
                                            default={item?.startDate.split("/")[0]}
                                          />
                                          {/* {error.startMonthErr && (
                                                  <p style={errorStyle}>
                                                    {error.startMonthErr}
                                                  </p>
                                                )} */}
                                        </Col>
                                        <Col sm={6} md={6}>
                                          <MyComponent
                                            name="startYear"
                                            // onChange={
                                            //   experienceInfoDropDownHandler
                                            // }
                                            options={Year}
                                            default={item?.startDate.split("/")[1]}
                                          />
                                          {/* {error.startYearErr && (
                                                  <p style={errorStyle}>
                                                    {error.startYearErr}
                                                  </p>
                                                )} */}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col sm={6} md={6}>
                                    <div className="fild-text">
                                      End Time
                                    </div>
                                    <div>
                                      <Row>
                                        <Col sm={6} md={6}>
                                          <MyComponent
                                            name="endMonth"
                                            // onChange={
                                            //   experienceInfoDropDownHandler
                                            // }
                                            options={Month}
                                            default={item?.endDate ? item?.endDate.split("/")[0] : null}
                                          />
                                          {/* {error.endMonthErr && (
                                                  <p style={errorStyle}>
                                                    {error.endMonthErr}
                                                  </p>
                                                )} */}
                                        </Col>
                                        <Col sm={6} md={6}>
                                          <MyComponent
                                            name="endYear"
                                            // onChange={
                                            //   experienceInfoDropDownHandler
                                            // }
                                            options={Year}
                                            default={item?.endDate ? item?.endDate.split("/")[1] : ""}
                                          />
                                          {/* {error.endYearErr && (
                                                  <p style={errorStyle}>
                                                    {error.endYearErr}
                                                  </p>
                                                )} */}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col sm={6} md={6}>
                                    <div className="fild-text">
                                      Work Experience{" "}
                                    </div>
                                    <div className="w-100">
                                      <CustomInput
                                        type="text"
                                        name="workExperience"
                                        default={
                                          item?.workExperience
                                        }
                                      // onChange={
                                      //   experienceInfoChangeHandler
                                      // }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mb-4">
                                  <Col sm={12} md={12}>
                                    <div className="fild-text">
                                      Description About Your Interest
                                    </div>
                                    <div>
                                      <textarea
                                        className="text-area area"
                                        name="workDescription"
                                        defaultValue={
                                          item?.workDescription
                                        }
                                      // onChange={
                                      //   experienceInfoChangeHandler
                                      // }
                                      ></textarea>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={3} md={3} className="bt">
                                    <Button className="mb-3 back-bt">
                                      Delete
                                    </Button>
                                    <Button
                                      className="mb-3 add-bt "
                                    // onClick={handleAddExperience}
                                    >
                                      Edit
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Fragment>
                    )
                  }) : null}</>
                }



                {radioValue == "Experience" &&
                  <Fragment>
                    <div className="container-fluid">
                      <Row className="justify-content-center mb-3 main-card">
                        <Col xs={12} md={12} lg={12} xl={12}>
                          <div>
                            <Row className="mb-4">
                              <Col sm={6} md={6} className="bt">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleChecked}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Presently Working
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Col sm={6} md={6}>
                                <div className="fild-text">Job Position</div>
                                <div className="w-100">
                                  <CustomInput
                                    type="text"
                                    name="jobPosition"
                                    onChange={inputChangeHandler}
                                    value={professionalInformation?.jobPosition}
                                  />
                                </div>
                                {/* {error.jobPositionErr && (
                                  <p style={errorStyle}>
                                    {error.jobPositionErr}
                                  </p>
                                )} */}
                              </Col>
                              <Col sm={6} md={6}>
                                <div className="fild-text">Company Name</div>
                                <div className="w-100">
                                  <CustomInput
                                    type="text"
                                    name="companyName"
                                    onChange={inputChangeHandler}

                                    value={professionalInformation?.companyName}
                                  />
                                </div>
                                {/* {error.companyNameErr && (
                                  <p style={errorStyle}>
                                    {error.companyNameErr}
                                  </p>
                                )} */}
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Col sm={6} md={6}>
                                <div className="fild-text">Start Time</div>
                                <div>
                                  <Row>
                                    <Col sm={6} md={6}>
                                      <MyComponent
                                        name="startMonth"
                                        val={professionalInformation?.startMonth}
                                        value={professionalInformation?.startMonth}
                                        onChange={
                                          experienceInfoDropDownHandler
                                        }
                                        options={Month}
                                      />
                                      {/* {error.startMonthErr && (
                                        <p style={errorStyle}>
                                          {error.startMonthErr}
                                        </p>
                                      )} */}
                                    </Col>
                                    <Col sm={6} md={6}>
                                      <MyComponent
                                        name="startYear"
                                        value={professionalInformation?.startYear}
                                        onChange={
                                          experienceInfoDropDownHandler
                                        }
                                        options={Year}
                                      />
                                      {/* {error.startYearErr && (
                                        <p style={errorStyle}>
                                          {error.startYearErr}
                                        </p>
                                      )} */}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {/* {professionalInformation?.isWorking == false && */}
                                <Col sm={6} md={6}>
                                  <div className="fild-text">End Time</div>
                                  <div>
                                    <Row>
                                      {professionalInformation.isWorking==true?(<p>Presently Working</p>
                                      ):
                                      (
                                        <>
                                      <Col sm={6} md={6}>
                                        <MyComponent
                                          name="endMonth"
                                          val={professionalInformation?.endMonth}
                                          value={professionalInformation?.endMonth}
                                          onChange={
                                            experienceInfoDropDownHandler
                                          }
                                          options={Month}
                                        />
                                        {/* {error.endMonthErr && (
                                    <p style={errorStyle}>
                                      {error.endMonthErr}
                                    </p>
                                  )} */}
                                      </Col>
                                      <Col sm={6} md={6}>
                                        <MyComponent
                                          name="endYear"
                                          value={professionalInformation?.endYear}
                                          onChange={
                                            experienceInfoDropDownHandler
                                          }
                                          // options={Year}
                                          options={Year.filter(
                                            (item) =>
                                              item.value >= professionalInformation.startYear
                                          )}
                                        />
                                        {/* {error.endYearErr && (
                                        <p style={errorStyle}>
                                          {error.endYearErr}
                                        </p>
                                      )} */}
                                      </Col>
                                      </>
                                      )
}
                                    </Row>
                                  </div>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                              <Col sm={6} md={6}>
                                <div className="fild-text">
                                  Work Experience{" "}
                                </div>
                                <div className="w-100">
                                  <CustomInput
                                    type="text"
                                    name="workExperience"
                                    onChange={inputChangeHandler}
                                    value={professionalInformation?.workExperience}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Col sm={12} md={12}>
                                <div className="fild-text">
                                  Description About Your Interest
                                </div>
                                <div>
                                  <textarea
                                    className="text-area area"
                                    name="workDescription"
                                    onChange={inputChangeHandler}
                                    value={professionalInformation?.workDescription}
                                  ></textarea>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={3} md={3} className="bt">
                                <Button className="mb-3 back-bt">Back</Button>
                                <Button
                                  className="mb-3 add-bt "
                                  onClick={handleAddExperience}
                                >
                                  Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Fragment>}


              </div>
            </Card.Body>
          </Col>
        </Row>
        <div className="end-bt">
          <Row className="bt">
            <Col className="bt">
              <Button className="mb-3 Cancel-bt">Cancel</Button>
            </Col>
            <Col>
              <Button className="mb-3 Save-bt "
                onClick={saveButtonHandler}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};
export default ProfessionalInfo;
